import { addRoutes } from 'store/slices/kecho/core/system';
// import Loadable from 'components/Loadable';
// import { lazy } from 'react';
import AuthGuard from 'core/route-guard/AuthGuard';
import { Roles } from 'config';
import DashboardTabs from './DashboardTabs';

// const PrincipalDashboard = Loadable(lazy(() => import('./PrincipalDashboard')));
// const HqadminDashboard = Loadable(lazy(() => import('./HqadminDashboard')));

class DashboardModule {
    constructor() {
        addRoutes([
            {
                path: '/dashboard/principal',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <DashboardTabs tab="1" />
                    </AuthGuard>
                )
            },
            {
                path: '/dashboard/hqadmin',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin]}>
                        <DashboardTabs tab="2" />
                    </AuthGuard>
                )
            },
            {
                path: '/dashboard/sizing',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <DashboardTabs tab="3" />
                    </AuthGuard>
                )
            },
            {
                path: '/dashboard/reports',
                element: (
                    <AuthGuard role={[Roles.Admin, Roles.HrAdmin, Roles.HrIc, Roles.Cluster, Roles.Principals]}>
                        <DashboardTabs tab="4" />
                    </AuthGuard>
                )
            }
        ]);
    }
}

export default new DashboardModule();
