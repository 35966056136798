/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    Button,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TablePagination,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { IconDownload } from '@tabler/icons';
import ArrowDownIcon from 'assets/images/pages/arrow-down.svg';
import resetIcon from 'assets/images/pages/reset-icon.svg';
import EmptyData from 'components/errors/empty-data';
import { AUTH_LOCAL_STORAGE_KEY, GET_ALL_REQUEST, Roles, SIZING_CLASS_TYPE, formatDate, useStyles } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CentreApi } from 'services/api';
import { CentreKecho } from 'types/kecho/centre-kecho';
import ExportSizingModal from './components/ExportSizingModal';
import SizingTable from './components/SizingTable';
import SizingTableClassBreakdown from './components/SizingTableClassBreakdown';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import CustomSelectCenter from 'components/CustomSelectCenter';

interface IFilter {
    centerIds: string[];
    fromDate: string | null;
    toDate: string | null;
    class: string;
    page: number;
    take: number;
}

function Sizing() {
    const classes = useStyles();
    const [isOpenCenterSelect, setIsOpenCenterSelect] = useState<boolean>(false);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;

    const [sizingData, setSizingData] = useState<any>([]);
    const [metaData, setMetaData] = useState<any>([]);
    const [selectedOverallCenterId, setSelectedOverallCenterId] = useState<string>('');
    const [messageError, setMessageError] = useState<string>('');
    const [centreData, setCentreData] = useState<CentreKecho[]>([]);
    const [filterRanger, setFilterRanger] = useState({
        first: 'this' || undefined,
        last: 'day' || undefined,
        number: 1 || undefined,
        date: moment().format(formatDate.DATE_DEFAULT) || null,
        fromDate: moment().format(formatDate.DATE_DEFAULT) || null,
        toDate: moment().format(formatDate.DATE_DEFAULT) || null
    });
    // filter params
    const [filter, setFilter] = useState<IFilter>({
        centerIds: [],
        fromDate: moment().format(formatDate.DATE_DEFAULT) || null,
        toDate: moment().format(formatDate.DATE_DEFAULT) || null,
        class: 'overall',
        page: 1,
        take: 10
    });

    useEffect(() => {
        handleGetCenterList();
    }, []);

    useEffect(() => {
        const getSizingDataTimeOut = setTimeout(() => {
            getSizingData();
        }, 200);

        return () => clearTimeout(getSizingDataTimeOut);
    }, [filter, selectedOverallCenterId]);

    const handleGetCenterList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            setSelectedOverallCenterId(dataList[0]?.id);
            setFilter((prev) => ({ ...prev, centerIds: [dataList[0]?.id] }));
        }
    };

    const getSizingData = async () => {
        if (messageError || !Object.values(filter).every((filterValue) => !!filterValue)) {
            setSizingData([]);
            setMetaData([]);
            return;
        }

        const centerId = userCenterID || selectedOverallCenterId;
        const param = {
            fromDate: filter?.fromDate || moment().format(formatDate.DATE_DEFAULT),
            toDate: filter?.toDate || moment().format(formatDate.DATE_DEFAULT),
            page: filter?.page || 1,
            take: filter?.take || 10
        };

        if (
            (filterRanger.last === 'month' && (filterRanger.number > 3 || filterRanger.number <= 0)) ||
            (filterRanger.last === 'week' && (filterRanger.number > 12 || filterRanger.number <= 0)) ||
            (filterRanger.last === 'day' && (filterRanger.number > 90 || filterRanger.number <= 0))
        ) {
            setSizingData([]);
            setMetaData([]);
            return;
        }

        const { data, meta, error } = await CentreApi.listSizingOfCenter(userRole, centerId, filter.centerIds, filter.class, param);

        if (error) {
            setSizingData([]);
            return;
        }

        if (data) setSizingData(data?.data);
        if (meta) setMetaData(meta);
    };

    const setDefaultDateRange = () => {
        setMessageError('');
        setFilterRanger((prev) => {
            return {
                ...prev,
                number: 1,
                date: moment().format(formatDate.DATE_DEFAULT),
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
            };
        });
        setFilter((prev) => ({
            ...prev,
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
        }));
    };

    // date filter next/past {number} day
    const isSoFilterDayDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().add(1, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'days').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().subtract(number, 'days').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'days').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} week
    const isSoFilterWeekDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).add(1, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).add(number, 'weeks').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).subtract(number, 'weeks').format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).subtract(1, 'weeks').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().isoWeekday(1).format(formatDate.DATE_DEFAULT),
                toDate: moment().isoWeekday(5).format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    // date filter next/past {number} month
    const isSoFilterMonthDate = (type: string, number?: number) => {
        if (type === 'next') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().add(1, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().add(number, 'months').endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'past') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().subtract(number, 'months').startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().subtract(1, 'months').endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        } else if (type === 'this') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().startOf('month').format(formatDate.DATE_DEFAULT),
                toDate: moment().endOf('month').format(formatDate.DATE_DEFAULT),
                page: 1
            }));
        }
    };

    const validateFilter = (first: string, number: number, last: string) => {
        if (first === 'select-date' || first === 'range-date') {
            setMessageError('');
            return true;
        }
        if (first === 'this' || first === 'next' || first === 'past') {
            if (last === 'day') {
                if (number > 0 && number <= 90) {
                    setMessageError('');
                    return true;
                }
                setMessageError('No data for this date. Please choose a different date');
                return true;
            }
            if (last === 'week') {
                if (number > 0 && number <= 12) {
                    setMessageError('');
                    return true;
                }
                setMessageError('No data for this date. Please choose a different date');
                return true;
            }
            if (last === 'month') {
                if (number > 0 && number <= 3) {
                    setMessageError('');
                    return true;
                }
                setMessageError('No data for this date. Please choose a different date');
                return true;
            }
        }

        return false;
    };

    const handleChangeFirst = (e: any) => {
        const first = e.target.value;
        if (!validateFilter(first, filterRanger.number, filterRanger.last)) {
            return;
        }
        const rangerNumber = 1;
        setMessageError('');
        setFilterRanger((prev) => {
            return {
                ...prev,
                date: moment().format(formatDate.DATE_DEFAULT),
                number: 1,
                first
            };
        });

        if (first === 'this') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
            // updateFilterWithDate(moment().format(formatDate.DATE_DEFAULT));
        } else if (first === 'next') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
        } else if (first === 'past') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate(first, rangerNumber);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate(first, rangerNumber);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate(first, rangerNumber);
            }
        } else if (first === 'select-date') {
            setFilter((prev) => ({
                ...prev,
                fromDate: moment().format(formatDate.DATE_DEFAULT),
                toDate: moment().format(formatDate.DATE_DEFAULT)
            }));
        } else if (first === 'range-date') {
            setDefaultDateRange();
        }
    };

    const handleChangeInputNumber = (e: any) => {
        const number = e.target.value;
        if (!validateFilter(filterRanger.first, number, filterRanger.last)) {
            return;
        }
        setFilterRanger((prev) => ({ ...prev, number }));

        if (filterRanger.first === 'next') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate('next', number);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate('next', number);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate('next', number);
            }
        } else if (filterRanger.first === 'past') {
            if (filterRanger.last === 'day') {
                isSoFilterDayDate('past', number);
            } else if (filterRanger.last === 'week') {
                isSoFilterWeekDate('past', number);
            } else if (filterRanger.last === 'month') {
                isSoFilterMonthDate('past', number);
            }
        }
    };

    const handleChangeLast = (e: any) => {
        const last = e.target.value;
        if (!validateFilter(filterRanger.first, filterRanger.number, last)) {
            return;
        }
        setFilterRanger((prev) => ({ ...prev, last }));
        if (last === 'day') {
            if (filterRanger.first === 'next') {
                isSoFilterDayDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterDayDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterDayDate('this');
            }
        } else if (last === 'week') {
            if (filterRanger.first === 'next') {
                isSoFilterWeekDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterWeekDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterWeekDate('this');
            }
        } else if (last === 'month') {
            if (filterRanger.first === 'next') {
                isSoFilterMonthDate('next', filterRanger.number);
            } else if (filterRanger.first === 'past') {
                isSoFilterMonthDate('past', filterRanger.number);
            } else if (filterRanger.first === 'this') {
                isSoFilterMonthDate('this');
            }
        }
    };

    const handleOnChangeDateRange = (value: any) => {
        setFilterRanger((prev) => ({ ...prev, ...value }));
        setFilter((prev) => ({ ...prev, fromDate: value.fromDate, toDate: value.toDate, page: 1 }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const handleShowExport = () => {
        UIManager.showModal({
            content: (onClose: any) => <ExportSizingModal onClose={onClose} />,
            stylesModal: {
                maxWidth: 1000
            }
        });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.Admin || userRole === Roles.HrAdmin || userRole === Roles.HrIc;
    };

    const hanldeOnchangeCenter = (e: any) => {
        if (filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN) {
            setFilter((prev) => ({
                ...prev,
                centerIds: e.target.value
            }));
        } else {
            setSelectedOverallCenterId(e.target.value);
        }
    };

    const handleResetToDefaultFilter = () => {
        setMessageError('');
        setFilterRanger({
            first: 'this',
            last: 'day',
            number: 1,
            date: moment().format(formatDate.DATE_DEFAULT),
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().format(formatDate.DATE_DEFAULT)
        });
        setFilter((prev) => ({
            ...prev,
            fromDate: moment().format(formatDate.DATE_DEFAULT),
            toDate: moment().format(formatDate.DATE_DEFAULT),
            page: 1
        }));
    };

    const CustomIconComponent = () => {
        let centerNames: string = '';
        const centerIds = [...filter.centerIds].splice(1);
        if (filter.centerIds.length > 0) {
            centerNames = centreData
                ?.filter((item) => centerIds.includes(item.id as string))
                .map((item) => item.nameCenter)
                .join(', ');
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
                style={{
                    right: '10px',
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: 'none',
                    cursor: 'pointer'
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenCenterSelect(true);
                }}
            >
                {filter.centerIds.length > 1 && (
                    <Tooltip title={centerNames}>
                        <div style={{ minWidth: '65px', display: 'flex', marginRight: '6px', fontSize: '12px', fontWeight: 600 }}>
                            + {filter.centerIds.length - 1} more
                        </div>
                    </Tooltip>
                )}

                <img src={ArrowDownIcon} alt="&darr;" />
            </div>
        );
    };
    return (
        <div className="sizing-wrapper">
            <CardContent className="sizing-top-filter">
                <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                    <Grid xs={12} sx={{ marginTop: 0, marginLeft: 0, width: '100%' }}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            <FormControl variant="outlined">
                                <InputLabel size="small" id="sizing-page__select-class__label">
                                    Class
                                </InputLabel>
                                <Select
                                    disabled={!(centreData?.length > 0 || userCenterID)}
                                    labelId="sizing-page__select-class"
                                    id="sizing-page__select-class_select"
                                    name="class"
                                    label="Class"
                                    value={filter.class}
                                    size="small"
                                    sx={{ minWidth: 100 }}
                                    onChange={(e) => {
                                        setFilter((prev) => ({ ...prev, class: e.target.value }));
                                    }}
                                >
                                    <MenuItem value={SIZING_CLASS_TYPE.OVERALL}>Overall</MenuItem>
                                    <MenuItem value={SIZING_CLASS_TYPE.CLASS_BREAKDOWN}>Class Breakdown</MenuItem>
                                </Select>
                            </FormControl>

                            {isShowCenterFilter() && (
                                <CustomSelectCenter
                                    data={centreData}
                                    componentSx={{
                                        marginLeft: '10px',
                                        '& .MuiSelect-select': {
                                            ...(filter.centerIds.length > 1 &&
                                                filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN && {
                                                    paddingRight: '20px !important'
                                                })
                                        }
                                    }}
                                    name="centerIds"
                                    label="Center"
                                    onChange={(e: any) => {
                                        hanldeOnchangeCenter(e);
                                        if (filter.class === SIZING_CLASS_TYPE.OVERALL) {
                                            setIsOpenCenterSelect(false);
                                        }
                                    }}
                                    value={filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN ? filter.centerIds : selectedOverallCenterId}
                                    multiple={filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN}
                                    selectProps={{
                                        IconComponent: filter.class === SIZING_CLASS_TYPE.CLASS_BREAKDOWN ? CustomIconComponent : undefined,
                                        open: isOpenCenterSelect,
                                        onClick: (e) => {
                                            const element = e.target as HTMLElement;
                                            // Check when clicking outside the select box

                                            if (
                                                element.childNodes.length <= 0 ||
                                                (element.childNodes.length === 4 && element.nodeName === 'DIV')
                                            ) {
                                                setIsOpenCenterSelect(false);
                                                return;
                                            }

                                            if (isOpenCenterSelect && filter.class === SIZING_CLASS_TYPE.OVERALL) {
                                                return;
                                            }

                                            setIsOpenCenterSelect(true);
                                        },
                                        MenuProps: {
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }
                                    }}
                                />
                            )}

                            <FormControl style={{ width: 130, marginLeft: 10 }}>
                                <InputLabel id="demo-simple-select-label">First</InputLabel>
                                <Select
                                    disabled={!(centreData?.length > 0 || userCenterID)}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="isFirst"
                                    value={filterRanger.first}
                                    label="Filter"
                                    size="small"
                                    onChange={(e) => {
                                        handleChangeFirst(e);
                                    }}
                                >
                                    <MenuItem value="this">This</MenuItem>
                                    <MenuItem value="next">Next</MenuItem>
                                    <MenuItem value="past">Past</MenuItem>
                                    <MenuItem value="select-date">Select date</MenuItem>
                                    <MenuItem value="range-date">Date range</MenuItem>
                                </Select>
                            </FormControl>
                            <div className="date-filter" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                {(filterRanger.first === 'next' || filterRanger.first === 'past') && (
                                    <FormControl style={{ width: 70, marginLeft: 10 }}>
                                        <TextField
                                            type="number"
                                            className="input-number"
                                            size="small"
                                            label="Number"
                                            value={filterRanger.number}
                                            onChange={(e) => handleChangeInputNumber(e)}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                )}

                                {(filterRanger.first === 'this' || filterRanger.first === 'next' || filterRanger.first === 'past') && (
                                    <FormControl style={{ width: 100, marginLeft: 10 }}>
                                        <InputLabel id="demo-simple-select-label">Last</InputLabel>
                                        <Select
                                            disabled={!(centreData?.length > 0 || userCenterID)}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="isLast"
                                            value={filterRanger.last}
                                            label="Filter"
                                            onChange={(e) => handleChangeLast(e)}
                                            size="small"
                                        >
                                            <MenuItem value="day">Day</MenuItem>
                                            <MenuItem value="week">Week</MenuItem>
                                            <MenuItem value="month">Month</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}

                                {filterRanger.first === 'select-date' && (
                                    <LocalDesktopDatePicker
                                        label="Select Date"
                                        value={filterRanger.date}
                                        sx={{ marginLeft: '10px', width: '165px', maxWidth: 'unset' }}
                                        isRequired
                                        onChange={(e) => {
                                            const date = e ? moment(e).format(formatDate.DATE_DEFAULT) : '';

                                            setFilterRanger((prev) => ({ ...prev, date }));
                                            setFilter((prev) => ({
                                                ...prev,
                                                fromDate: date,
                                                toDate: date,
                                                page: 1
                                            }));
                                        }}
                                        onError={(error) => {
                                            setMessageError('');
                                            setSizingData([]);
                                            setMetaData([]);
                                        }}
                                    />
                                )}

                                {filterRanger.first === 'range-date' && (
                                    <DateRangeLocalDesktopDatePicker
                                        isRequired
                                        views={['year', 'month', 'day']}
                                        sx={{ marginLeft: '10px' }}
                                        dateSx={{ width: '165px', maxWidth: 'unset' }}
                                        fromDateValue={filterRanger.fromDate}
                                        toDateValue={filterRanger.toDate}
                                        onChange={handleOnChangeDateRange}
                                        onError={() => {
                                            setMessageError('');
                                            setSizingData([]);
                                            setMetaData([]);
                                        }}
                                    />
                                )}
                                {messageError && (
                                    <div className="error-message" style={{ position: 'absolute', top: 40, left: 18 }}>
                                        <Typography variant="body2" color="error" style={{ whiteSpace: 'nowrap' }}>
                                            {messageError}
                                        </Typography>
                                    </div>
                                )}
                            </div>

                            {(centreData.length > 0 || userCenterID) && (
                                <FormControl style={{ marginLeft: 10 }}>
                                    <Button
                                        size="medium"
                                        style={{
                                            color: '#3F51B5',
                                            padding: '12px',
                                            border: '1px solid #687FD1',
                                            whiteSpace: 'nowrap'
                                        }}
                                        onClick={() => {
                                            handleResetToDefaultFilter();
                                        }}
                                    >
                                        <img style={{ marginRight: '8px' }} src={resetIcon} alt="reset-icon" /> Reset
                                    </Button>
                                </FormControl>
                            )}
                        </div>
                    </Grid>
                    {(centreData.length > 0 || userCenterID) && (
                        <Grid container justifyContent="flex-end">
                            <div>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    style={{ backgroundColor: '#3F51B5', outline: 'none', color: 'white' }}
                                    onClick={handleShowExport}
                                >
                                    <IconDownload style={{ width: 22, marginRight: 8 }} /> Export sizing data
                                </Button>
                            </div>
                        </Grid>
                    )}
                </Grid>
            </CardContent>

            {filter.class === 'overall' ? (
                <CardContent className="sizing-content">
                    {sizingData && sizingData.length > 0 ? (
                        sizingData?.map((item: any, index: number) => <SizingTable key={index} data={item} />)
                    ) : (
                        <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <EmptyData />
                            </Grid>
                        </Grid>
                    )}
                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter?.take || 10}
                        page={filter?.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            ) : (
                <CardContent className="sizing-content">
                    {sizingData && sizingData.length > 0 ? (
                        sizingData?.map((item: any, index: number) => <SizingTableClassBreakdown key={index} data={item} />)
                    ) : (
                        <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <EmptyData />
                            </Grid>
                        </Grid>
                    )}
                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter?.take || 10}
                        page={filter?.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </CardContent>
            )}
        </div>
    );
}

export default Sizing;
