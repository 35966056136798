import * as React from 'react';

// import { Link } from 'react-router-dom';
// material-ui
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Box,
    Button,
    CardContent,
    Chip,
    Grid,
    InputAdornment,
    Stack,
    TableContainer,
    TablePagination,
    TextField,
    Tooltip
} from '@mui/material';
import styles from './reliefTicket.module.scss';

// assets
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { IconDownload } from '@tabler/icons';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';

import CustomSelectCenter from 'components/CustomSelectCenter';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import UIManager from 'core/UIManager';
import { debounce, upperFirst } from 'lodash';
import { CSVLink } from 'react-csv';
import { CentreApi } from 'services/api';
import reliefTicketApi from 'services/api/reliefTicketApi';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { CreateReliefModal, DeleteReliefTicketModal, DetailReliefModal, UploadFileModal } from './components';

const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Interview Date', key: 'interviewDate' },
    { label: 'Mobile No.', key: 'phoneNumber' },
    { label: 'Email Address', key: 'email' },
    { label: 'Qualifications (FCECCE/ EY1/ EY2/ L1/ L2)', key: 'certification[diploma]' },
    { label: 'Status', key: 'status' },
    { label: 'Relief Type (RT/ RTA)', key: 'reliefType' },
    { label: 'Availability', key: 'availability' },
    { label: 'Residential Area', key: 'area' },
    { label: 'Preferred Working Location', key: 'preferWorkLocation' },
    { label: 'Note', key: 'note' },
    { label: 'Agent', key: 'agent' },
    { label: 'Salary (Per Hour)', key: 'salary' },
    { label: 'Start Date', key: 'commencementDate' },
    { label: 'Termination Date', key: 'cessationDate' },
    { label: 'NRIC', key: 'NRIC' },
    { label: 'Bank', key: 'bank' },
    { label: 'Application Form', key: 'applicationForm' },
    { label: 'Educational Certificates', key: 'educationalCertificates' },
    { label: 'Medical', key: 'medical' },
    { label: 'Contract Signed', key: 'contractSigned' },
    { label: 'Declaration Form', key: 'declarationForm' }
];

interface Row extends ReliefTicketKecho {
    action?: string;
}

const ReliefPool = () => {
    const classes = useStyles();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const [staffList, setStaffList] = React.useState<ReliefTicketKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);

    const col: IColumnTable<Row>[] = [
        {
            field: 'name',
            isSort: true,
            headerName: 'Name',
            render: (row) => <>{row?.name}</>
        },
        {
            field: 'email',
            isSort: true,
            headerName: 'Email Address',
            render: (row) => <>{row?.email}</>
        },

        {
            field: 'phoneNumber',
            isSort: true,
            headerName: 'Mobile',
            render: (row) => <>{row?.phoneNumber}</>
        },
        {
            field: 'diploma',
            isSort: true,
            headerName: 'Qualification',
            render: (row) => <>{row?.certification?.diploma}</>
        },
        {
            field: 'availability',
            isSort: true,
            headerName: 'Availability',
            render: (row) => <>{row?.availability}</>
        },
        {
            field: 'preferWorkLocation',
            isSort: true,
            headerName: 'Preferred',
            render: (row) => <>{row?.preferWorkLocation}</>
        },
        {
            field: 'area',
            isSort: true,
            headerName: 'Working Location',
            render: (row) => <>{row?.area}</>
        },
        {
            field: 'salary',
            isSort: true,
            headerName: 'Salary (Per Hour)',
            render: (row) => <>{row?.salary}</>
        },
        {
            field: 'agent',
            isSort: true,
            headerName: 'Agent',
            render: (row) => <>{row?.agent}</>
        },
        {
            field: 'status',
            isSort: true,
            headerName: 'Status',
            render: (row, index) => (
                <>
                    <Chip
                        key={index}
                        sx={
                            statusColors[upperFirst(row?.status)]
                                ? {
                                      ...statusColors[upperFirst(row?.status)]
                                  }
                                : {}
                        }
                        label={row?.status}
                    />
                </>
            )
        },
        {
            field: 'action',
            isSort: false,
            headerName: 'Action',
            render: (row) => (
                <>
                    <Stack direction="row">
                        <Tooltip title="View detail" placement="top">
                            <Button
                                variant="outlined"
                                style={{
                                    color: '#3F51B5',
                                    border: '1px solid #3F51B5',
                                    marginRight: 10
                                }}
                                onClick={() => handleOpenModalDetailRelief(row)}
                            >
                                <VisibilityIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> View
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit detail" placement="top">
                            <Button
                                variant="outlined"
                                style={{
                                    color: '#3F51B5',
                                    border: '1px solid #3F51B5',
                                    marginRight: 10
                                }}
                                onClick={() => handleOpenModalEditStaff(row)}
                            >
                                <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Edit
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Delete">
                            <Button
                                variant="outlined"
                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                onClick={() => handleOpenDeleteModalStaff(row)}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                            </Button>
                        </Tooltip>
                    </Stack>
                </>
            )
        }
    ];

    React.useEffect(() => {
        if (isShowCenterFilter()) {
            handleGetCentreList();
        } else {
            setFilter({ ...filter, centreId: userCenterID });
        }
    }, []);

    React.useEffect(() => {
        if (filter?.centreId || filter?.centreId === null) {
            handleGetReliefTicketList();
        }
    }, [filter]);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre({ ...GET_ALL_REQUEST, screen: isRoleHrIc() ? 'relief-pool' : null });
        if (dataList) {
            setFilter({ ...filter, centreId: dataList[0]?.id });
            if (isRoleHrAdminAndHrIc()) {
                setCentreData([{ id: 'null', nameCenter: 'HQ' }, ...dataList]);
            } else {
                setCentreData(dataList);
            }
        }
    };

    const handleGetReliefTicketList = async () => {
        const { dataList, meta } = await reliefTicketApi.getReliefTicketList({ ...filter, centreId: filter?.centreId || userCenterID });
        if (dataList) {
            setStaffList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchRelief = debounce(onChange, 300);

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC && filter.sortColumn === sortColumn ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenCreateModalStaff = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <CreateReliefModal onClose={onClose} callback={handleGetReliefTicketList} userRole={userRole} userCenterID={userCenterID} />
            )
        });
    };

    const handleOpenModalEditStaff = (reliefTicket: ReliefTicketKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <CreateReliefModal
                    onClose={onClose}
                    callback={handleGetReliefTicketList}
                    reliefTicketEdit={reliefTicket}
                    userRole={userRole}
                    userCenterID={userCenterID}
                />
            )
        });
    };
    const handleOpenModalDetailRelief = (reliefTicket: ReliefTicketKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DetailReliefModal onClose={onClose} reliefTicket={reliefTicket} />
        });
    };

    const handleUploadFileModal = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <UploadFileModal onClose={onClose} onUpload={handleUploadFile} callback={handleGetReliefTicketList} />
            ),
            stylesModal: {
                minWidth: 600
            }
        });
    };

    const handleUploadFile = async (file: any) => {
        try {
            const { data } = await reliefTicketApi.importReliefTicket({ file });
            if (!data?.success || data?.code) {
                UIManager.showAlert({
                    message: data?.message || 'Something went wrong',
                    alert: {
                        color: 'error'
                    }
                });
                return false;
            }
            UIManager.showAlert({
                message: data?.message,
                alert: {
                    color: 'success'
                }
            });
            return true;
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
            return false;
        }
    };

    const handleOpenDeleteModalStaff = (reliefTicket: ReliefTicketKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <DeleteReliefTicketModal onClose={onClose} callback={handleGetReliefTicketList} reliefTicketEdit={reliefTicket} />
            )
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.HrAdmin || userRole === Roles.HrIc || userRole === Roles.Cluster;
    };

    const isRoleHrIc = () => userRole === Roles.HrIc;
    const isRoleHrAdminAndHrIc = () => userRole === Roles.HrIc || userRole === Roles.HrAdmin;

    const statusColors: { [key: string]: { backgroundColor: string; color: string } } = {
        Pending: {
            backgroundColor: '#F9EDC6',
            color: '#D28E0A'
        },
        Active: {
            backgroundColor: '#D4FADE',
            color: '#09B54E'
        },
        'Non-active': {
            backgroundColor: '#FCE3E1',
            color: '#F44336'
        }
    };
    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Relief Pool" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className="group-filter" style={{ display: 'flex', marginBottom: 0 }}>
                            {isShowCenterFilter() && (
                                <CustomSelectCenter
                                    data={centreData}
                                    value={centreData.length > 0 && filter.centreId ? filter.centreId : 'null'}
                                    name="centerId"
                                    label="Center"
                                    onChange={(e) => {
                                        const center = e.target.value;
                                        setFilter({ ...filter, centreId: center === 'null' ? null : center });
                                    }}
                                    selectProps={{
                                        MenuProps: {
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }
                                    }}
                                />
                            )}
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearchRelief}
                                placeholder="Search by Name, Email..."
                                // value={filter.query}
                                size="small"
                                style={{ marginLeft: '8px' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <div className="group-action">
                                <Stack direction="row" spacing={2}>
                                    <Tooltip title="Export relief pool" placement="top" style={{ marginRight: 8 }}>
                                        <Button
                                            variant="outlined"
                                            style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginLeft: 10 }}
                                            startIcon={<IconDownload />}
                                        >
                                            <CSVLink
                                                data={staffList}
                                                headers={headers}
                                                filename="Relief-Pool.csv"
                                                className={styles.importCsv}
                                                style={{ color: '#3F51B5' }}
                                            >
                                                Export
                                            </CSVLink>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Upload relief pool" placement="top" style={{ marginRight: 8 }}>
                                        <Button
                                            variant="outlined"
                                            style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginLeft: 10 }}
                                            onClick={handleUploadFileModal}
                                            startIcon={<IconDownload style={{ transform: ' rotate(180deg)' }} />}
                                        >
                                            Import
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Create a relief pool" placement="top">
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                            size="large"
                                            onClick={handleOpenCreateModalStaff}
                                            startIcon={<AddIcon />}
                                        >
                                            Add
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {staffList?.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <TableContainer sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={staffList}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                    </TableContainer>

                    {/* table pagination */}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ReliefPool;
