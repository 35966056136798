import * as React from 'react';
// material-ui
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, CardContent, Chip, Grid, TablePagination, Tooltip } from '@mui/material';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets

import { DEFAULT_REQUEST, Order } from 'config';

import { EnhancedTableHeadProps } from 'types';

import AddIcon from '@mui/icons-material/Add';
import UIManager from 'core/UIManager';
import { qualificationApi } from 'services/api';

import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { AddQualificationModal, DeleteModal } from './components';

import { LoadingButton } from '@mui/lab';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { QualificationKecho } from 'types/kecho/relief';

interface Row extends QualificationKecho {
    action?: string;
    diploma?: string;
}

// ==============================|| TABLE HEADER ||============================== //

interface CustomerListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

const QualificationList = () => {
    const [qualificationList, setQualificationList] = React.useState<QualificationKecho[]>([]);

    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    const cols: IColumnTable<Row>[] = [
        {
            field: 'title',
            headerName: 'Title',
            isSort: true,
            width: '20%'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            isSort: true,
            width: '10%',
            render: (row) => <>{moment(row?.createdAt).format('DD/MM/YYYY')}</>
        },
        {
            field: 'diploma',
            headerName: 'Diplomas',
            isSort: false,
            width: '35%',
            render: (row) => (
                <>
                    {row?.qualificationCert?.length > 2 ? (
                        <>
                            {row?.qualificationCert?.slice(0, 2).map((item: any) => (
                                <Chip key={item.id} label={`${item?.cert?.title} - ${item?.cert?.diploma}`} style={{ marginRight: 8 }} />
                            ))}
                            <Tooltip
                                title={row?.qualificationCert
                                    ?.slice(2)
                                    ?.map((item: any) => `${item?.cert?.title} - ${item?.cert?.diploma}`)
                                    .join(', ')}
                            >
                                <Chip
                                    label={`+${row?.qualificationCert?.length - 2} more`}
                                    style={{ marginRight: 8, cursor: 'help' }}
                                    size="small"
                                    variant="outlined"
                                />
                            </Tooltip>
                        </>
                    ) : (
                        row?.qualificationCert?.map((item: any) => (
                            <Chip key={item.id} label={`${item?.cert?.title} - ${item?.cert?.diploma}`} style={{ marginRight: 8 }} />
                        ))
                    )}
                </>
            )
        },
        {
            field: 'price',
            headerName: 'Price',
            isSort: true,
            width: '10%',
            render: (row) => (
                <Chip
                    color="primary"
                    clickable
                    label={`$ ${row?.price}`}
                    sx={{
                        margin: '0 5px',
                        padding: '0 5px',
                        width: '100px',
                        fontWeight: 500
                    }}
                />
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            render: (row) => (
                <>
                    <Tooltip title="Edit" placement="top">
                        <Button
                            variant="outlined"
                            style={{
                                color: '#3F51B5',
                                border: '1px solid #3F51B5',
                                marginRight: 10
                            }}
                            onClick={() => handleOpenModalAddQualification(row)}
                        >
                            <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Edit
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <Button
                            variant="outlined"
                            style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                            onClick={() => handleOpenDeleteModal(row)}
                        >
                            <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                        </Button>
                    </Tooltip>
                </>
            )
        }
    ];

    React.useEffect(() => {
        handleGetList();
    }, [filter]);

    const handleGetList = async () => {
        const payload = { ...filter, order: filter.order };
        const { dataList, meta } = await qualificationApi.listQualification(payload);
        if (dataList) {
            setQualificationList(dataList);
        }
        if (meta) {
            setMetaData(meta);
        }
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC && filter.sortColumn === sortColumn ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleOpenModalAddQualification = (qualification?: QualificationKecho) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <AddQualificationModal onClose={onClose} callback={handleGetList} qualificationEdit={qualification} />
            ),
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenDeleteModal = (qualification: QualificationKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteModal onClose={onClose} callback={handleGetList} qualification={qualification} />
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Qualification List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={18} sm={18} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Add New Qualification" placement="top">
                                <LoadingButton
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={() => handleOpenModalAddQualification()}
                                    startIcon={<AddIcon sx={{ fontSize: '1.3rem' }} />}
                                >
                                    Add
                                </LoadingButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            {qualificationList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={qualificationList}
                            columns={cols}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default QualificationList;
