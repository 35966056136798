import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import ReportEnrolmentIcon from 'assets/images/icons/report-enrolment-icon.svg';
import UIManager from 'core/UIManager';
import DownloadReportModal from './components/DownloadReportModal';

const DownloadReports = () => {
    const handleShowModalReport = () => {
        UIManager.showModal({
            content: (onClose: any) => <DownloadReportModal onClose={onClose} />,
            stylesModal: {
                maxWidth: 900,
                width: 600
            }
        });
    };
    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            <CardContent style={{ paddingTop: 0 }} className="card-container">
                <Card
                    sx={{ maxWidth: 350 }}
                    style={{
                        padding: 20,
                        border: '1px solid #E3E6EB',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px #F8FAFC',
                        transition: 'box-shadow 0.3s ease',
                        backgroundColor: '#F8FAFC',
                        cursor: 'pointer'
                    }}
                    onClick={handleShowModalReport}
                    className="card"
                >
                    <Grid spacing={2}>
                        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                            <Grid container sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                                <Grid item xs={2} sx={{ marginRight: '20px' }}>
                                    <Avatar
                                        src={ReportEnrolmentIcon}
                                        sx={{ height: 50, width: 50 }}
                                        style={{ padding: 12, backgroundColor: '#DDE4FF' }}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h3" component="div">
                                        Enrolment & Manpower Report
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>A common data point for discussion on planning for enrolment and/or manpower</Typography>
                        </Grid>
                    </Grid>
                </Card>
            </CardContent>
        </div>
    );
};
export default DownloadReports;
