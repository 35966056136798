import { useEffect, useState } from 'react';
// project imports

// assets

import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
// third party
import { Formik } from 'formik';
import * as Yup from 'yup';

// project imports
import ModalCustom from 'components/Modal/ModalCustom';
import { Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi, UserApi, clusterApi } from 'services/api';
import CustomSelectCenter from 'components/CustomSelectCenter';

// assets

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    clusterEdit?: any;
}

const ClusterModal = (props: IProps) => {
    const classes = useStyles();
    const { clusterEdit, callback, onClose } = props;
    const centerIds = clusterEdit
        ? clusterEdit.center.map((el: any) => {
              return el.id;
          })
        : [];
    const [userList, setUserList] = useState<any>([]);
    const [centerList, setCenterList] = useState<any>([]);
    useEffect(() => {
        handleGetUserList();
        handleGetCenterList();
    }, []);

    const handleSubmitQualification = async (obj: any, config: any) => {
        const managerObj = userList.find((item: any) => item?.staff?.id === obj.manager);
        const payload = {
            nameCluster: obj.name,
            centerIds: selectedNames.join(','),
            user: managerObj
        };
        if (clusterEdit) {
            const id = clusterEdit.id;
            const { data } = await clusterApi.updateCluster(id, payload);
            if (data) {
                UIManager.showAlert({
                    message: 'Update successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        } else {
            const { data } = await clusterApi.createCluster(payload);
            if (data) {
                UIManager.showAlert({
                    message: 'Create successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        }
    };
    const handleGetUserList = async () => {
        const param = {
            role: Roles.Cluster,
            haveCluster: clusterEdit ? 'true' : 'false'
        };
        const { dataList } = await UserApi.listUser(param);
        if (dataList) setUserList(dataList);
    };
    const handleGetCenterList = async () => {
        const param = {
            haveCluster: 'false'
        };
        const { dataList } = clusterEdit ? await CentreApi.listCentre() : await CentreApi.listCentre(param);
        if (dataList) {
            setCenterList(dataList);
        }
    };

    const [selectedNames, setSelectedNames] = useState(centerIds);
    return (
        <div style={{ width: 600 }}>
            <Formik
                initialValues={{
                    name: clusterEdit?.nameCluster,
                    manager: clusterEdit?.staffs[0]?.id,
                    centers: selectedNames
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).required('Name is required'),
                    manager: Yup.string().max(255).required('Manager is required')
                    // centers: Yup.array().min(1).required('Center is required'),
                })}
                onSubmit={handleSubmitQualification}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                    <ModalCustom
                        onSave={submitForm}
                        disabled={isSubmitting}
                        onClose={onClose}
                        onCancel={() => {}}
                        title="Create a Cluster"
                        contentSave={!clusterEdit ? 'Create' : 'Save'}
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid
                                container
                                spacing={2}
                                columns={16}
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(touched.name && errors.name)}>
                                        <TextField
                                            sx={{
                                                height: '62px',
                                                marginTop: '8px',

                                                '.MuiFormLabel-root': {
                                                    lineHeight: '2.1375em'
                                                },
                                                '.MuiInputBase-input': {
                                                    padding: '21px 14px'
                                                }
                                            }}
                                            id="outlined-basic"
                                            label="Name"
                                            variant="outlined"
                                            name="name"
                                            type="text"
                                            value={values.name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(touched.manager && errors.manager)}>
                                        <InputLabel id="demo-simple-select-label" sx={{ lineHeight: '3.1375em' }}>
                                            Manager
                                        </InputLabel>
                                        <Select
                                            sx={{ height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="manager"
                                            value={values.manager}
                                            label="Manager"
                                            onChange={handleChange}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {userList?.map((item: any) => (
                                                <MenuItem key={item?.id} value={item?.staff?.id}>
                                                    {item?.staff?.email}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.manager && errors.manager && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.manager}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <CustomSelectCenter
                                componentSx={{
                                    width: '100%',
                                    maxWidth: 'unset',

                                    '.MuiSelect-multiple': {
                                        lineHeight: '1.1375em',
                                        height: 'auto',
                                        marginTop: '8px'
                                    },
                                    '.MuiInputLabel-root': {
                                        top: '3px'
                                    }
                                }}
                                data={centerList}
                                name="centerIds"
                                label="Centers"
                                size="medium"
                                value={selectedNames}
                                onChange={(e: any) => {
                                    setSelectedNames(e.target.value);
                                }}
                                multiple
                                selectProps={{
                                    MenuProps: {
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }
                                }}
                            />
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </div>
    );
};

export default ClusterModal;
