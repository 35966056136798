// assets

import { Box, Button, Typography } from '@mui/material';
import { IconFile } from '@tabler/icons';
import CloudUpload from 'assets/images/icons/cloud-upload.svg';
import ModalCustom from 'components/Modal/ModalCustom';
import React from 'react';
import { useDropzone } from 'react-dropzone';

interface IProps {
    onClose: () => void;
    onUpload: (file: any) => Promise<boolean>;
    callback: any;
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: 2,
    marginBottom: 2
};
const focusedStyle = {
    borderColor: '#3F51B5'
};

const acceptStyle = {
    borderColor: '#3F51B5'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const styleBoxFile = {
    display: 'flex',
    justifyContent: 'space-between'
};

// material-ui
const UploadFileModal = (props: IProps) => {
    const { onClose, onUpload, callback } = props;
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        maxFiles: 1
    });

    const files = acceptedFiles.map((file: any) => (
        <Box sx={styleBoxFile} key={file}>
            <Typography
                variant="subtitle1"
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <IconFile style={{ marginRight: '10px' }} /> {file.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                {file.size} bytes
            </Typography>
        </Box>
    ));

    const uploadFile = async () => {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        setIsLoadingUpload(true);
        const res = await onUpload(acceptedFiles[0]);

        if (res) {
            onClose();
            callback();
            setIsLoadingUpload(false);
        } else {
            setIsLoadingUpload(false);
        }
    };

    const handleDownloadTemplate = () => {
        const filePath = '/templates/relief-pool/Relief Pool Import Template.xlsx';
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', 'Relief Pool Import Template.xlsx');
        document.body.appendChild(link);
        link.click();

        // Dọn dẹp
        document.body.removeChild(link);
    };

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    React.useEffect(() => {
        if (files.length > 0) {
            setIsDisabled(false);
        }
    }, [files]);
    return (
        <ModalCustom
            onSave={uploadFile}
            onClose={onClose}
            onCancel={onClose}
            title="Import File"
            contentSave="Import"
            disabled={isDisabled}
            isLoading={isLoadingUpload}
        >
            <>
                <section className="container">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginLeft: 10 }}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </Button>
                    </Box>
                    <Box {...getRootProps({})} sx={style}>
                        <img src={CloudUpload} alt="warning" />
                        <input {...getInputProps()} />
                        <p>Drag &lsquo;n&lsquo; drop file here, or click to select file</p>
                        <em>
                            Only <b>*.xlsx</b> excel file will be accepted
                        </em>
                    </Box>
                    <aside>{files}</aside>
                </section>
            </>
        </ModalCustom>
    );
};

export default UploadFileModal;
