import * as React from 'react';

// material-ui
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { Box, Button, CardContent, Grid, InputAdornment, Stack, TablePagination, TextField, Tooltip } from '@mui/material';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import SearchIcon from '@mui/icons-material/Search';
import CustomSelectCenter from 'components/CustomSelectCenter';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { ALL_CENTER_OPTION, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi, UserApi } from 'services/api';
import { useDebounce } from 'services/hooks/useDebounce';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { UserKechoProfile } from 'types/kecho/user-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import ViewDetail from './components/ViewDetail';
// import moment from 'moment';

interface Row extends UserKechoProfile {
    roleName?: string;
    action?: string;
}

// ==============================|| CUSTOMER LIST ||============================== //

const UserList = () => {
    const classes = useStyles();
    const [selected] = React.useState<string[]>([]);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [userList, setUserList] = React.useState<UserKechoProfile[]>([]);
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    const cols: IColumnTable<Row>[] = [
        {
            field: 'name',
            isSort: true,
            headerName: 'Username',
            width: '30%'
        },
        {
            field: 'email',
            isSort: true,
            headerName: 'Email',
            width: '40%'
        },
        {
            field: 'jobTitle',
            isSort: true,
            headerName: 'Job title',
            width: '20%',
            render: (row) => <>{row?.jobTitle || 'N/A'}</>
        },
        {
            field: 'roleName',
            isSort: true,
            headerName: 'Role',
            width: '10%',
            render: (row) => <>{row?.role?.roleName}</>
        },
        {
            field: 'action',
            isSort: false,
            headerName: 'Action',
            render: (row) => (
                <>
                    <Stack direction="row">
                        <Tooltip title="View detail" placement="top">
                            <Button
                                variant="outlined"
                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                onClick={() => handleShowDetail(row)}
                            >
                                <VisibilityTwoToneIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> View
                            </Button>
                        </Tooltip>
                    </Stack>
                </>
            )
        }
    ];

    React.useEffect(() => {
        handleGetCentreList();
    }, []);

    React.useEffect(() => {
        debounceHandleGetUserList();
    }, [filter]);

    const handleGetUserList = async () => {
        if (!filter.centreId) return;

        const { dataList, meta } = await UserApi.listUser({
            ...filter,
            centreId: filter.centreId !== ALL_CENTER_OPTION.id ? filter.centreId : ''
        });
        if (dataList) setUserList(dataList);
        if (meta) setMetaData(meta);
    };

    const debounceHandleGetUserList = useDebounce(handleGetUserList, 300);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList?.length > 0) {
            if (dataList?.length > 1) {
                dataList?.unshift(ALL_CENTER_OPTION);
            }
            setCentreData(dataList);
            setFilter((prev) => ({ ...prev, centreId: dataList[0]?.id }));
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, page: 1, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {};

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const isSelected = (name?: string) => {
        if (!name) return false;
        return selected.indexOf(name) !== -1;
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const handleShowDetail = (user: any) => {
        UIManager.showModal({
            content: (onClose: any) => <ViewDetail onClose={onClose} userDetail={user} />,
            stylesModal: {
                minWidth: '500px'
            }
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="User List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                            style={{ marginRight: '8px' }}
                        />
                        <CustomSelectCenter
                            data={centreData}
                            value={centreData.length > 0 ? filter.centreId : ''}
                            name="centerId"
                            label="Center"
                            onChange={(e) => {
                                setFilter({ ...filter, centreId: e.target.value, page: 1 });
                            }}
                            selectProps={{
                                MenuProps: {
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Create a user" placement="top">
                                <Button variant="contained" color="secondary" size="large" onClick={() => {}}>
                                    <AddIcon sx={{ fontSize: '1.3rem' }} />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                </Grid>
            </CardContent>

            {/* table */}
            {userList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={userList}
                            columns={cols}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                    </Box>

                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <EmptyData />
                        <br />
                        {filter.query !== '' && <div>Sorry we couldn't find any matches for {filter.query}</div>}
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default UserList;
