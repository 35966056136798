// project imports

// assets
// import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import { useEffect, useState } from 'react';
import reliefTicketApi from 'services/api/reliefTicketApi';
import { formatDateOnly } from 'utils/common/formatDate';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    reliefTicket: any;
}

const marginBottom = {
    marginBottom: '8px'
};

const DetailReliefModal = (props: IProps) => {
    const { onClose, reliefTicket } = props;
    const [reliefData, setReliefData] = useState<any>();

    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefTicketApi.getReliefTicketById(reliefTicket?.id);
            if (res) {
                setReliefData(res.data);
            }
        };

        getRelief();
    }, []);

    return (
        <ModalCustom
            sx={{ width: '450px' }}
            onSave={() => {}}
            onClose={onClose}
            onCancel={onClose}
            title="View Relief Detail"
            contentCancel="Close"
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Name: </b>
                        {reliefData?.name}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Email: </b>
                        {reliefData?.email}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Phone: </b>
                        {reliefData?.phoneNumber}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Diploma: </b>
                        {reliefData?.certification?.diploma}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Relief Type: </b>
                        {reliefData?.reliefType}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Interview Date: </b>
                        {formatDateOnly(reliefData?.interviewDate)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Availability: </b>
                        {reliefData?.availability}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Area: </b>
                        {reliefData?.area}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Prefer Work Location: </b>
                        {reliefData?.preferWorkLocation}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Salary: </b>
                        {reliefData?.salary} $
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Start Date: </b>
                        {formatDateOnly(reliefData?.commencementDate)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Termination Date: </b>
                        {formatDateOnly(reliefData?.cessationDate)}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Note: </b>
                        {reliefData?.note}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Agent: </b>
                        {reliefData?.agent}
                    </Typography>
                    <Typography gutterBottom component="div" sx={marginBottom}>
                        <b>Documents: </b>
                        {reliefData?.NRIC ? 'NRIC, ' : ''}
                        {reliefData?.medical ? 'Medical, ' : ''}
                        {reliefData?.declarationForm ? 'Declaration Form, ' : ''}
                        {reliefData?.bank ? 'Bank, ' : ''}
                        {reliefData?.contractSigned ? 'Contract Signed, ' : ''}
                        {reliefData?.educationalCertificates ? 'Educational Certificates, ' : ''}
                        {reliefData?.applicationForm ? 'Application Form' : ''}
                    </Typography>
                </Grid>
            </Grid>
        </ModalCustom>
    );
};

export default DetailReliefModal;
