import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useContext, useState } from 'react';
import { ArrangementOrder, HeadCell } from 'types';
import { FilterContext, IAttendees } from '..';
import { AttendanceContext } from './CollapseAttendance';
import StatusAttendance from './StatusAttendance';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { Order } from 'config';

interface Row extends IAttendees {
    action?: string;
    nameStaff?: string;
}

const TableAttendance = () => {
    const contextAttendance = useContext(AttendanceContext);
    const { filter, setFilter } = useContext(FilterContext);
    const [order, setOrder] = useState<'DESC' | 'ASC'>('ASC');
    const [orderBy, setOrderBy] = useState<string>('id');

    const rows = contextAttendance?.attendances || [];
    const col: IColumnTable<Row>[] = [
        {
            field: 'nameStaff',
            isSort: true,
            headerName: 'Staff name',
            render: (row) => <>{row?.staff?.nameStaff}</>
        },
        {
            field: 'checkInTime',
            isSort: true,
            headerName: 'Check - in',
            render: (row) => <>{formatTime(row?.checkInTime)}</>
        },
        {
            field: 'checkOutTime',
            isSort: true,
            headerName: 'Check - out',
            render: (row) => <>{formatTime(row?.checkOutTime)}</>
        },
        {
            field: 'status',
            isSort: true,
            headerName: 'Status',
            render: (row) => <StatusAttendance status={row?.status} />
        }
    ];

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setOrder(isAsc);
        setOrderBy(property);
        setFilter({
            ...filter,
            order: isAsc,
            sortColumn: property
        });
    };

    function formatTime(time: string) {
        if (!time) {
            return '';
        }
        const timeParts = time.split(':');
        const formattedTime = `${timeParts[0]}:${timeParts[1]}`;

        return formattedTime;
    }

    const formatOrder: any = order.toLocaleLowerCase();

    return (
        <TableCustom
            hasSort
            isDefaultHeaderColor
            rows={rows}
            columns={col}
            onRequestSort={handleRequestSort}
            order={formatOrder}
            orderBy={orderBy}
        />
    );
};

export default TableAttendance;
