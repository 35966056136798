import SearchIcon from '@mui/icons-material/Search';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    TablePagination,
    TextField,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { LeaveApi, RatioApi, StaffApi } from 'services/api';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { v4 as uuidv4 } from 'uuid';
// import { ClassKecho } from 'types/kecho/class-kecho';
import { makeStyles } from '@mui/styles';
import EmptyData from 'components/errors/empty-data';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { ABSENCE_TYPE, convertTime, DEFAULT_PAGINATION, timeOfDay } from 'config';
import UIManager from 'core/UIManager';
import { debounce } from 'lodash';
import moment from 'moment';
import ConflictDeploymentModal from './ConflictDeploymentModal';

interface IProps {
    onClose: any;
    callback?: any;
    centreId?: string;
}

interface Row {
    id: string;
    staffId: string;
    name: string;
    diplomas: string;
    type: string;
    email: string;
    select: string;
}

const convertData = (data: any): Row[] => {
    return data.map((item: any) => {
        return {
            ...item,
            name: item.nameStaff,
            level: item.cert?.diploma,
            numberChild: 12,
            diplomas: item?.staffCert?.map((staff: any) => staff?.cert?.diploma).join(', ') || '-',
            type: item?.cert?.title
        };
    });
};

const useStyles = makeStyles(() => ({
    selectMenu: {
        maxHeight: '200px'
    }
}));

export default function AbsenceModal({ onClose, callback, centreId }: IProps) {
    const classes = useStyles();
    const [selectStaff, setSelectStaff] = useState<any>();

    const [StaffData, setStaffData] = useState<StaffKecho[]>([]);
    const [metaData, setMetaData] = useState<IMetaData>({});
    // const [limitData] = useState<number>(10);
    const [fromDate, setFromDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [endDate, setEndDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [startHour, setStartHour] = useState(7);
    const [endHour, setEndHour] = useState(7.5);
    const [type, setType] = useState<string>('ANNUAL LEAVE');
    const [note, setNote] = useState<string>('');
    const [msgDate, setMsgDate] = useState<string>('');
    const [msgHour, setMsgHour] = useState<string>('');
    const [msgRes, setMsgRes] = useState<string>('');

    const [filter, setFilter] = useState<IFilterRequest>(DEFAULT_PAGINATION);

    const [step, setStep] = useState<number>(1);

    const { page, take } = filter;

    const col: IColumnTable<Row>[] = [
        {
            field: 'name',
            headerName: 'Name'
        },
        {
            field: 'email',
            headerName: 'Email'
        },
        {
            field: 'diplomas',
            headerName: 'Diplomas'
        },
        {
            field: 'type',
            headerName: 'Type'
        },
        {
            field: 'select',
            headerName: 'Select',
            render: (row) => {
                return <Radio checked={row?.id === selectStaff?.id} onChange={(event) => setSelectStaff(row)} />;
            }
        }
    ];

    useEffect(() => {
        handleGetClassData();
    }, [filter]);

    const handleGetClassData = async () => {
        const payload = { ...filter, centreId, isTemporary: 'false' };
        const { dataList, error, meta } = await StaffApi.list(payload);
        if (!error) {
            if (dataList) setStaffData(dataList.filter((item: any) => !item.isTemporary));
            if (meta) setMetaData(meta);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({ ...filter, take: Number(event.target.value) });
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchStaff = debounce(onChange, 500);

    const handleValidate = (strH: any, endH: any, strD: any, endD: any) => {
        if (strD < endD) setMsgHour('');
        else if (strD > endD) setMsgHour('');
        else if (strH < endH && strD <= endD) setMsgHour('');
        else if (strH === endH && strD < endD) setMsgHour('');
        else if (strH >= endH && strD === endD) setMsgHour('End Time must be greater than Start Time');
        else setMsgHour('End Time must be greater than Start Time');
    };

    const handleApply = async () => {
        const { data } = await LeaveApi.addAbsence({
            staffId: selectStaff.id,
            categoryAbsence: type,
            fromDate: moment(fromDate).format('yyyy-MM-DD'),
            toDate: moment(endDate).format('yyyy-MM-DD'),
            fromHour: startHour,
            toHour: endHour,
            remarks: note
        });
        if (data) {
            await RatioApi.checkLeave();
            UIManager.showAlert({
                message: 'Add absence successfully',
                alert: {
                    color: 'success'
                }
            });
            if (callback) callback();
            onClose();
        }
    };

    const handleCheck = async () => {
        setMsgRes('');
        if (msgHour || msgDate || !fromDate || !endDate) {
            return;
        }

        if (startHour >= endHour && fromDate === endDate) {
            setMsgHour('End Time must be greater than Start Time');
            return;
        }
        const res: any = await LeaveApi.checkAbsence({
            staffId: selectStaff.id,
            categoryAbsence: type,
            fromDate: moment(fromDate).format('yyyy-MM-DD'),
            toDate: moment(endDate).format('yyyy-MM-DD'),
            fromHour: startHour,
            toHour: endHour,
            remarks: note
        });

        const data = res?.data;

        if (data && !data?.canAddAbsence) {
            setMsgRes('This staff has already been added absence on this time');
            return;
        }

        if (data && data?.canAddAbsence) {
            if (data?.data) {
                onClose();
                UIManager.showModal({
                    content: (onClosePopup: any) => (
                        <ConflictDeploymentModal
                            onClose={onClosePopup}
                            data={data?.data}
                            onSave={() => {
                                handleApply();
                                onClosePopup();
                            }}
                        />
                    ),
                    stylesModal: {
                        maxWidth: '600px',
                        width: '100%'
                    }
                });
            } else {
                setStep(3);
            }
        }
    };

    const handleStep = () => {
        if (!selectStaff) {
            UIManager.showAlert({
                message: 'Please choose a staff',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        if (step === 1) setStep(2);
        else if (step === 2) handleCheck();
        else handleApply();
    };

    const renderTableStaff = () => {
        return (
            <Paper
                sx={{
                    minWidth: '600px'
                }}
            >
                <Grid
                    item
                    xs={4}
                    sx={{
                        marginBottom: '20px'
                    }}
                >
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearchStaff}
                        placeholder="Search by name"
                        size="small"
                        fullWidth
                    />
                </Grid>

                {convertData(StaffData).length ? (
                    <>
                        <TableCustom
                            isInterleavedStyle
                            rows={convertData(StaffData)}
                            columns={col}
                            onRowClick={(row) => {
                                setSelectStaff(row);
                            }}
                        />

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={metaData?.itemCount || StaffData?.length}
                            rowsPerPage={take}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    const renderStep2 = () => {
        return (
            <>
                <Typography variant="subtitle1" gutterBottom component="div">
                    Staff: {selectStaff.nameStaff} - {selectStaff.cert?.diploma} - {selectStaff.cert?.type}
                </Typography>
                <Grid container spacing={2} style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12}>
                        <FormControl fullWidth error={Boolean(msgDate)}>
                            <DateRangeLocalDesktopDatePicker
                                views={['year', 'month', 'day']}
                                isRequired
                                disablePast
                                isAllowEqual
                                disabled={step === 3}
                                isShowErrorMessage={false}
                                sx={{ gap: '16px' }}
                                dateSx={{ maxWidth: 'unset' }}
                                errorTextSx={{ position: 'relative', top: '0', left: '10px', fontSize: '0.75rem' }}
                                fromDateValue={fromDate}
                                toDateValue={endDate}
                                renderInput={(params: any) => <TextField size="large" fullWidth {...params} />}
                                onChange={(value) => {
                                    if (value.errorText) {
                                        setMsgDate(value.errorText);
                                    } else {
                                        setMsgDate('');
                                    }
                                    setFromDate(value.fromDate);
                                    setEndDate(value.toDate);
                                }}
                                onError={() => {
                                    setMsgDate('');
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {msgDate && (
                    <Grid sx={{ mt: 0 }} container spacing={2} columns={16}>
                        <FormHelperText error id="standard-weight-helper-text--register" style={{ marginLeft: '20px' }}>
                            {msgDate}
                        </FormHelperText>
                    </Grid>
                )}
                <Grid container spacing={2} style={{ marginTop: 5 }} justifyContent="space-between">
                    <Grid item xs={6} sm={6}>
                        <FormControl fullWidth error={Boolean(startHour && msgHour)}>
                            <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                            <Select
                                disabled={step === 3}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="startHour"
                                value={startHour}
                                label="Start Time"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setStartHour(value);
                                    handleValidate(value, endHour, fromDate, endDate);
                                }}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }}
                            >
                                {timeOfDay.map((column: any) => (
                                    <MenuItem key={uuidv4()} value={column}>
                                        {convertTime(column)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormControl fullWidth error={Boolean(endHour && msgHour)}>
                            <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                            <Select
                                disabled={step === 3}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="endHour"
                                value={endHour}
                                label="End Time"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setEndHour(value);
                                    handleValidate(startHour, value, fromDate, endDate);
                                }}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }}
                            >
                                {timeOfDay.map((column: any) => (
                                    <MenuItem key={uuidv4()} value={column}>
                                        {convertTime(column)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {msgHour && (
                    <Grid sx={{ mt: 0 }} container spacing={2} columns={16}>
                        <FormHelperText error id="standard-weight-helper-text--register" style={{ marginLeft: '20px' }}>
                            {msgHour}
                        </FormHelperText>
                    </Grid>
                )}
                <Grid item xs={16} style={{ paddingTop: 20 }}>
                    <FormControl fullWidth>
                        <InputLabel id="absenceType-label">Absence Type</InputLabel>
                        <Select
                            disabled={step === 3}
                            labelId="absenceType-label"
                            id="absenceType"
                            name="absenceType"
                            label="Absence Type"
                            value={type}
                            onChange={(e) => {
                                setType(e.target.value);
                            }}
                        >
                            {Object.keys(ABSENCE_TYPE)?.map((key: any) => (
                                <MenuItem key={uuidv4()} value={ABSENCE_TYPE[key]}>
                                    {ABSENCE_TYPE[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <TextField
                    style={{ marginTop: 20 }}
                    fullWidth
                    multiline
                    rows={3}
                    label="Reason"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </>
        );
    };

    function renderButtonSaveContent() {
        if (step === 1) {
            return 'Next';
        }
        if (step === 2) {
            return 'Check';
        }
        if (step === 3) {
            return 'Apply';
        }
        return undefined;
    }

    function renderButtonCancelContent() {
        if (step === 1) {
            return undefined;
        }
        if (step === 2) {
            return 'Back';
        }
        if (step === 3) {
            return 'Cancel';
        }
        return undefined;
    }

    function renderButtonCancelFunc() {
        if (step === 1) {
            return undefined;
        }
        if (step === 2) {
            setMsgRes('');
            return setStep(1);
        }
        if (step === 3) {
            return onClose();
        }
        return undefined;
    }

    return (
        <ModalCustom
            onSave={handleStep}
            onClose={onClose}
            onCancel={() => renderButtonCancelFunc()}
            title={step === 1 ? 'Please select staff' : 'Please select time'}
            contentCancel={renderButtonCancelContent()}
            contentSave={renderButtonSaveContent()}
            sx={{ maxHeight: step === 1 ? '300px' : 'auto', overflowY: step === 1 ? 'scroll' : 'hidden' }}
            typeHelper={msgDate || msgRes ? 'error' : 'warning'}
            msgHelper={msgRes}
        >
            <>
                {step === 1 && renderTableStaff()}
                {(step === 2 || step === 3) && renderStep2()}
            </>
        </ModalCustom>
    );
}
