import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';
import { IFilterRequest } from 'types/query/filter';

const BaseApi = 'qualification/';

const QualificationApi = {
    listQualification(body?: IFilterRequest): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base, body);
        return axiosAuthClient.get(url);
    },
    createQualification(body: any): Promise<PromiseApi> {
        const base = `${BaseApi}`;
        const url = buildUrl(base);
        return axiosAuthClient.post(url, body);
    },
    updateQualification(id: string, body: any): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    },
    getDetailsClusterById(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    deleteQualification(id: string): Promise<PromiseApi> {
        const base = `${BaseApi}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.delete(url);
    },
    updateCertificate(id: string, body: { price: number }): Promise<PromiseApi> {
        const base = `${'certification/'}${id}`;
        const url = buildUrl(base);
        return axiosAuthClient.patch(url, body);
    },
    listCertification(): Promise<PromiseApi> {
        const url = `${'certification/'}`;
        return axiosAuthClient.get(url);
    }
};
export default QualificationApi;
