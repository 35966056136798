import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';

export interface IFooterModalProps {
    iconCancel?: any;
    iconSave?: any;
    contentCancel?: string;
    contentSave?: string;
    disabled?: boolean;
    isLoading?: boolean;
    onSave: () => any;
    onCancel: () => any;
}
const FooterModal = (props: IFooterModalProps) => {
    const { iconSave, iconCancel, contentCancel, contentSave, disabled, onSave, onCancel, isLoading = false } = props;
    const IconSave = iconSave;
    const IconCancel = iconCancel;

    if (!contentCancel && !contentSave) {
        return <></>;
    }

    return (
        <Grid item xs={11} textAlign="right" style={{ padding: '20px 20px 8px 20px', borderTop: '1px solid #E0E0E0' }}>
            {contentCancel && (
                <Button
                    variant="outlined"
                    style={{
                        color: '#3F51B5',
                        border: '1px solid #3F51B5',
                        marginRight: 10,
                        maxHeight: '42px',
                        minHeight: '42px',
                        boxShadow: 'none'
                    }}
                    disabled={isLoading}
                    size="small"
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                    }}
                >
                    {IconCancel && <IconCancel style={{ width: 20, marginBottom: 2, marginRight: 8 }} />}
                    {contentCancel}
                </Button>
            )}
            {contentSave && (
                <LoadingButton
                    loading={isLoading}
                    loadingPosition="start"
                    className="btn-save"
                    variant="contained"
                    size="small"
                    type="submit"
                    disabled={disabled}
                    style={{
                        backgroundColor: '#3F51B5',
                        border: '1px solid #3F51B5',
                        maxHeight: '42px',
                        minHeight: '42px',
                        boxShadow: 'none',
                        color: 'white',
                        paddingLeft: isLoading ? '30px' : '10px'
                    }}
                    onClick={() => {
                        if (onSave) {
                            onSave();
                        }
                    }}
                >
                    {IconSave && <IconSave style={{ width: 20, marginBottom: 2, marginRight: 8 }} />}
                    {contentSave}
                </LoadingButton>
            )}
        </Grid>
    );
};

export default FooterModal;
