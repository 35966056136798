// project imports

// assets
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { convertTime, ReliefStatus } from 'config';
import { StaffKecho } from 'types/kecho/staff-kecho';
import UIManager from 'core/UIManager';
import { useEffect, useState } from 'react';
import { formatDateOnly } from 'utils/common/formatDate';
import ModalCustom from 'components/Modal/ModalCustom';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    staff: StaffKecho;
    relief: string;
}

const ChooseStaffModal = (props: IProps) => {
    const { onClose, staff, relief } = props;
    const navigate = useNavigate();
    const [reliefData, setReliefData] = useState<any>();
    // const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefApi.getReliefDetail(relief);
            if (res) {
                setReliefData(res.data);
            }
        };

        getRelief();
    }, []);

    const handleOk = async () => {
        onClose();
        const res = await reliefApi.UpdateReliefById(relief, ReliefStatus.STAFF_ASSIGNED, staff);
        if (res) {
            UIManager.showAlert({
                message: 'Staff assigned',
                alert: {
                    color: 'success'
                }
            });
            navigate('/staff/relief-list', { replace: true });
        }
    };

    return (
        <>
            <ModalCustom
                onSave={handleOk}
                onCancel={onClose}
                onClose={onClose}
                title="Confirm optimization"
                contentSave="Yes"
                contentCancel="No"
            >
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <Typography gutterBottom component="div" style={{ fontSize: '15px' }}>
                        Staff <strong>{staff.email}</strong> will support center <strong>{reliefData?.center?.nameCenter}</strong> from
                        <br />
                        <strong>
                            {formatDateOnly(reliefData?.startDate)} {convertTime(reliefData?.startHour)}
                        </strong>{' '}
                        to{' '}
                        <strong>
                            {formatDateOnly(reliefData?.endDate)} {convertTime(reliefData?.endHour)}
                        </strong>
                    </Typography>
                    <Typography gutterBottom component="div" style={{ fontSize: '15px', color: '#F44336' }}>
                        Are you sure you want to continue?
                    </Typography>
                </Grid>
            </ModalCustom>
        </>
    );
};

export default ChooseStaffModal;
