import { useEffect, useState } from 'react';
// project imports

// assets

import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
// third party
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

// project imports

// assets
import { makeStyles } from '@mui/styles';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import { convertTime, formatDate, ReasonForRequestRelief, ReliefStatus, timeOfDay } from 'config';
import moment from 'moment';
import { qualificationApi } from 'services/api';
import reliefApi from 'services/api/reliefApi';
import staffApi from 'services/api/staffApi';
import { QualificationKecho } from 'types/kecho/relief';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { formatDateCustomFormat } from 'utils/common/formatDate';
import { navigatePath } from 'utils/utils';
// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    centerId?: string;
    currentDate?: any;
}

const useStyles = makeStyles(() => ({
    selectMenu: {
        maxHeight: '200px'
    }
}));

const AddReliefModal = (props: IProps) => {
    const { onClose, centerId, currentDate } = props;

    const theme = useTheme();
    const classes = useStyles();
    const [startDate, setStartDate] = useState(moment().format(formatDate.DATE_DEFAULT));
    const [endDate, setEndDate] = useState(moment().format(formatDate.DATE_DEFAULT));
    const [startHour, setStartHour] = useState(7);
    const [endHour, setEndHour] = useState(7.5);
    const [staffList, setStaffList] = useState<StaffKecho[]>([]);
    const [qualificationList, setQualificationList] = useState<QualificationKecho[]>([]);
    const [msgHour, setMsgHour] = useState<string>('');

    useEffect(() => {
        handleGetStaffList();
        handleGetQualificationList();
    }, []);

    useEffect(() => {
        updateDate();
    }, [currentDate]);

    // const handleChangeDate = (newValue: any) => {
    //     if (newValue) {
    //         setEndDate(newValue);
    //         setStartDate(newValue);
    //     }
    // };

    const updateDate = () => {
        setStartDate(currentDate);
        setEndDate(currentDate);
    };

    const handleValidate = (strH: any, endH: any, strD: any, endD: any) => {
        if (strD < endD) setMsgHour('');
        else if (strH < endH && strD <= endD) setMsgHour('');
        else if (strH === endH && strD < endD) setMsgHour('');
        else if (strH >= endH && strD === endD) setMsgHour('End Time must be greater than Start Time');
        else setMsgHour('End Time must be greater than Start Time');
    };

    const handleGetStaffList = async () => {
        if (!centerId) return;

        const { dataList } = await staffApi.listAllStaffByCenter({ centreId: centerId });
        if (dataList) setStaffList(dataList.filter((item: any) => !item.isTemporary));
    };

    const handleGetQualificationList = async () => {
        const { dataList } = await qualificationApi.listQualification();
        if (dataList) setQualificationList(dataList);
    };

    const handleAskRelief = async (obj: any, config: any) => {
        if (msgHour) {
            return;
        }
        if (startHour >= endHour && startDate === endDate) {
            setMsgHour('End Time must be greater than Start Time');
            return;
        }
        const payload = {
            ...obj,
            startDate: formatDateCustomFormat(startDate, 'yyyy-MM-dd'),
            endDate: formatDateCustomFormat(endDate, 'yyyy-MM-dd'),
            startHour,
            endHour,
            reason: obj.reason?.includes('Other reasons') ? obj.otherReason : obj.reason,
            status: ReliefStatus.DRAFT,
            centerId
        };
        delete payload.otherReason;

        const { data } = await reliefApi.createRelief(payload);
        if (data) {
            const idRelief = data.id;
            const day = moment(endDate).diff(moment(startDate), 'days') + 1;

            navigatePath(
                `/ask-wakyu?idRelief=${idRelief}&startDate=${formatDateCustomFormat(
                    startDate,
                    'yyyy-MM-dd'
                )}&endDate=${formatDateCustomFormat(endDate, 'yyyy-MM-dd')}&workHour=${payload.workHour}&workHour=${
                    payload.workHour
                }&day=${day}`
            );
        }
    };
    return (
        <div style={{ width: '40vw' }}>
            <Formik
                validationSchema={Yup.object().shape({
                    absentStaff: Yup.string().max(255).required('Name is required'),
                    reason: Yup.string().max(255).required('Reason is required'),
                    qualification: Yup.string().max(255).required('Qualification is required'),
                    otherReason: Yup.string().when('reason', {
                        is: ReasonForRequestRelief[4].text,
                        then: Yup.string().required('Other reason is required')
                    })
                })}
                initialValues={{
                    absentStaff: '',
                    reason: '',
                    otherReason: '',
                    qualification: ''
                }}
                onSubmit={handleAskRelief}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                    <ModalCustom
                        onSave={submitForm}
                        disabled={isSubmitting}
                        onClose={onClose}
                        onCancel={() => {}}
                        title="Ask a Relief"
                        contentSave="Create"
                    >
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid sx={{ mt: 1 }} container spacing={2} columns={16}>
                                <Grid item xs={16} sm={8} sx={{ maxWidth: '100% !important', flexBasis: '100% !important' }}>
                                    <DateRangeLocalDesktopDatePicker
                                        fromDateValue={startDate}
                                        toDateValue={endDate}
                                        views={['year', 'month', 'day']}
                                        isRequired
                                        disablePast
                                        isAllowEqual
                                        sx={{ gap: '16px' }}
                                        dateSx={{ maxWidth: 'unset' }}
                                        errorTextSx={{ position: 'relative', top: '0', left: '10px', fontSize: '0.75rem' }}
                                        dateRangeErrorTextSx={{
                                            position: 'relative',
                                            top: '0',
                                            left: '10px',
                                            fontSize: '0.75rem'
                                        }}
                                        renderInput={(params: any) => <TextField size="large" fullWidth {...params} />}
                                        onChange={(value: any) => {
                                            setStartDate(value.fromDate);
                                            setEndDate(value.toDate);
                                            handleValidate(startHour, endHour, value.fromDate, value.toDate);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid sx={{ mt: 0 }} container spacing={2} columns={16}>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(startHour && msgHour)}>
                                        <InputLabel id="demo-simple-select-label" style={{ top: '10px' }}>
                                            Start Time
                                        </InputLabel>
                                        <Select
                                            style={{ lineHeight: '1.4375em', height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="startHour"
                                            value={startHour}
                                            label="Start Time"
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                setStartHour(value);
                                                handleValidate(value, endHour, startDate, endDate);
                                            }}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {timeOfDay.map((column: any) => (
                                                <MenuItem key={uuidv4()} value={column}>
                                                    {convertTime(column)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(endHour && msgHour)}>
                                        <InputLabel id="demo-simple-select-label" style={{ top: '10px' }}>
                                            End Time
                                        </InputLabel>
                                        <Select
                                            style={{ lineHeight: '1.4375em', height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="endHour"
                                            value={endHour}
                                            label="End Time"
                                            onChange={(e) => {
                                                const value = Number(e.target.value);
                                                setEndHour(value);
                                                handleValidate(startHour, value, startDate, endDate);
                                            }}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {timeOfDay.map((column: any) => (
                                                <MenuItem key={uuidv4()} value={column}>
                                                    {convertTime(column)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {msgHour && (
                                <Grid sx={{ mt: 0 }} container spacing={2} columns={16}>
                                    <FormHelperText error id="standard-weight-helper-text--register" style={{ marginLeft: '28px' }}>
                                        {msgHour}
                                    </FormHelperText>
                                </Grid>
                            )}

                            <Grid sx={{ mt: 0 }} container spacing={2} columns={16}>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(touched.absentStaff && errors.absentStaff)}>
                                        <InputLabel id="demo-simple-select-label" style={{ top: '10px' }}>
                                            Staff Name
                                        </InputLabel>
                                        <Select
                                            style={{ lineHeight: '1.4375em', height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="absentStaff"
                                            value={values.absentStaff}
                                            label="Staff Name"
                                            onChange={handleChange}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {staffList?.map((item) => (
                                                <MenuItem key={uuidv4()} value={item.id}>
                                                    {item.nameStaff}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.absentStaff && errors.absentStaff && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.absentStaff}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={16} sm={8}>
                                    <FormControl fullWidth error={Boolean(touched.qualification && errors.qualification)}>
                                        <InputLabel id="demo-simple-select-label" style={{ top: '10px' }}>
                                            Qualification
                                        </InputLabel>
                                        <Select
                                            style={{ lineHeight: '1.4375em', height: '62px', marginTop: '8px' }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="qualification"
                                            value={values.qualification}
                                            label="qualification"
                                            onChange={handleChange}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }}
                                        >
                                            {qualificationList?.map((item) => (
                                                <MenuItem key={uuidv4()} value={item.id}>
                                                    {`${item.title}  ($ ${item.price})`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.qualification && errors.qualification && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.qualification}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={16} sm={8} mt="24px">
                                <FormControl fullWidth error={Boolean(touched.reason && errors.reason)}>
                                    <InputLabel id="demo-simple-select-label" style={{ top: '0px' }}>
                                        Reason
                                    </InputLabel>
                                    <Select
                                        style={{ lineHeight: '1.4375em', height: '62px' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="reason"
                                        label="Reason"
                                        value={values.reason}
                                        onChange={handleChange}
                                    >
                                        {ReasonForRequestRelief?.map((item) => (
                                            <MenuItem key={uuidv4()} value={item.text}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.reason && errors.reason && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.reason}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {values.reason?.includes('Other reasons') ? (
                                <Grid item xs={16} sm={8} mt={2}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.otherReason && errors.otherReason)}
                                        // sx={{ ...theme.typography.customInput }}
                                    >
                                        <TextField
                                            size="medium"
                                            label="Other Reason"
                                            id="outlined-adornment-name-register"
                                            type="text"
                                            value={values.otherReason}
                                            name="otherReason"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            sx={{ '.MuiOutlinedInput-input': { padding: '20px 14px' } }}
                                        />
                                        {touched.otherReason && errors.otherReason && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.otherReason}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </form>
                    </ModalCustom>
                )}
            </Formik>
        </div>
    );
};

export default AddReliefModal;
