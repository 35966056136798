import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import React, { useEffect } from 'react';
import ReliefList from '../Relief';
import ReliefTicket from '../ReliefTicket';
import TimesheetApproval from '../TimesheetApproval';
import './styles.scss';
import { AUTH_LOCAL_STORAGE_KEY, RedirectPath, Roles } from 'config';
import { useNavigate } from 'react-router-dom';

interface ReliefManagementTabsProps {
    tab?: string;
}
const ReliefManagementTabs = ({ tab }: ReliefManagementTabsProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const path = window.location.pathname;
    const navigate = useNavigate();
    const [value, setValue] = React.useState(tab || '1');

    useEffect(() => {
        if (path.includes(RedirectPath.RELIEF_TICKET)) {
            setValue('2');
        } else if (path.includes(RedirectPath.TIMESHEET_LIST)) {
            setValue('3');
        } else {
            setValue('1');
        }
    }, [path]);
    const handleChangeTabRelief = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            navigate(RedirectPath.RELIEF_LIST);
        } else if (newValue === '2') {
            navigate(RedirectPath.RELIEF_TICKET);
        } else if (newValue === '3') {
            navigate(RedirectPath.TIMESHEET_LIST);
        }
    };

    const isShowTabReliefTicket = () => {
        return userRole !== Roles.User;
    };
    const isShowTabReliefPool = () => {
        return userRole === Roles.HrAdmin || userRole === Roles.HrIc || userRole === Roles.Principals || userRole === Roles.Cluster;
    };
    const isShowTabTimesheet = () => {
        return (
            userRole === Roles.Principals ||
            userRole === Roles.Admin ||
            userRole === Roles.Cluster ||
            userRole === Roles.HrIc ||
            userRole === Roles.HrAdmin
        );
    };
    return (
        <div className="relief-tab-management">
            <TabContext value={value}>
                <TabList onChange={handleChangeTabRelief} className="relief-tab-list">
                    {isShowTabReliefTicket() && <Tab label="Relief Ticket" value="1" />}
                    {isShowTabReliefPool() && <Tab label="Relief Pool" value="2" />}
                    {isShowTabTimesheet() && <Tab label="Timesheet Approval" value="3" />}
                </TabList>
                <TabPanel value="1" className="relief-manage-panel relief-list-panel">
                    <ReliefList />
                </TabPanel>
                <TabPanel value="2" className="relief-manage-panel relief-pool-panel">
                    <ReliefTicket />
                </TabPanel>
                <TabPanel value="3" className="relief-manage-panel timsheet-approval-panel">
                    <TimesheetApproval />
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default ReliefManagementTabs;
