import * as React from 'react';
// material-ui
import { Box, Button, Chip, Grid, TablePagination, Tooltip, Typography } from '@mui/material';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
// import AcceptIcon from 'assets/images/icons/page-actions/tabler-icon-check.svg';
// import DenyIcon from 'assets/images/icons/page-actions/tabler-icon-circle-minus.svg';
// import LateIcon from 'assets/images/icons/page-actions/tabler-icon-clock-cancel.svg';
import QRCodeIcon from 'assets/images/icons/page-actions/tabler-icon-qrcode.svg';
import MissingIcon from 'assets/images/icons/page-actions/tabler-icon-warning.svg';
import RejectIcon from 'assets/images/icons/page-actions/tabler-icon-ban.svg';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import AskWakyuIcon from 'assets/images/icons/page-actions/tabler-icon-message.svg';
import AssignTicketIcon from 'assets/images/icons/page-actions/tabler-icon-plus.svg';
import DeleteRedIcon from 'assets/images/icons/page-actions/tabler-icon-trash.svg';
import { IconCurrencyDollarSingapore, IconMessage2 } from '@tabler/icons';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import {
    AUTH_LOCAL_STORAGE_KEY,
    convertTime,
    DEFAULT_REQUEST,
    GET_ALL_REQUEST,
    Order,
    RELIEF_FROM,
    ReliefStatus,
    Roles,
    SEARCH_SCOPE,
    useStyles
} from 'config';
import UIManager from 'core/UIManager';
import QRCode from 'react-qr-code';
import { useSearchParams } from 'react-router-dom';
import reliefApi from 'services/api/reliefApi';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { calculateTotalPriceRelief } from 'utils/common/calculateTotalPrice';
import { formatDateOnly } from 'utils/common/formatDate';
import { navigatePath } from 'utils/utils';
import AddReliefModal from '../CalculationStaffRatio/List/Components/ClassTable/components/ReliefModal/ReliefModal';
import AssignReliefModal from './components/AssignReliefModal';
import DeleteReliefModal from './components/DeleteReliefModal';
import DetailReliefModal from './components/DetailReliefModal';
// import RejectReliefModal from './components/RejectReliefModal';
import CreateExternalReliefModal from './components/CreateExternalReliefModal';
import MissingInReliefModal from './components/MissingInReliefModal';
import AskClusterModal from './components/AskClusterModal';
import CustomSelectCenter from 'components/CustomSelectCenter';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { CentreApi } from 'services/api';

// sau extend interface cua list
interface Row {
    id?: string | number;
    nameCenter: string;
    startDate: string;
    endDate: string;
    status: string;
    diploma: string;
    candidate: string;
    action: string;
}

const ReliefList = () => {
    const classes = useStyles();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [reliefList, setReliefList] = React.useState<any[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, order: 'DESC', sortColumn: 'createdAt' });
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;
    const [searchParams] = useSearchParams();
    const centreId: any = searchParams.get('centreId');

    const col: IColumnTable<Row>[] = [
        {
            field: 'nameCenter',
            headerName: 'Center Name',
            isSort: true,
            width: '15%',
            render: (row: any) => <>{row?.center?.nameCenter}</>
        },
        {
            field: 'startDate',
            headerName: 'Start Date & Time',
            isSort: true,
            width: '10%',
            render: (row: any) => (
                <>
                    {formatDateOnly(row?.startDate)} {row?.startHour && `- ${convertTime(row?.startHour)}`}
                </>
            )
        },
        {
            field: 'endDate',
            headerName: 'End Date & Time',
            isSort: true,
            width: '10%',
            render: (row: any) => (
                <>
                    {formatDateOnly(row?.endDate)} {row?.endHour && `- ${convertTime(row?.endHour)}`}
                </>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            isSort: true,
            width: '10%',
            render: (row: any, index: number) => (
                <Chip
                    key={index}
                    sx={
                        statusColors[row?.status]
                            ? {
                                  ...statusColors[row?.status]
                              }
                            : {}
                    }
                    label={row?.status}
                />
            )
        },
        {
            field: 'diploma',
            headerName: 'Certifications',
            isSort: true,
            width: '25%',
            render: (row: any, index: number) => (
                <>
                    {row?.qualification?.qualificationCert?.length > 2 ? (
                        <>
                            {row?.qualification?.qualificationCert?.slice(0, 2).map((item: any) => (
                                <Chip key={item.id} label={item?.cert?.diploma} style={{ marginRight: 8 }} />
                            ))}
                            <Tooltip
                                title={row?.qualification?.qualificationCert
                                    ?.slice(2)
                                    ?.map((item: any) => item?.cert?.diploma)
                                    .join(', ')}
                            >
                                <Chip
                                    label={`+${row?.qualification?.qualificationCert?.length - 2} more`}
                                    style={{ marginRight: 8, cursor: 'help' }}
                                    size="small"
                                    variant="outlined"
                                />
                            </Tooltip>
                        </>
                    ) : (
                        row?.qualification?.qualificationCert?.map((item: any) => (
                            <Chip key={item.id} label={item?.cert?.diploma} style={{ marginRight: 8 }} />
                        ))
                    )}
                </>
            )
        },
        {
            field: 'candidate',
            headerName: 'Candidate',
            isSort: true,
            width: '10%',
            render: (row: any) => (
                <>
                    {row?.reliefStaff && row?.status !== ReliefStatus.PENDING_RELIEF_ASSIGNATION && (
                        <div style={{ marginTop: 5 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 10,
                                    fontWeight: 500
                                }}
                            >
                                <IconCurrencyDollarSingapore style={{ width: 20 }} />
                                {calculateTotalPriceRelief(
                                    row?.startDate,
                                    row?.endDate,
                                    row?.startHour,
                                    row?.endHour,
                                    row?.qualification?.price
                                )}
                            </div>
                            <Typography
                                style={{ cursor: 'copy', color: '#5D8BF4' }}
                                onClick={() => copyToClipBoard(row?.reliefStaff?.email)}
                            >
                                {row?.reliefStaff?.email}
                            </Typography>
                        </div>
                    )}
                </>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            render: (row: any) => <>{renderAction(row)}</>
        }
    ];

    React.useEffect(() => {
        if (isShowCenterFilter()) {
            handleGetCentreList();
        } else {
            setFilter({ ...filter, centreId: userCenterID });
        }
    }, []);
    React.useEffect(() => {
        if (filter?.centreId || filter?.centreId === null) {
            handleGetList();
        }
    }, [filter]);

    const handleGetList = async () => {
        const payload = {
            ...filter,
            page: filter.page,
            limit: filter.limit,
            sortColumn: filter.sortColumn,
            order: filter.order,
            scope: SEARCH_SCOPE.IN_CLUSTER,
            from: RELIEF_FROM.MY_CLUSTER
        };
        const { data } = await reliefApi.getReliefList(payload);
        if (data) {
            setReliefList(data.data);
            setMetaData(data.meta);
        }
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setFilter({ ...filter, centreId: dataList[0]?.id });
            setCentreData(dataList);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const handleRedirectToWakyu = (relief: any) => async () => {
        navigatePath(`/ask-wakyu?idRelief=${relief.id}`);
    };

    const handleAskCM = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <AskClusterModal onClose={onClose} reliefId={relief?.id} callback={handleGetList} />
        });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const handleDeleteRelief = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteReliefModal onClose={onClose} relief={relief} callback={handleGetList} />
        });
    };

    const handleUpdateStateRelief = (relief: any, status: ReliefStatus) => async () => {
        let statusUpdate = '';
        if (status === ReliefStatus.PENDING_RELIEF_ASSIGNATION) {
            statusUpdate = 'Missing in action';
        } else if (status === ReliefStatus.CM_REJECT) {
            statusUpdate = 'Reject';
        }
        const res = await reliefApi.UpdateReliefById(relief.id, status);
        if (res) {
            UIManager.showAlert({
                message: `Relief status updated to "${statusUpdate}"`,
                alert: {
                    color: 'success'
                }
            });
            handleGetList();
        }
    };

    const handleAssignRelief = (reliefId: string) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <AssignReliefModal reliefId={reliefId} onClose={onClose} callback={handleGetList} />,
            stylesModal: {
                minWidth: '900px'
            }
        });
    };

    const handleCreateExternalRelief = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <CreateExternalReliefModal onClose={onClose} callback={handleGetList} reliefId={relief.id} />,
            stylesModal: {
                minWidth: '600px'
            }
        });
    };

    const handleMissingInRelief = (relief: any) => async () => {
        UIManager.showModal({
            content: (onClose: any) => <MissingInReliefModal onClose={onClose} callback={handleGetList} reliefId={relief.id} />,
            stylesModal: {
                minWidth: '600px'
            }
        });
    };

    const handleOpenModalDetail = (relief: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DetailReliefModal onClose={onClose} relief={relief.id} />
        });
    };

    const copyToClipBoard = (text: string) => {
        navigator.clipboard.writeText(text);
        UIManager.showAlert({
            message: 'Copy to clipboard success',
            alert: {
                color: 'success'
            }
        });
    };

    const handleOpenModalAskRelief = () => {
        UIManager.showModal({
            content: (onClose: any) => <AddReliefModal onClose={onClose} centerId={centreId || userCenterID} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC && filter.sortColumn === sortColumn ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const statusColors: { [key: string]: { backgroundColor: string; color: string } } = {
        Draft: {
            backgroundColor: '#00000014',
            color: '#616161'
        },
        'CM rejected': {
            backgroundColor: '#FCE3E1',
            color: '#F44336'
        },
        'Pending Relief Assignation': {
            backgroundColor: '#F9EDC6',
            color: '#D28E0A'
        },
        'Pending Staff Assignation': {
            backgroundColor: '#F9EDC6',
            color: '#D28E0A'
        },
        'Relief Assigned': {
            backgroundColor: '#D4FADE',
            color: '#09B54E'
        },
        'Staff Assigned': {
            backgroundColor: '#D4FADE',
            color: '#09B54E'
        },
        Attended: {
            backgroundColor: '#D4FADE',
            color: '#09B54E'
        }
    };

    const renderButtonAction = (relief: any) => {
        if (relief?.status === ReliefStatus.DRAFT && userRole === Roles.Principals) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleRedirectToWakyu(relief)}
                    >
                        <img src={AskWakyuIcon} alt="ask wakyu" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Ask Wakyu
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleAskCM(relief)}
                    >
                        <img src={AskWakyuIcon} alt="ask cm" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Ask CM
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleDeleteRelief(relief)}
                    >
                        <img src={DeleteRedIcon} alt="delete" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Delete
                    </Button>
                </>
            );
        }
        if (
            relief?.status === ReliefStatus.RELIEF_ASSIGNED &&
            (userRole === Roles.Principals || userRole === Roles.HrAdmin || userRole === Roles.HrIc)
        ) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', whiteSpace: 'nowrap', marginBottom: 10 }}
                        onClick={handleMissingInRelief(relief)}
                    >
                        <img src={MissingIcon} alt="assign ticket" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Missing
                        in Action
                    </Button>
                </>
            );
        }
        if (relief?.status === ReliefStatus.PENDING_STAFF_ASSIGNATION && userRole === Roles.Cluster) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleRedirectToWakyu(relief)}
                    >
                        <img src={AskWakyuIcon} alt="ask wakyu" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Ask Wakyu
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleCreateExternalRelief(relief)}
                    >
                        <img src={AssignTicketIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Create
                        Ticket
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ color: '#F44336', border: '1px solid #F44336', marginBottom: 10 }}
                        onClick={handleUpdateStateRelief(relief, ReliefStatus.CM_REJECT)}
                    >
                        <img src={RejectIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Reject
                    </Button>
                </>
            );
        }
        if (
            relief?.status === ReliefStatus.PENDING_RELIEF_ASSIGNATION &&
            (userRole === Roles.Principals || userRole === Roles.Cluster || userRole === Roles.HrAdmin || userRole === Roles.HrIc)
        ) {
            return (
                <>
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={handleAssignRelief(relief.id)}
                    >
                        <img src={AssignTicketIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Assign
                        Relief
                    </Button>
                </>
            );
        }
        return <></>;
    };

    const renderAction = (relief: any) => {
        return (
            <>
                <div className="group-button-actions">
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10, marginBottom: 10 }}
                        onClick={() => handleOpenModalDetail(relief)}
                    >
                        <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                    </Button>
                    {(relief?.status === ReliefStatus.RELIEF_ASSIGNED || relief?.status === ReliefStatus.ATTENDED) &&
                        userRole === Roles.Principals && (
                            <Button
                                variant="contained"
                                style={
                                    !relief?.reliefStaff?.isTemporary
                                        ? { background: '#e1e1e1', marginRight: 10, marginBottom: 10 }
                                        : {
                                              background: 'transparent',
                                              color: '#3F51B5',
                                              border: '1px solid #3F51B5',
                                              boxShadow: 'none',
                                              marginRight: 10,
                                              marginBottom: 10
                                          }
                                }
                                onClick={() => handleCreateQrcode(relief)}
                                disabled={!relief?.reliefStaff?.isTemporary}
                            >
                                <img src={QRCodeIcon} alt="qrcode" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                                Generate QR
                            </Button>
                        )}

                    {renderButtonAction(relief)}
                </div>
            </>
        );
    };

    const handleCreateQrcode = (relief: any) => {
        if (userRole !== Roles.Principals) {
            UIManager.showAlert({
                message: 'You are not allowed to create QR code',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        const urlOrigin = window.location.origin;
        const reliefEmail = relief?.reliefTicket?.email || relief?.reliefStaff?.email || '';
        const reliefId = relief?.id || '';
        const centerName = encodeURIComponent(relief?.center?.nameCenter) || '';
        const reliefName = encodeURIComponent(relief?.reliefStaff?.nameStaff) || '';
        UIManager.showModal({
            content: (onClose: any) => (
                <div
                    style={{
                        width: '500px',
                        height: '500px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Typography fontSize={20} fontWeight={600} mb={1}>
                            Scan the QR to access Kecho Attendance
                        </Typography>
                        <Typography fontSize={16} fontWeight={400}>
                            Candidate: {reliefEmail}
                        </Typography>
                    </div>
                    <QRCode
                        value={`${urlOrigin}/staff/attendance/${reliefName}/${centerName}/${reliefId}`}
                        style={{ width: '300px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    />
                    <div className="redirect-attendance" style={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            style={{ background: '#3f51b5' }}
                            onClick={() => {
                                navigatePath(`/staff/attendance/${reliefName}/${centerName}/${reliefId}`);
                            }}
                        >
                            Go to Attendance
                        </Button>
                    </div>
                </div>
            )
        });
    };

    return (
        <Box sx={{ minHeight: '80vh', height: 'auto', padding: '0px 24px', backgroundColor: 'white' }}>
            {isShowCenterFilter() && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '12px' }}>
                    <CustomSelectCenter
                        data={centreData}
                        value={centreData.length > 0 && filter.centreId ? filter.centreId : 'null'}
                        name="centerId"
                        label="Center"
                        onChange={(e) => {
                            const center = e.target.value;
                            setFilter({ ...filter, centreId: center === 'null' ? null : center });
                        }}
                        selectProps={{
                            MenuProps: {
                                classes: {
                                    paper: classes.selectMenu
                                }
                            }
                        }}
                    />
                </Box>
            )}
            {isShowCenterFilter() ? null : (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '12px' }}>
                    <Tooltip title="Ask a relief" placement="top">
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                            size="large"
                            onClick={handleOpenModalAskRelief}
                            startIcon={<IconMessage2 />}
                        >
                            Ask a relief
                        </Button>
                    </Tooltip>
                </Box>
            )}
            {reliefList?.length ? (
                <Box>
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={reliefList}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default ReliefList;
