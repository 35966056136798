/* eslint-disable no-plusplus */
import * as React from 'react';
import './styles.scss';
// material-ui
import {
    Box,
    Button,
    CardContent,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TablePagination,
    Toolbar,
    Tooltip
} from '@mui/material';

// project imports
// import MainCard from 'components/cards/MainCard';

// assets
import { IconFileExport } from '@tabler/icons';
import RejectIcon from 'assets/images/icons/page-actions/tabler-icon-ban.svg';
import AcceptIcon from 'assets/images/icons/page-actions/tabler-icon-check.svg';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';

import {
    ALL_CENTER_OPTION,
    AUTH_LOCAL_STORAGE_KEY,
    DEFAULT_REQUEST,
    GET_ALL_REQUEST,
    Order,
    RELIEF_AGENT,
    ReasonForRequestRelief,
    Roles,
    TIMESHEET_STATUS,
    formatDate,
    useStyles
} from 'config';

import EmptyData from 'components/errors/empty-data';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { CentreApi } from 'services/api';
import defaultApi from 'services/api/defaultApi';
import { EnhancedTableToolbarProps } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { TimesheetApprovalListType } from 'types/kecho/timesheet-approval';
import { IFilterRequest, IMetaData } from 'types/query/filter';
// import RejectTimesheetModal from './RejectTimesheetModal';
import CustomSelectCenter from 'components/CustomSelectCenter';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { CSVLink } from 'react-csv';
import { useDebounce } from 'services/hooks/useDebounce';
import ConfirmApproveRejectModal from './ConfirmApproveRejectModal';
import DetailTimesheetModal from './DetailTimesheetModal';
import MoreApproveRejectModal from './MoreApproveRejectModal';

interface MyType {
    handleGetList: () => void; // Bắt buộc
    [key: string]: any; // Các trường khác tùy chọn
}

interface Row extends TimesheetApprovalListType {
    action?: string;
    reliefName?: string;
    email?: string;
}

interface TimesheetListProps extends EnhancedTableToolbarProps {
    selected: string[];
    selectedRow: any[];
    handleGetList: () => void;
    fromDate: string;
    toDate: string;
    defaultDate: string;
}

// ==============================|| HEADERS EXPORT ||============================== //
const headers = [
    { key: 'reliefID', label: 'Relief ID' },
    { key: 'centre', label: 'Center' },
    { key: 'ptCode', label: 'PT Code' },
    { key: 'cluster', label: 'Cluster' },
    { key: 'reliefAgent', label: 'Relief Agent' },
    { key: 'reliefName', label: 'Name of relief' },
    { key: 'reliefType', label: 'Relief Type (RT/RTA)' },
    { key: 'reliefTypeExt', label: 'Relief Type (Int/Ext)' },
    { key: 'hourlyRate', label: 'Hourly Rate' },
    { key: 'noOfHours', label: 'No. of Hours' },
    { key: 'totalCost', label: 'Total Cost' },
    { key: 'centreHRBudget', label: 'Center/HR Budget' },
    { key: 'reason', label: 'Remarks/Reasons' }
];

// ==============================|| TABLE HEADER TOOLBAR ||============================== //
const EnhancedTableToolbar = ({ numSelected, selected, selectedRow, handleGetList, defaultDate, fromDate, toDate }: TimesheetListProps) => {
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [csvData, setCsvData] = React.useState<any[]>([{}]);
    const csvLink = React.useRef<any>(null);

    const [isEnableApprove, setIsEnableApprove] = React.useState<boolean>(false);
    const [isEnableReject, setIsEnableReject] = React.useState<boolean>(false);
    const [titleEnableApprove, setTitleEnableApprove] = React.useState<string>('');
    const [titleEnableReject, setTitleEnableReject] = React.useState<string>('');
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    React.useEffect(() => {
        if (selectedRow.length > 1) {
            const isEnable = selectedRow.some((item) => item?.status === TIMESHEET_STATUS.WAITING_APPROVAL);
            const selectItems = selectedRow.filter((item) => item?.status === TIMESHEET_STATUS.WAITING_APPROVAL);
            setIsEnableApprove(isEnable);
            setIsEnableReject(isEnable);
            setTitleEnableApprove(isEnable ? 'Approve' : 'One or more of the selected timesheet are must be state is waiting for approve');
            setTitleEnableReject(isEnable ? 'Reject' : 'One or more of the selected timesheet are must be state is waiting for approve');
            setSelectedItems(selectItems);
        } else {
            setIsEnableApprove(false);
            setIsEnableReject(false);
            setSelectedItems([]);
        }
    }, [selectedRow]);

    const handleApproveTimeSheet = async (status: TIMESHEET_STATUS) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <MoreApproveRejectModal
                    onClose={onClose}
                    status={status}
                    selected={selectedItems}
                    callback={handleGetList}
                    type="approve"
                />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleRejectTimeSheet = (status: TIMESHEET_STATUS) => {
        UIManager.showModal({
            content: (onClose: any) => (
                <MoreApproveRejectModal onClose={onClose} status={status} selected={selectedItems} callback={handleGetList} type="reject" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const [headerExport, setHeaderExport] = React.useState<any[]>(headers);

    const handleExportTimesheet = () => {
        let dataExport: any = [];
        let headersExport: any = [...headers];
        dataExport = selectedRow?.map((row) => {
            const reliefID = row?.relief?.id;
            const centre = row?.relief?.center?.nameCenter || 'N/A';
            const ptCode = row?.relief?.center?.ECDA_ID || 'N/A';
            const cluster = row?.relief?.clusterManager?.nameStaff || 'N/A';
            const reliefAgent = row?.relief?.reliefStaff?.reliefTicket?.agent || 'N/A';
            const reliefName = row?.relief?.reliefStaff?.reliefTicket?.name || 'N/A';
            const reliefType = row?.relief?.reliefStaff?.reliefTicket?.reliefType || 'N/A';
            const reliefTypeExt = row?.relief?.reliefStaff?.reliefTicket?.agent === RELIEF_AGENT.BABILOU ? 'Ext' : 'Int';
            const hourlyRate = row?.relief?.reliefStaff?.reliefTicket?.salary || 'N/A';
            const noOfHours = row?.totalHour || 'N/A';
            const totalCost = row?.relief?.reliefStaff?.reliefTicket?.salary * row?.totalHour || 'N/A';
            const centreHRBudget =
                ReasonForRequestRelief[2].text === row?.relief?.reason || ReasonForRequestRelief[3].text === row?.relief?.reason
                    ? 'HR'
                    : 'Center';
            const reason = row?.relief?.reason || 'N/A';
            let data: any = {
                reliefID,
                centre,
                ptCode,
                cluster,
                reliefAgent,
                reliefName,
                reliefType,
                reliefTypeExt,
                hourlyRate,
                noOfHours,
                totalCost,
                centreHRBudget,
                reason
            };
            row?.reliefAttendances?.forEach((item: any) => {
                data = { ...data, [moment(item?.date).format('DD/MM/YYYY')]: item?.total || 'N/A' };
            });
            return data;
        });
        const currentDate = fromDate || defaultDate;
        const toDateEx = toDate || defaultDate;
        const dateEx1 = moment(currentDate);
        const dateEx2 = moment(toDateEx);
        for (let i = dateEx1; i.diff(dateEx2, 'days') <= 0; i.add(1, 'days')) {
            headersExport = [...headersExport, { key: i.format('DD/MM/YYYY'), label: i.format('DD/MM/YYYY') }];
        }
        selectedRow?.forEach((item: any, index: number) => {
            const timeItem = item?.reliefAttendances;
            if (currentDate !== toDateEx) {
                const date1 = moment(currentDate);
                const date2 = moment(toDateEx);
                while (date1.diff(date2, 'days') <= 0) {
                    const date = date1.format('DD/MM/YYYY');
                    const itemTime = timeItem.find((it: any) => moment(it?.date).format('DD/MM/YYYY') === date);
                    dataExport[index] = { ...dataExport[index], [date]: itemTime?.total || 'N/A' };
                    date1.add(1, 'days');
                }
            }
        });
        setHeaderExport(headersExport);
        setCsvData(dataExport);
        setTimeout(() => {
            csvLink.current.link.click();
        }, 500);
    };

    return (
        <Toolbar
            sx={{
                ...(numSelected > 0 && {
                    color: (theme) => theme.palette.common.white
                }),
                padding: 0,
                margin: 0,
                alignItems: 'start'
            }}
        >
            <Box sx={{ flexGrow: 1 }} />
            {numSelected > 1 && (
                <>
                    {(userRole === Roles.Admin ||
                        userRole === Roles.HrIc ||
                        userRole === Roles.HrAdmin ||
                        userRole === Roles.Principals) && (
                        <>
                            <Tooltip title={titleEnableApprove} placement="top">
                                <Button
                                    variant="outlined"
                                    style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                    onClick={() => handleApproveTimeSheet(TIMESHEET_STATUS.APPROVED)}
                                    startIcon={
                                        <img src={AcceptIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                                    }
                                    size="medium"
                                    disabled={!isEnableApprove}
                                >
                                    Approve all
                                </Button>
                            </Tooltip>

                            <Tooltip title={titleEnableReject} placement="top" style={{ marginRight: 8 }}>
                                <Button
                                    variant="outlined"
                                    style={{ color: '#f44336', border: '1px solid #f44336', marginRight: 10 }}
                                    onClick={() => handleRejectTimeSheet(TIMESHEET_STATUS.REJECTED)}
                                    startIcon={
                                        <img src={RejectIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                                    }
                                    size="medium"
                                    disabled={!isEnableReject}
                                >
                                    Reject all
                                </Button>
                            </Tooltip>
                            {/* <span>
                                    <Button
                                        variant="contained"
                                        style={{ color: 'white', backgroundColor: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                        onClick={() => handleApproveTimeSheet(TIMESHEET_STATUS.APPROVED)}
                                        disabled={!isEnableApprove}
                                    >
                                        Approve
                                    </Button>
                                </span> */}
                            {/* <span>
                                <Button
                                    variant="contained"
                                    style={{ color: 'white', backgroundColor: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                                    onClick={() => handleRejectTimeSheet(TIMESHEET_STATUS.REJECTED)}
                                    disabled={!isEnableReject}
                                >
                                    Reject
                                </Button>
                            </span> */}
                        </>
                    )}
                    {(userRole === Roles.Admin ||
                        userRole === Roles.HrIc ||
                        userRole === Roles.HrAdmin ||
                        userRole === Roles.Cluster ||
                        userRole === Roles.Principals) && (
                        <>
                            {/* <Tooltip title="Export">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3F51B5', border: '1px solid #3F51B5' }}
                                    onClick={handleExportTimesheet}
                                >
                                    Export
                                </Button>
                            </Tooltip>
                            <CSVLink
                                data={csvData}
                                filename="timesheet_approval.csv"
                                style={{ textDecoration: 'none', color: 'white' }}
                                target="_blank"
                                headers={headerExport}
                                ref={csvLink}
                            /> */}

                            <Button
                                variant="outlined"
                                style={{ border: '1px solid #3F51B5', color: '#3F51B5' }}
                                onClick={handleExportTimesheet}
                                size="medium"
                            >
                                <IconFileExport size={22} style={{ marginRight: 5 }} /> Export all
                            </Button>
                            <CSVLink
                                data={csvData}
                                filename="timesheet_approval.csv"
                                style={{ textDecoration: 'none', color: 'white' }}
                                target="_blank"
                                headers={headerExport}
                                ref={csvLink}
                            />
                        </>
                    )}
                </>
            )}
        </Toolbar>
    );
};

// ==============================|| CUSTOMER LIST ||============================== //
const TimesheetApprovalList = () => {
    const classes = useStyles();
    const [selectedId, setSelectedId] = React.useState<string[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
    const [order, setOrder] = React.useState<'DESC' | 'ASC'>('ASC');
    const [orderBy, setOrderBy] = React.useState<string>('createdAt');
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const defaultDate = moment().format(formatDate.DATE_DEFAULT);
    const [timeSheetList, setTimeSheetList] = React.useState<TimesheetApprovalListType[]>([]);
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({
        ...DEFAULT_REQUEST,
        status: '',
        centreId: '',
        fromDate: defaultDate,
        toDate: moment().add('days', 1).format(formatDate.DATE_DEFAULT)
    });

    // export csv
    const [nameTimeSheet, setNameTimeSheet] = React.useState<string>('');
    const [csvData, setCsvData] = React.useState<any[]>([{}]);
    const csvLink = React.useRef<any>(null);

    const col: IColumnTable<Row>[] = [
        {
            field: 'reliefName',
            isSort: true,
            headerName: 'Name',
            width: '15%',
            render: (row) => <>{row?.relief?.reliefStaff?.nameStaff || 'N/A'}</>
        },
        {
            field: 'email',
            isSort: true,
            headerName: 'Email',
            width: '25%',
            render: (row) => <>{row?.relief?.reliefStaff?.email || 'N/A'}</>
        },
        {
            field: 'status',
            isSort: true,
            headerName: 'Timesheet status',
            width: '10%',
            render: (row, index) => (
                <>
                    <Chip
                        key={index}
                        color={row?.status === TIMESHEET_STATUS.WAITING_APPROVAL ? 'warning' : statusColors[row?.status] || 'default'}
                        style={{ marginRight: 8 }}
                        label={row?.status}
                    />
                </>
            )
        },
        {
            field: 'modifiedBy',
            isSort: true,
            headerName: 'Last modified by',
            width: '10%',
            render: (row) => <>{row?.modifiedBy || 'N/A'}</>
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            render: (row) => <>{renderAction(row)}</>
        }
    ];

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        debounceHandleGetList();
    }, [filter]);

    const handleGetList = async () => {
        if (!filter.fromDate || !filter.toDate) return;

        const requestFilterCenterId = filter.centreId === ALL_CENTER_OPTION.id ? '' : filter.centreId;

        const payload = {
            ...filter,
            page: filter.page,
            limit: filter.limit,
            sortColumn: filter.sortColumn,
            order: filter.order,
            fromDate: filter.fromDate || defaultDate,
            toDate: filter.toDate || defaultDate,
            centreId: requestFilterCenterId || userCenterID || userSelectionCenterID
        };
        const { data } = await defaultApi.getTimeSheetListApproval(payload);
        if (data) {
            setTimeSheetList(data.data);
            setMetaData(data.meta);
            // setSelectedId([]);
            // setSelectedRow([]);
        }
    };

    const debounceHandleGetList = useDebounce(handleGetList, 250);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList?.length > 0) {
            if (dataList?.length > 1) {
                dataList?.unshift(ALL_CENTER_OPTION);
            }
            setCentreData(dataList);
            setFilter({ ...filter, centreId: dataList[0]?.id });
        }
    };

    const handleSelectClick = (newSelectedListId: string[]) => {
        setSelectedId(newSelectedListId);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    // action Approval, Reject timesheet
    const handleRejectTimesheet = (relief: any, status: TIMESHEET_STATUS) => async () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <ConfirmApproveRejectModal onClose={onClose} relief={relief} callback={handleGetList} status={status} type="reject" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleApproveTimesheet = (relief: any, status: TIMESHEET_STATUS) => async () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <ConfirmApproveRejectModal onClose={onClose} relief={relief} callback={handleGetList} status={status} type="approve" />
            ),
            stylesModal: {
                maxWidth: 600
            }
        });
    };

    const handleOpenModalDetail = (relief: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DetailTimesheetModal onClose={onClose} relief={relief} userRole={userRole} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };

    const [headerExport, setHeaderExport] = React.useState<any[]>(headers);
    const handleExportTimesheet = (relief: any) => async () => {
        const reliefID = relief?.relief?.id;
        const center = relief?.relief?.center?.nameCenter || 'N/A';
        const ptCode = relief?.relief?.center?.ECDA_ID || 'N/A';
        const cluster = relief?.relief?.clusterManager?.nameStaff || 'N/A';
        const reliefAgent = relief?.relief?.reliefStaff?.reliefTicket?.agent || 'N/A';
        const reliefName = relief?.relief?.reliefStaff?.reliefTicket?.name || 'N/A';
        const reliefType = relief?.relief?.reliefStaff?.reliefTicket?.reliefType || 'N/A';
        const reliefTypeExt = reliefAgent === RELIEF_AGENT.BABILOU ? 'Ext' : 'Int';
        const hourlyRate = relief?.relief?.reliefStaff?.reliefTicket?.salary || 'N/A';
        const noOfHours = relief?.totalHour || 'N/A';
        const totalCost = hourlyRate * noOfHours || 'N/A';
        const reason = relief?.relief?.reason || 'N/A';
        const centreHRBudget = ReasonForRequestRelief[2].text === reason || ReasonForRequestRelief[3].text === reason ? 'HR' : 'Center';
        setNameTimeSheet(reliefID);

        const payload: any = {
            reliefID,
            centre: center,
            ptCode,
            cluster,
            reliefAgent,
            reliefName,
            reliefType,
            reliefTypeExt,
            hourlyRate,
            noOfHours,
            totalCost,
            centreHRBudget,
            reason
        };

        let data: any = [{ ...payload }];
        let headersExport: any = [...headers];
        const reliefAttendances = relief?.reliefAttendances;
        const currentDate = filter.fromDate || defaultDate;
        const toDate = filter.toDate || defaultDate;
        const date1 = moment(currentDate);
        const date2 = moment(toDate);

        if (currentDate !== toDate) {
            for (let i = date1; i.diff(date2, 'days') <= 0; i.add(1, 'days')) {
                const date = i.format('DD/MM/YYYY');
                headersExport = [...headersExport, { key: date, label: date }];
                const item = reliefAttendances.find((it: any) => moment(it?.date).format('DD/MM/YYYY') === date);
                data = data.map((it: any) => ({ ...it, [date]: item?.total || 'N/A' }));
            }
        }
        setHeaderExport(headersExport);
        setCsvData(data);
        setTimeout(() => {
            csvLink.current.link.click();
        }, 500);
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const isAsc = filter.order === Order.DESC && filter.sortColumn === sortColumn ? Order.ASC : Order.DESC;
        setOrder(isAsc);
        setOrderBy(sortColumn);
        setFilter({
            ...filter,
            order: isAsc,
            sortColumn
        });
    };

    const renderButtonAction = (relief: any) => {
        const checkStatus = () => {
            return (
                relief?.status === TIMESHEET_STATUS.APPROVED ||
                relief?.status === TIMESHEET_STATUS.REJECTED ||
                relief?.status === TIMESHEET_STATUS.OPEN
            );
        };
        return (
            <>
                {isShowApproveRejectButton() && (
                    <>
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                            disabled={checkStatus()}
                            onClick={handleApproveTimesheet(relief, TIMESHEET_STATUS.APPROVED)}
                        >
                            <img src={AcceptIcon} alt="missing" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Approve
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ color: '#f44336', border: '1px solid #f44336', marginRight: 10 }}
                            disabled={checkStatus()}
                            onClick={handleRejectTimesheet(relief, TIMESHEET_STATUS.REJECTED)}
                        >
                            <img src={RejectIcon} alt="late" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> Reject
                        </Button>
                    </>
                )}
                {isShowExportButton() && (
                    <>
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                            onClick={handleExportTimesheet(relief)}
                        >
                            <IconFileExport size={22} style={{ marginRight: 5 }} /> Export
                        </Button>
                        <CSVLink
                            data={csvData}
                            filename={`timesheet_approval_${nameTimeSheet}.csv`}
                            style={{ textDecoration: 'none', color: 'white' }}
                            target="_blank"
                            headers={headerExport}
                            ref={csvLink}
                        />
                    </>
                )}
            </>
        );
    };

    const renderAction = (relief: any) => {
        return (
            <>
                {userRole !== Roles.User && (
                    <Button
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 10 }}
                        onClick={() => handleOpenModalDetail(relief)}
                    >
                        <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                    </Button>
                )}

                {renderButtonAction(relief)}
            </>
        );
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    const isShowApproveRejectButton = () => {
        return userRole === Roles.Admin || userRole === Roles.HrIc || userRole === Roles.HrAdmin || userRole === Roles.Principals;
    };

    const isShowExportButton = () => {
        return (
            userRole === Roles.Admin ||
            userRole === Roles.HrIc ||
            userRole === Roles.HrAdmin ||
            userRole === Roles.Cluster ||
            userRole === Roles.Principals
        );
    };

    const statusColors: { [key: string]: 'default' | 'primary' | 'error' | 'success' | 'info' | 'warning' | 'secondary' } = {
        Open: 'default',
        Waiting: 'warning',
        Rejected: 'error',
        Approved: 'success'
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Timesheet Approval" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid container justifyContent="space-between" spacing={2} style={{ flexWrap: 'nowrap' }}>
                    <Grid item xs={12} sm={10}>
                        <div className="group-filter">
                            {isShowCenterFilter() ? (
                                <CustomSelectCenter
                                    componentSx={{ marginRight: 1 }}
                                    data={centreData}
                                    value={filter.centreId}
                                    name="centerId"
                                    label="Center"
                                    onChange={(e: any) => {
                                        setFilter({
                                            ...filter,
                                            centreId: e.target.value,
                                            page: 1
                                        });
                                    }}
                                    selectProps={{
                                        MenuProps: {
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            <FormControl style={{ height: 40, marginRight: 8 }}>
                                <DateRangeLocalDesktopDatePicker
                                    disabled={!centreData.length && !userCenterID}
                                    sx={{ '.date-range-picker': { gap: 1 } }}
                                    views={['year', 'month', 'day']}
                                    isRequired
                                    fromDateValue={filter.fromDate}
                                    toDateValue={filter.toDate}
                                    onChange={(value) => {
                                        setFilter((prev) => {
                                            return { ...prev, fromDate: value.fromDate, toDate: value.toDate };
                                        });
                                    }}
                                    onError={() => {
                                        setTimeSheetList([]);
                                        setMetaData({});
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ width: 200 }}>
                                <InputLabel size="small" id="demo-simple-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    disabled={!centreData.length && !userCenterID}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="status"
                                    size="small"
                                    value={String(filter.status) || 'all'}
                                    label="Status"
                                    onChange={(e) => {
                                        setFilter({ ...filter, status: e.target.value === 'all' ? '' : e.target.value });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.OPEN}>{TIMESHEET_STATUS.OPEN}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.WAITING_APPROVAL}>{TIMESHEET_STATUS.WAITING_APPROVAL}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.APPROVED}>{TIMESHEET_STATUS.APPROVED}</MenuItem>
                                    <MenuItem value={TIMESHEET_STATUS.REJECTED}>{TIMESHEET_STATUS.REJECTED}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <EnhancedTableToolbar
                            numSelected={selectedId.length}
                            selected={selectedId}
                            selectedRow={selectedRow}
                            handleGetList={handleGetList}
                            defaultDate={defaultDate}
                            fromDate={filter.fromDate || defaultDate}
                            toDate={filter.toDate || defaultDate}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {timeSheetList.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            hasSelect
                            rows={timeSheetList}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                            selectedListId={selectedId}
                            onRowSelect={handleSelectClick}
                            // EnhancedTableToolbar={() => (
                            //     <EnhancedTableToolbar
                            //         numSelected={selectedId.length}
                            //         selected={selectedId}
                            //         selectedRow={selectedRow}
                            //         handleGetList={handleGetList}
                            //         defaultDate={defaultDate}
                            //         fromDate={filter.fromDate || defaultDate}
                            //         toDate={filter.toDate || defaultDate}
                            //     />
                            // )}
                        />
                    </Box>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={metaData?.itemCount || 0}
                        rowsPerPage={filter.take}
                        page={filter.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default TimesheetApprovalList;
