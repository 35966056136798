// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ClassIcon from '@mui/icons-material/Class';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupsIcon from '@mui/icons-material/Groups';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Roles } from 'config';
// import menuTemp from './menuTemp';
// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    BusinessCenterIcon,
    ClassIcon,
    AssignmentIndIcon,
    GroupsIcon,
    CorporateFareIcon
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const menuProd = [
    {
        id: 'settings',
        title: <FormattedMessage id="settings" />,
        type: 'collapse',
        icon: icons.CorporateFareIcon,
        accessRole: [Roles.Admin],
        children: [
            {
                id: 'cluster-list',
                title: <FormattedMessage id="cluster-list" />,
                type: 'item',
                url: '/cluster/cluster-list',
                breadcrumbs: false
            },
            {
                id: 'centre-list',
                title: <FormattedMessage id="centre-list" />,
                type: 'item',
                url: '/centre/centre-list',
                breadcrumbs: false
            },
            {
                id: 'user-list',
                title: <FormattedMessage id="user-list" />,
                type: 'item',
                url: '/user/user-list',
                breadcrumbs: false
            },
            {
                id: 'class-list',
                title: <FormattedMessage id="class-list" />,
                type: 'item',
                url: '/class/class-list',
                breadcrumbs: false
            },
            {
                id: 'hric-management',
                title: <FormattedMessage id="hric-management" />,
                type: 'item',
                url: '/staff/hric-management',
                breadcrumbs: false
            },
            {
                id: 'list-ratio',
                title: <FormattedMessage id="list-ratio" />,
                type: 'item',
                url: '/staff/list-ratio',
                breadcrumbs: false
            },
            {
                id: 'absence-list',
                title: <FormattedMessage id="absence-list" />,
                type: 'item',
                url: '/staff/absence-list',
                breadcrumbs: false
            },
            {
                id: 'qualification-list',
                title: <FormattedMessage id="qualification-list" />,
                type: 'item',
                url: '/staff/qualification-list',
                breadcrumbs: false,
                accessRole: [Roles.Admin, Roles.Cluster, Roles.Principals]
            }
        ]
    },
    // {
    //     id: 'cluster',
    //     title: <FormattedMessage id="cluster" />,
    //     type: 'collapse',
    //     icon: icons.CorporateFareIcon,
    //     accessRole: [Roles.Admin],
    //     children: [
    //         {
    //             id: 'cluster-list',
    //             title: <FormattedMessage id="cluster-list" />,
    //             type: 'item',
    //             url: '/cluster/cluster-list',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'centre',
    //     title: <FormattedMessage id="centre" />,
    //     type: 'collapse',
    //     icon: icons.BusinessCenterIcon,
    //     accessRole: [Roles.Admin, Roles.Cluster, Roles.HrAdmin, Roles.HrIc],
    //     children: [
    //         {
    //             id: 'centre-list',
    //             title: <FormattedMessage id="centre-list" />,
    //             type: 'item',
    //             url: '/centre/centre-list',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'user',
    //     title: <FormattedMessage id="user" />,
    //     type: 'collapse',
    //     icon: icons.AssignmentIndIcon,
    //     accessRole: [Roles.Admin, Roles.HrAdmin],
    //     children: [
    //         {
    //             id: 'user-list',
    //             title: <FormattedMessage id="user-list" />,
    //             type: 'item',
    //             url: '/user/user-list',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'class',
    //     title: <FormattedMessage id="class" />,
    //     type: 'collapse',
    //     icon: icons.ClassIcon,
    //     accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin],
    //     children: [
    //         {
    //             id: 'class-list',
    //             title: <FormattedMessage id="class-list" />,
    //             type: 'item',
    //             url: '/class/class-list',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    {
        id: 'staff-planning',
        title: <FormattedMessage id="staff-planning" />,
        type: 'collapse',
        icon: icons.GroupsIcon,
        accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin],
        children: [
            {
                id: 'staff-list',
                title: <FormattedMessage id="staff-list" />,
                type: 'item',
                url: '/staff/staff-list',
                breadcrumbs: false
            },
            {
                id: 'cal-staff-ratio',
                title: <FormattedMessage id="cal-staff-ratio" />,
                type: 'item',
                url: '/staff/cal-staff-ratio',
                breadcrumbs: false
            }
            // {
            //     id: 'relief-ticket',
            //     title: <FormattedMessage id="relief-ticket" />,
            //     type: 'item',
            //     url: '/staff/relief-list',
            //     breadcrumbs: false
            // }
        ]
    },
    {
        id: 'relief-management',
        title: <FormattedMessage id="relief-management" />,
        type: 'collapse',
        icon: icons.GroupsIcon,
        accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin],
        children: [
            {
                id: 'relief-ticket',
                title: <FormattedMessage id="relief-ticket" />,
                type: 'item',
                url: '/staff/relief-list',
                breadcrumbs: false
            },
            {
                id: 'relief-pool',
                title: <FormattedMessage id="relief-pool" />,
                type: 'item',
                url: '/staff/relief-ticket',
                breadcrumbs: false,
                accessRole: [Roles.HrIc, Roles.HrAdmin, Roles.Cluster, Roles.Principals]
            }
        ]
    }
    // {
    //     id: 'relief-ticket',
    //     title: <FormattedMessage id="relief-ticket" />,
    //     type: 'collapse',
    //     icon: icons.GroupsIcon,
    //     accessRole: [Roles.HrIc, Roles.HrAdmin],
    //     children: [
    //         {
    //             id: 'relief-ticket',
    //             title: <FormattedMessage id="relief-ticket" />,
    //             type: 'item',
    //             url: '/staff/relief-ticket',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'hric-management',
    //     title: <FormattedMessage id="hric-management" />,
    //     type: 'collapse',
    //     icon: icons.GroupsIcon,
    //     accessRole: [Roles.Admin, Roles.HrAdmin],
    //     children: [
    //         {
    //             id: 'hric-management',
    //             title: <FormattedMessage id="hric-management" />,
    //             type: 'item',
    //             url: '/staff/hric-management',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'list-ratio',
    //     title: <FormattedMessage id="list-ratio" />,
    //     type: 'collapse',
    //     icon: icons.BusinessCenterIcon,
    //     accessRole: [Roles.Admin, Roles.Cluster, Roles.Principals, Roles.HrAdmin, Roles.HrIc, Roles.User],
    //     children: [
    //         {
    //             id: 'list-ratio',
    //             title: <FormattedMessage id="list-ratio" />,
    //             type: 'item',
    //             url: '/staff/list-ratio',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'absence-qualification',
    //     title: <FormattedMessage id="absence-qualification" />,
    //     type: 'collapse',
    //     icon: icons.GroupsIcon,
    //     accessRole: [Roles.Admin, Roles.Cluster, Roles.Principals],
    //     children: [
    //         {
    //             id: 'absence-list',
    //             title: <FormattedMessage id="absence-list" />,
    //             type: 'item',
    //             url: '/staff/absence-list',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'qualification-list',
    //             title: <FormattedMessage id="qualification-list" />,
    //             type: 'item',
    //             url: '/staff/qualification-list',
    //             breadcrumbs: false,
    //             accessRole: [Roles.Admin, Roles.Cluster, Roles.Principals]
    //         }
    //     ]
    // }
];

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    type: 'group',
    role: 'hd_admin',
    children: menuProd,
    accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin, Roles.User]
};

export const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    role: 'hd_admin',
    accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin],
    children: [
        {
            id: 'principal',
            title: <FormattedMessage id="principal" />,
            type: 'item',
            url: '/dashboard/principal',
            icon: DashboardIcon,
            accessRole: [Roles.Admin, Roles.Cluster, Roles.HrIc, Roles.Principals, Roles.HrAdmin],
            breadcrumbs: false
        },
        {
            id: 'hqadmin',
            title: <FormattedMessage id="hqadmin" />,
            type: 'item',
            url: '/dashboard/hqadmin',
            icon: AnalyticsIcon,
            accessRole: [Roles.Admin, Roles.HrAdmin, Roles.Cluster, Roles.HrIc],
            breadcrumbs: false
        }
    ]
};

export default application;
