import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_LOCAL_STORAGE_KEY, MethodShowAlert, REACT_APP_API_URL } from 'config';
import UIManager from 'core/UIManager';
import { IErrorResponse } from 'types/kecho/api-promise';

const axiosClient = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*/*',
        accept: '*/*'
    }
});

// Add a request interceptor
axiosClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.ACCESS_TOKEN);
        config.headers!.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => ({
        error
    })
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    (response: AxiosResponse) => {
        if (MethodShowAlert.indexOf(response.config.method || '') >= 0)
            UIManager.showAlert({
                message: 'Success',
                alert: {
                    color: 'success'
                }
            });
        return response;
    },
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    (error) => {
        const dataError = error.response?.data as IErrorResponse;
        // if (MethodShowAlert.indexOf(error.config.method || '') >= 0)
        //     UIManager.showAlert({
        //         message: dataError?.message || 'Error',
        //         alert: {
        //             color: 'error'
        //         }
        //     });
        return dataError;
    }
);

export default axiosClient;
