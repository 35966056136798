import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    Box,
    Checkbox,
    Grid,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import EmptyData from 'components/errors/empty-data';
import React from 'react';

interface HasId {
    id?: string | number;
}

export interface IColumnTable<T extends HasId> {
    field: keyof T | '';
    headerName: string;
    width?: number | string;
    isCheckBox?: boolean;
    isSort?: boolean;
    render?: (row: T, index: number) => React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: T) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, row: T) => void;
}

interface ITableProps<T extends HasId> {
    rows: T[] | undefined;
    columns: IColumnTable<T>[];
    hasSort?: boolean;
    hideSortIcon?: boolean;
    isInterleavedStyle?: boolean;
    isDefaultHeaderColor?: boolean;
    orderBy?: string;
    order?: 'asc' | 'desc';
    hasSelect?: boolean;
    selectedListId?: string[];
    EnhancedTableToolbar?: () => React.ReactNode;
    onRowClick?: (row: T) => void;
    onRowSelect?: (listSelectedId: string[]) => void;
    onRequestSort?: (event: React.SyntheticEvent<Element, Event>, property: string) => void;
}

// Định nghĩa kiểu của props bao gồm isActive
interface StyledTableCellProps extends TableCellProps {
    isDefault?: boolean;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ theme, isDefault }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#303972',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    '& .MuiTableSortLabel-root:focus': {
        color: theme.palette.common.white
    },
    '& .MuiTableSortLabel-root:hover': {
        color: theme.palette.common.white
    },
    ' .MuiTableSortLabel-icon': {
        color: theme.palette.common.white
    },
    '.MuiTableSortLabel-icon.Mui-active': {
        color: theme.palette.common.white
    },
    '.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon': {
        color: theme.palette.common.white
    },
    '.MuiTableSortLabel-root.Mui-active': {
        color: theme.palette.common.white
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '&:hover': {
        backgroundColor: '#F0F2FF !important'
    }
}));

const TableCustom = <T extends HasId>({
    rows,
    columns,
    hasSort,
    hideSortIcon = false,
    order = 'asc',
    orderBy,
    isDefaultHeaderColor = false,
    isInterleavedStyle = false,
    hasSelect,
    selectedListId = [],
    EnhancedTableToolbar,
    onRowClick,
    onRequestSort,
    onRowSelect
}: ITableProps<T>) => {
    const RowStyle = isInterleavedStyle ? StyledTableRow : TableRow;
    const HeaderCell = isDefaultHeaderColor ? TableCell : StyledTableCell;
    const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        }
    };

    const isSelected = (id: string | number | undefined) => {
        if (id && selectedListId) {
            return selectedListId.indexOf(id.toString()) !== -1;
        }

        return false;
    };

    const handleSelectRow = (event: React.ChangeEvent<HTMLInputElement>, id: string | number | undefined, isSelectAll: boolean) => {
        let newSelected: string[] = [];
        if (id && !isSelectAll && selectedListId) {
            const selectedIndex = selectedListId.indexOf(id.toString());
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedListId, id.toString());
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedListId.slice(1));
            } else if (selectedIndex === selectedListId.length - 1) {
                newSelected = newSelected.concat(selectedListId.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selectedListId.slice(0, selectedIndex), selectedListId.slice(selectedIndex + 1));
            }
        }

        if (isSelectAll && selectedListId && rows) {
            if (event.target.checked) {
                newSelected = rows.map((item) => item.id?.toString() || '');
            } else {
                newSelected = [];
            }
        }

        if (onRowSelect) {
            onRowSelect(newSelected);
        }
    };

    return (
        <>
            {hasSelect && selectedListId && selectedListId.length > 0 && (
                <Typography color="#4D607A" variant="h4" paddingBottom="10px">
                    {selectedListId.length} records selected
                </Typography>
            )}
            {rows && rows?.length > 0 ? (
                <TableContainer sx={{ borderRadius: '6px' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow sx={{ width: '100%' }}>
                                {hasSort ? (
                                    <>
                                        {hasSelect && selectedListId && (
                                            <HeaderCell
                                                isDefault={isDefaultHeaderColor}
                                                sx={{
                                                    '.MuiTableSortLabel-root': {
                                                        width: '100%',
                                                        justifyContent: 'space-between'
                                                    }
                                                }}
                                                width={50}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={selectedListId.length > 0 && selectedListId.length < rows.length}
                                                    checked={rows.length > 0 && selectedListId?.length === rows.length}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        handleSelectRow(event, '', true);
                                                    }}
                                                    inputProps={{
                                                        'aria-label': 'select all desserts'
                                                    }}
                                                />
                                            </HeaderCell>
                                        )}
                                        {!EnhancedTableToolbar ? (
                                            columns.map((headCell) => (
                                                <HeaderCell
                                                    isDefault={isDefaultHeaderColor}
                                                    key={headCell.field.toString()}
                                                    width={headCell.width}
                                                    sx={{
                                                        '.MuiTableSortLabel-root': {
                                                            width: '100%',
                                                            justifyContent: 'space-between'
                                                        }
                                                    }}
                                                >
                                                    {headCell.isSort ? (
                                                        <div style={{ display: 'flex' }}>
                                                            <TableSortLabel
                                                                // active={orderBy === headCell.id}
                                                                active={orderBy === headCell.field}
                                                                direction={orderBy === headCell.field.toString() ? order : 'asc'}
                                                                onClick={createSortHandler(headCell.field.toString())}
                                                                IconComponent={ArrowDownwardIcon}
                                                                hideSortIcon={hideSortIcon}
                                                            >
                                                                <>{headCell.headerName}</>
                                                            </TableSortLabel>
                                                        </div>
                                                    ) : (
                                                        <Box>{headCell.headerName}</Box>
                                                    )}
                                                </HeaderCell>
                                            ))
                                        ) : (
                                            <HeaderCell colSpan={columns.length}>{EnhancedTableToolbar()}</HeaderCell>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {columns.map((col) => (
                                            <StyledTableCell key={String(col.field)} style={{ minWidth: col.width || 70 }}>
                                                {col.headerName}
                                            </StyledTableCell>
                                        ))}
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, rowIndex) => (
                                <RowStyle
                                    hover
                                    key={rowIndex}
                                    style={{ cursor: onclick ? 'pointer' : 'context-menu' }}
                                    onClick={() => {
                                        if (onRowClick) {
                                            onRowClick(row);
                                        }
                                    }}
                                >
                                    {hasSelect && (
                                        <StyledTableCell align="left">
                                            <Checkbox
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleSelectRow(event, row.id, false);
                                                }}
                                                color="primary"
                                                checked={isSelected(row.id) || false}
                                                inputProps={{
                                                    'aria-labelledby': `enhanced-table-checkbox-${rowIndex}`
                                                }}
                                            />
                                        </StyledTableCell>
                                    )}
                                    {columns.map((col, colIndex) => (
                                        <StyledTableCell align="left" key={colIndex}>
                                            {col.render ? (
                                                col.render(row, colIndex)
                                            ) : (
                                                <Typography
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (col.onChange) {
                                                            col.onChange(event, row);
                                                        }
                                                    }}
                                                    onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
                                                        if (col.onClick) {
                                                            col.onClick(event, row);
                                                        }
                                                    }}
                                                >
                                                    {col.field && row[col.field]}
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    ))}
                                </RowStyle>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default TableCustom;
