import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import Checkbox from '@mui/material/Checkbox';
import { convertTime, ReliefStatus, StatusLeave, timeOfDay } from 'config';
import { v4 as uuidv4 } from 'uuid';
import { Chip, Tooltip, Typography } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import TeacherIcon from 'assets/images/icons/teacher.svg';
import AssistantIcon from 'assets/images/icons/assistant.svg';

import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CancelIcon from '@mui/icons-material/Cancel';
import { StaffKecho } from 'types/kecho/staff-kecho';

interface IProps {
    rows: any;
    page: number;
    take: number;
    limitData: number;
    currentDate?: string;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function StaffTable({ rows, page, take, limitData, currentDate, handleChangePage, handleChangeRowsPerPage }: IProps) {
    const checkHour = (value: number, staffRatio: any, leave: any, date: any) => {
        if (!staffRatio) return true;
        return staffRatio?.some((item: any) => {
            const compareDate = new Date(item?.ratio?.date?.date).getTime() >= new Date(date).getTime();
            const isLeave = leave?.some((leaveItem: any) => {
                return leaveItem?.fromHour <= value && leaveItem?.toHour >= value;
            });
            return value >= item?.fromHour && value <= item?.toHour && compareDate && !isLeave;
        });
    };

    const checkHourAbsentApproved = (value: number, leave: any, date: any) => {
        if (!leave) return true;
        const leaveApproved = leave?.filter((item: any) => item?.status === StatusLeave.APPROVED);
        return leaveApproved?.some((item: any) => {
            const compareDate = new Date(item?.dateLeave).getTime() >= new Date(date).getTime();
            return value >= item?.fromHour && value <= item?.toHour && compareDate;
        });
    };
    const checkHourAbsentPending = (value: number, leave: any, date: any) => {
        if (!leave) return true;
        const leavePending = leave?.filter((item: any) => item?.status === StatusLeave.WAITING_FOR_APPROVAL);
        return leavePending?.some((item: any) => {
            const compareDate = new Date(item?.dateLeave).getTime() >= new Date(date).getTime();
            return value >= item?.fromHour && value <= item?.toHour && compareDate;
        });
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden', border: '1px solid #E3E6EB' }}>
                <TableContainer sx={{ maxHeight: 'max-content', overflowY: 'hidden' }} className="table-container">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ minWidth: 100 }}>Staff</TableCell>
                                <TableCell style={{ minWidth: 70 }}>Diplomas</TableCell>
                                <TableCell style={{ minWidth: 70 }}>Title</TableCell>
                                {timeOfDay.map((column: any) => (
                                    <TableCell key={uuidv4()} align="center" style={{ minWidth: 50 }}>
                                        {convertTime(column)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row: StaffKecho) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                                        <TableCell align="left">
                                            <div className="d-flex" style={{ display: 'flex', alignItems: 'center' }}>
                                                {row?.type === 'teacher' && <img src={TeacherIcon} alt="teacher" />}
                                                {row?.type === 'assistant' && <img src={AssistantIcon} alt="assistant" />}
                                                <Typography sx={{ ml: 1 }} style={{ fontWeight: 600 }}>
                                                    {row.nameStaff}
                                                </Typography>
                                                {row?.reliefStaffs?.length > 0 &&
                                                    row?.reliefStaffs[0].status === ReliefStatus.RELIEF_ASSIGNED && (
                                                        <Chip className="chip-relief" label="Relief" />
                                                    )}
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography>
                                                {row?.staffCert?.map((item: any) => item?.cert?.diploma).join(', ') || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography>
                                                {row?.staffCert?.map((item: any) => item?.cert?.title).join(', ') || '-'}
                                            </Typography>
                                        </TableCell>
                                        {timeOfDay.map((column: any) => {
                                            const checkStateHour = !checkHour(column, row.staffRatio, row.leave, currentDate);
                                            const checkStateAbsentApproved = checkHourAbsentApproved(column, row.leave, currentDate);
                                            const checkStateAbsentPending = checkHourAbsentPending(column, row.leave, currentDate);
                                            return (
                                                <TableCell key={uuidv4()} align="center">
                                                    {checkStateHour ? (
                                                        <>
                                                            {checkStateAbsentApproved && (
                                                                <Tooltip title="On leave">
                                                                    <CancelIcon style={{ color: '#A7A7A7' }} />
                                                                </Tooltip>
                                                            )}
                                                            {checkStateAbsentPending && (
                                                                <Tooltip title="Pending leave">
                                                                    <MoreHorizIcon
                                                                        style={{
                                                                            background: '#FCC43E',
                                                                            color: '#FFFFFF',
                                                                            borderRadius: '50%',
                                                                            border: '1px solid #FCC43E',
                                                                            width: '0.85em',
                                                                            height: '0.85em'
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                            {!checkStateAbsentApproved && !checkStateAbsentPending && (
                                                                <Tooltip title="Available">
                                                                    <CheckCircleIcon style={{ color: '#3f51b5' }} />
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Tooltip title="Assigned">
                                                            <DoDisturbOnIcon color="error" />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={limitData}
                    rowsPerPage={take}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}
