import * as React from 'react';

import './styles.scss';
// material-ui
import { Box, Button, CardContent, Chip, Grid, InputAdornment, TablePagination, TextField, Tooltip } from '@mui/material';

// project imports
// import { Customer } from 'types/customer';
// import MainCard from 'components/cards/MainCard';

// assets
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { CentreApi, StaffApi } from 'services/api';
import { useDispatch, useSelector } from 'store';
import { setStaffListByCenter } from 'store/slices/template/staffList';
import { DefaultRootStateProps } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import { DeleteStaffModal, StaffModal } from './components';
import VisibilityModal from './components/VisibilityModal';
import CustomSelectCenter from 'components/CustomSelectCenter';
// import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow.svg';

interface Row extends StaffKecho {
    nameStaff?: string;
    email?: string;
    phoneNumber?: string;
    diploma?: string;
    status?: string;
    action?: string;
}

// ==============================|| CUSTOMER LIST ||============================== //
const roles = [Roles.HrIc, Roles.HrAdmin];
const StaffList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const centerID = useSelector((state: DefaultRootStateProps) => state.staffList.filter.centerId);
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;
    const [staffList, setStaffList] = React.useState<StaffKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, isTemporary: 'false' });
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);

    React.useEffect(() => {
        if (isShowCenterFilter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            if (filter?.centerId || centerID || userCenterID) {
                handleGetStaffList();
            }
        }, 300);
        return () => clearTimeout(searchInputTimer);
    }, [filter, centerID, userCenterID]);

    const col: IColumnTable<Row>[] = [
        {
            field: 'nameStaff',
            headerName: 'Name',
            isSort: true,
            width: '20%'
        },
        {
            field: 'email',
            headerName: 'Email',
            isSort: true,
            width: '30%'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            isSort: true,
            width: '10%'
        },
        {
            field: 'diploma',
            headerName: 'Diplomas',
            isSort: true,
            width: '10%',
            render: (row) => {
                return row?.staffCert?.map((item: any) => {
                    return <Chip key={item?.id} label={item?.cert?.diploma} />;
                });
            }
        },
        {
            field: 'isHidden',
            headerName: 'Status',
            isSort: true,
            width: '10%',
            render: (row) => {
                return (
                    <Chip
                        style={{
                            backgroundColor: row?.isHidden ? '#FCE3E1' : '#D4FADE',
                            color: row?.isHidden ? '#F44336' : '#09B54E'
                        }}
                        label={row?.isHidden ? 'Hidden' : 'Visible'}
                    />
                );
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            render: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Edit detail"
                            placement="top"
                            style={{
                                display: roles.includes(userRole) || !row?.isTemporary ? 'none' : 'inline-flex'
                            }}
                        >
                            <Button
                                variant="outlined"
                                style={{
                                    color: '#3F51B5',
                                    border: '1px solid #3F51B5',
                                    marginRight: 10,
                                    display: roles.includes(userRole) || !row?.isTemporary ? 'none' : 'inline-flex'
                                }}
                                onClick={() => handleOpenModalEditStaff(row)}
                            >
                                <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Edit
                            </Button>
                        </Tooltip>
                        <Tooltip title="View detail" placement="top">
                            <Button
                                variant="outlined"
                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                onClick={() => {
                                    window.location.href = `/staff/staff-details/${row?.id}`;
                                }}
                            >
                                <VisibilityTwoToneIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> View
                            </Button>
                        </Tooltip>
                        <Tooltip title="Plan Of Staff" placement="top">
                            <Button
                                variant="outlined"
                                style={{ color: '#3F51B5', border: '1px solid #3F51B5', marginRight: 5 }}
                                onClick={() => {
                                    window.location.href = `/staff/plan-staff/${row?.id}/${centerID || userCenterID}`;
                                }}
                            >
                                <PreviewIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Plan
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Remove" style={{ display: roles.includes(userRole) ? 'none' : 'inline-flex' }}>
                            <Button
                                variant="outlined"
                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                onClick={() => handleOpenDeleteModalStaff(row)}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                            </Button>
                        </Tooltip>
                        {/* <DeleteStaffKechoModal pagination={pagination} name={row?.nameStaff} staffId={row?.id} /> */}
                    </>
                );
            }
        }
    ];

    const handleGetStaffList = async () => {
        const { dataList, meta } = await StaffApi.list({ ...filter, centreId: centerID || userCenterID || userSelectionCenterID });
        if (dataList) setStaffList(dataList);
        if (meta) setMetaData(meta);
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList) {
            setCentreData(dataList);
            dispatch(setStaffListByCenter({ centerId: centerID || userCenterID || dataList[0]?.id }));
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, sortColumn: string) => {
        const order = filter.order === Order.DESC && sortColumn === filter.sortColumn ? Order.ASC : Order.DESC;
        setFilter({ ...filter, sortColumn, order });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleOpenModalEditStaff = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <StaffModal onClose={onClose} callback={handleGetStaffList} staffEdit={staff} />
        });
    };

    // const handleOpenCreateModalStaff = () => {
    //     UIManager.showModal({
    //         content: (onClose: any) => <StaffModal onClose={onClose} callback={handleGetStaffList} />
    //     });
    // };

    const handleOpenModalVisibility = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <VisibilityModal
                    onClose={onClose}
                    callback={handleGetStaffList}
                    centerId={centerID || userCenterID || userSelectionCenterID}
                    isTemporary={filter.isTemporary}
                />
            ),
            stylesModal: {
                minWidth: '900px'
            }
        });
    };

    const handleOpenDeleteModalStaff = (staff: StaffKecho) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteStaffModal onClose={onClose} callback={handleGetStaffList} staffEdit={staff} />
        });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const isShowCenterFilter = () => {
        return userRole === Roles.Cluster || userRole === Roles.HrIc || userRole === Roles.Admin || userRole === Roles.HrAdmin;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Staff List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0, paddingBottom: '15px' }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <div className="group-filter" style={{ marginBottom: 0 }}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder="Search by name"
                                value={filter.query}
                                size="small"
                                style={{ marginRight: '8px' }}
                            />
                            {isShowCenterFilter() ? (
                                <CustomSelectCenter
                                    componentSx={{ marginRight: 1 }}
                                    data={centreData}
                                    value={String(centerID)}
                                    name="centerId"
                                    label="Center"
                                    onChange={(e) => {
                                        dispatch(setStaffListByCenter({ centerId: e.target.value }));
                                        setFilter({ ...filter, page: 1 });
                                    }}
                                    selectProps={{
                                        MenuProps: {
                                            classes: {
                                                paper: classes.selectMenu
                                            }
                                        }
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {/* <FormControl style={{ width: 200 }}>
                                <InputLabel size="small" id="demo-simple-select-label">
                                    Filter
                                </InputLabel>
                                <Select
                                    disabled={!centreData.length && !userCenterID}
                                    size="small"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="isTemporary"
                                    value={filter.isTemporary || 'all'}
                                    label="Filter"
                                    onChange={(e) => {
                                        setFilter({ ...filter, isTemporary: e.target.value, page: 1 });
                                    }}
                                >
                                    <MenuItem value="all">All</MenuItem>
                                    <MenuItem value="true">Temporary staff</MenuItem>
                                    <MenuItem value="false">Official staff</MenuItem>
                                </Select>
                            </FormControl> */}
                        </div>
                    </Grid>
                    {(centreData.length > 0 || userCenterID) && (
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            sx={{ textAlign: 'right' }}
                            style={{ display: roles.includes(userRole) ? 'none' : 'flex' }}
                        >
                            <Grid container justifyContent="flex-end">
                                <Button
                                    size="medium"
                                    variant="outlined"
                                    style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                                    onClick={handleOpenModalVisibility}
                                >
                                    <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} />{' '}
                                    Visibility
                                </Button>
                                {/* <Tooltip title="Create a temporary staff" placement="top">
                                    <Button
                                        size="medium"
                                        variant="contained"
                                        style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                        onClick={handleOpenCreateModalStaff}
                                    >
                                        <AddIcon sx={{ fontSize: '1.3rem' }} /> Add
                                    </Button>
                                </Tooltip> */}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
            {/* table */}
            {staffList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={staffList}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />
                        {/* table pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default StaffList;
