import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, CardContent, Chip, Grid, InputAdornment, Stack, TablePagination, TextField, Tooltip } from '@mui/material';

// assets
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import EmptyData from 'components/errors/empty-data';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { DEFAULT_REQUEST, Order } from 'config';
import UIManager from 'core/UIManager';
import moment from 'moment';
import { clusterApi } from 'services/api';
import { ClusterKecho } from 'types/kecho/centre-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import ClusterModal from '../AddClusterModal';
import DeleteClusterModal from '../DeleteClusterModal';

interface Row extends ClusterKecho {
    nameStaff?: string;
    action?: string;
    createdDate?: string;
    clusterManager?: string;
    centreKecho?: string;
}

// ==============================|| CUSTOMER LIST ||============================== //

const ClusterList = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = React.useState<IFilterRequest>(DEFAULT_REQUEST);
    const [clusterList, setClusterList] = React.useState<ClusterKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});

    const cols: IColumnTable<Row>[] = [
        {
            field: 'nameCluster',
            isSort: true,
            headerName: 'Name',
            width: '15%'
        },
        {
            field: 'centreKecho',
            isSort: false,
            headerName: 'Centers',
            width: '35%',
            render: (row) => (
                <>
                    {row?.center?.map((item: any) => (
                        <Chip label={item?.nameCenter} onClick={() => goToDetailCenter(item?.id)} sx={{ mb: 1, mr: 0.5 }} />
                    ))}
                </>
            )
        },
        {
            field: 'clusterManager',
            isSort: true,
            headerName: 'Cluster Manager',
            width: '10%',
            render: (row) => (
                <>
                    {row?.staffs?.map((item: any) => (
                        <Chip label={item?.nameStaff} color="primary" sx={{ mb: 1, mr: 0.5 }} />
                    ))}
                </>
            )
        },
        {
            field: 'staffs',
            isSort: true,
            headerName: 'Email',
            width: '15%',
            render: (row) => <>{row?.staffs?.map((item: any) => item?.email)}</>
        },
        {
            field: 'createdDate',
            isSort: true,
            headerName: 'Created Date',
            width: '10%',
            render: (row) => <>{moment(row?.createdAt).format('DD/MM/YYYY')}</>
        },
        {
            field: 'action',
            isSort: false,
            headerName: 'Action',
            render: (row) => (
                <>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Tooltip title="Edit detail" placement="top">
                            <Button
                                variant="outlined"
                                style={{
                                    color: '#3F51B5',
                                    border: '1px solid #3F51B5',
                                    marginRight: 10
                                }}
                                onClick={() => handleOpenModalEditCluster(row)}
                            >
                                <EditIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#3F51B5' }} /> Edit
                            </Button>
                        </Tooltip>
                        <Tooltip placement="top" title="Remove">
                            <Button
                                variant="outlined"
                                style={{ color: '#ff0000', border: '1px solid #ff0000' }}
                                onClick={() => handleOpenDeleteModalCluster(row)}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: '1.3rem', marginRight: '5px', color: '#ff0000' }} /> Remove
                            </Button>
                        </Tooltip>
                    </Stack>
                </>
            )
        }
    ];

    React.useEffect(() => {
        const searchInputTimer = setTimeout(() => {
            handleGetClusterList();
        }, 500);
        return () => clearTimeout(searchInputTimer);
    }, [filter]);

    const handleGetClusterList = async () => {
        const { dataList, meta } = await clusterApi.listCluster(filter);
        if (dataList) {
            setClusterList(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const goToDetailCenter = (id: string) => {
        navigate(`/centre/centre-details/${id}`);
    };

    const handleOpenModalAddCluster = () => {
        UIManager.showModal({
            content: (onClose: any) => <ClusterModal onClose={onClose} callback={handleGetClusterList} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };
    const handleOpenModalEditCluster = (cluster: any) => {
        UIManager.showModal({
            content: (onClose: any) => <ClusterModal onClose={onClose} callback={handleGetClusterList} clusterEdit={cluster} />,
            stylesModal: {
                maxWidth: 900
            }
        });
    };
    const handleOpenDeleteModalCluster = (cluster: any) => {
        UIManager.showModal({
            content: (onClose: any) => <DeleteClusterModal onClose={onClose} callback={handleGetClusterList} clusterEdit={cluster} />
        });
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Cluster List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Add new cluster" placement="top">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                                    size="large"
                                    onClick={handleOpenModalAddCluster}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {clusterList.length ? (
                <Box
                    sx={{
                        padding: '0px 24px'
                    }}
                >
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={clusterList}
                            columns={cols}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take || 10}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <EmptyData />
                        <br />
                        {filter.query !== '' && <div>Sorry we couldn't find any matches for {filter.query}</div>}
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ClusterList;
