import { PromiseApi } from 'types/kecho/api-promise';
import axiosAuthClient from 'utils/axios/axiosAuthClient';
import { buildUrl } from '../../utils/buildApi';

const BaseApi = 'admin/dashboard/';
const DownloadReportsApi = 'manpower-report/';

const DashboardApi = {
    respected(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}respected`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    listClassDashBoard1(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}listClassDashBoard1`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    listClassDashBoard2(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}listClassDashBoard2`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    leaveDashBoard(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}leaveDashBoard`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    nextweekrespected(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}nextweekrespected`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    chartDashboard(centreId: string | undefined): Promise<PromiseApi> {
        const base = `${BaseApi}chart-dashboard`;
        const url = buildUrl(base, { centreId });
        return axiosAuthClient.get(url);
    },
    respectedCenter(): Promise<PromiseApi> {
        const base = `${BaseApi}respected-center`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    rankBrandRatio(): Promise<PromiseApi> {
        const base = `${BaseApi}rank-brand-ratio`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    chartCenterOverStaff(): Promise<PromiseApi> {
        const base = `${BaseApi}chart-center-over-staff`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    chartCenterUnderStaff(): Promise<PromiseApi> {
        const base = `${BaseApi}chart-center-under-staff`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    rankBrandCluster(): Promise<PromiseApi> {
        const base = `${BaseApi}rank-brand-ratio-cluster`;
        const url = buildUrl(base);
        return axiosAuthClient.get(url);
    },
    downloadReports(year: number | undefined): Promise<PromiseApi> {
        const base = `${DownloadReportsApi}download`;
        const url = buildUrl(base, { year });
        return axiosAuthClient.get(url, {
            responseType: 'arraybuffer'
        });
    }
};
export default DashboardApi;
