// project imports

// assets
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { reliefApi } from 'services/api';
import { convertTime, ReliefStatus } from 'config';
import UIManager from 'core/UIManager';
import ModalCustom from 'components/Modal/ModalCustom';
import { formatDateOnly } from 'utils/common/formatDate';
import { useEffect, useState } from 'react';

// project imports

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: () => void;
    reliefId: string;
}

const CreateExternalReliefModal = (props: IProps) => {
    const { onClose, callback, reliefId } = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    const [reliefDetail, setReliefDetail] = useState<any>();

    useEffect(() => {
        const getRelief = async () => {
            const res = await reliefApi.getReliefDetail(reliefId);
            if (res) {
                setReliefDetail(res.data);
            }
        };

        getRelief();
    }, [reliefId]);

    const handleConfirmCreateExternal = async () => {
        setIsDisabled(true);
        try {
            const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.PENDING_RELIEF_ASSIGNATION);
            if (!res.error) {
                UIManager.showAlert({
                    message: 'Create external relief ticket successfully',
                    alert: {
                        color: 'success'
                    }
                });
                onClose();
                callback();
                navigate('/staff/relief-list', { replace: true });
                setIsDisabled(false);
            } else {
                UIManager.showAlert({
                    message: res?.error?.response?.data?.message,
                    alert: {
                        color: 'error'
                    }
                });
                onClose();
                setIsDisabled(false);
            }
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
            setIsDisabled(false);
        }
    };

    return (
        <ModalCustom
            onSave={handleConfirmCreateExternal}
            onCancel={onClose}
            onClose={onClose}
            title="Create External Relief Ticket"
            contentSave="Yes"
            contentCancel="No"
            disabled={isDisabled}
            isLoading={isDisabled}
        >
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Typography gutterBottom component="div" style={{ fontSize: '15px' }}>
                    You are about to create an external relief ticket for the teacher{' '}
                    <strong>{reliefDetail?.absentStaff?.nameStaff}</strong> from the center{' '}
                    <strong>{reliefDetail?.center?.nameCenter}</strong> from{' '}
                    <strong>
                        {formatDateOnly(reliefDetail?.startDate)} - {convertTime(reliefDetail?.startHour)}
                    </strong>{' '}
                    to{' '}
                    <strong>
                        {formatDateOnly(reliefDetail?.endDate)} - {convertTime(reliefDetail?.endHour)}
                    </strong>
                </Typography>
                <Typography gutterBottom component="div" style={{ fontSize: '15px', color: '#F44336' }}>
                    Are you sure you want to continue?
                </Typography>
            </Grid>
        </ModalCustom>
    );
};

export default CreateExternalReliefModal;
