import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { InputAdornment, TextField, Grid, TablePagination, Radio, FormControl } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IFilterRequest } from 'types/query/filter';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, ReliefStatus, Roles, useStyles } from 'config';
import EmptyData from 'components/errors/empty-data';
import { ReliefTicketKecho } from 'types/kecho/relief-ticket';
import reliefTicketApi from 'services/api/reliefTicketApi';
import UIManager from 'core/UIManager';
import { CentreApi, reliefApi } from 'services/api';
import { debounce } from 'lodash';
import ModalCustom from 'components/Modal/ModalCustom';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { IconUserCheck } from '@tabler/icons';
import CustomSelectCenter from 'components/CustomSelectCenter';

interface IProps {
    onClose: any;
    callback: any;
    reliefId: string;
}

const convertData = (data: any): any[] => {
    return data.map((item: any) => {
        return {
            ...item,
            name: item.name,
            email: item.email,
            phone: item.phoneNumber,
            diplomas: item?.certification?.diploma,
            type: item?.reliefType
        };
    });
};

export default function AssignRelieftModal(props: IProps) {
    const { reliefId, onClose, callback } = props;
    const classes = useStyles();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectionCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectionCenterID : userCenterFirstID;

    const [selectTicket, setSelectTicket] = useState<ReliefTicketKecho>();
    const [reliefTickets, setReliefTicket] = useState<ReliefTicketKecho[]>([]);
    const [metaData, setMetaData] = useState<any>({});
    const [listCenters, setListCenters] = useState<any[]>([]);
    const [filter, setFilter] = useState<IFilterRequest>(DEFAULT_REQUEST);

    const col: IColumnTable<any>[] = [
        {
            field: 'name',
            headerName: 'Name'
        },
        {
            field: 'email',
            headerName: 'Email'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone'
        },
        {
            field: 'diplomas',
            headerName: 'Diplomas'
        },
        {
            field: 'type',
            headerName: 'Type'
        },
        {
            field: 'select',
            headerName: 'Select',
            render: (row) => {
                return <Radio checked={row?.id === selectTicket?.id} onChange={(event) => setSelectTicket(row)} />;
            }
        }
    ];

    useEffect(() => {
        if (isCheckRole()) {
            handleGetCenterList();
        } else {
            setFilter({ ...filter, centreId: userCenterID });
        }
    }, []);
    useEffect(() => {
        if (filter?.centreId || filter?.centreId === null) {
            handleGetReliefData();
        }
    }, [filter]);

    const handleGetCenterList = async () => {
        const { dataList } = await CentreApi.listCentre({ ...GET_ALL_REQUEST, screen: isRoleHrIc() ? 'relief-pool' : null });
        if (dataList) {
            setFilter({ ...filter, centreId: dataList[0]?.id });
            if (isRoleHrAdminAndHrIc()) {
                setListCenters([{ id: 'null', nameCenter: 'HQ' }, ...dataList]);
            } else {
                setListCenters(dataList);
            }
        }
    };
    const handleGetReliefData = async () => {
        const { dataList, meta } = await reliefTicketApi.getReliefTicketList({ ...filter, centreId: filter?.centreId || userCenterID });
        if (dataList) {
            setReliefTicket(dataList);
        }
        if (meta) setMetaData(meta);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event?.target.value;
        setFilter({ ...filter, query });
    };
    const handleSearchStaff = debounce(onChange, 500);

    const handleAssignRelief = async () => {
        if (!selectTicket) {
            UIManager.showAlert({
                message: 'Please select relief to assign',
                alert: {
                    color: 'error'
                }
            });

            return;
        }
        try {
            const res = await reliefApi.UpdateReliefById(reliefId, ReliefStatus.RELIEF_ASSIGNED, undefined, undefined, selectTicket);
            if (!res.error) {
                UIManager.showAlert({
                    message: `Relief status updated to "Relief Assigned"`,
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            } else {
                UIManager.showAlert({
                    message: res?.error?.response?.data?.message,
                    alert: {
                        color: 'error'
                    }
                });
            }
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const isCheckRole = () => {
        if (userRole !== Roles.Principals) {
            return true;
        }
        return false;
    };
    const isRoleHrIc = () => userRole === Roles.HrIc;
    const isRoleHrAdminAndHrIc = () => userRole === Roles.HrIc || userRole === Roles.HrAdmin;

    const renderTableStaff = () => {
        return (
            <Paper>
                <div style={{ paddingBottom: 20 }}>
                    <Grid container spacing={2}>
                        {isCheckRole() && listCenters?.length > 1 && (
                            <Grid item md={4} xs={4}>
                                <FormControl fullWidth style={{ height: 42 }}>
                                    <CustomSelectCenter
                                        data={listCenters}
                                        name="centerId"
                                        label="Center"
                                        value={String(filter.centreId)}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFilter((prev) => {
                                                return {
                                                    ...prev,
                                                    centreId: value === 'null' ? null : value,
                                                    page: 1
                                                };
                                            });
                                        }}
                                        selectProps={{
                                            MenuProps: {
                                                classes: {
                                                    paper: classes.selectMenu
                                                }
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        <Grid item md={4} xs={4}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearchStaff}
                                placeholder="Search by name"
                                size="small"
                                fullWidth
                                autoComplete="off"
                            />
                        </Grid>
                    </Grid>
                </div>
                {convertData(reliefTickets)?.length ? (
                    <>
                        <TableCustom
                            isInterleavedStyle
                            rows={convertData(reliefTickets)}
                            columns={col}
                            onRowClick={(row) => {
                                setSelectTicket(row);
                            }}
                        />

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50]}
                            component="div"
                            count={metaData?.itemCount || reliefTickets?.length}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <EmptyData />
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    return (
        <ModalCustom
            onSave={handleAssignRelief}
            onClose={onClose}
            onCancel={onClose}
            title="External relief selection"
            contentSave="Assign Relief"
            iconSave={IconUserCheck}
            disabled={reliefTickets?.length === 0}
        >
            {renderTableStaff()}
        </ModalCustom>
    );
}
