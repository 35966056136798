import * as React from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import { Box, Button, CardContent, Grid, InputAdornment, Stack, TablePagination, TextField, Tooltip } from '@mui/material';

// project imports
// import MainCard from 'components/cards/MainCard';

// assets

import SearchIcon from '@mui/icons-material/Search';
import ViewIcon from 'assets/images/icons/page-actions/tabler-icon-eye.svg';
import CustomSelectCenter from 'components/CustomSelectCenter';
import EmptyData from 'components/errors/empty-data';
import { ALL_CENTER_OPTION, AUTH_LOCAL_STORAGE_KEY, DEFAULT_REQUEST, GET_ALL_REQUEST, Order, Roles, useStyles } from 'config';

import { CentreApi } from 'services/api';
import classApi from 'services/api/classApi';
import { useDebounce } from 'services/hooks/useDebounce';
import { useDispatch, useSelector } from 'store';
import { setClassListByCenter } from 'store/slices/template/classList';
import { DefaultRootStateProps } from 'types';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ClassKecho } from 'types/kecho/class-kecho';
import { IFilterRequest, IMetaData } from 'types/query/filter';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';

interface Row extends ClassKecho {
    action?: string;
}
const ClassList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const centerID = useSelector((state: DefaultRootStateProps) => state.classList.filter.centerId);
    const [classList, setClassList] = React.useState<ClassKecho[]>([]);
    const [metaData, setMetaData] = React.useState<IMetaData>({});
    const [centreData, setCentreData] = React.useState<CentreKecho[]>([]);
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const [filter, setFilter] = React.useState<IFilterRequest>({ ...DEFAULT_REQUEST, centreId: centerID || '' });
    const userCenters = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center;
    const userCenterFirstID = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.staff?.center[0]?.id;
    const userSelectCenterID = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.SELECT_CENTER_ID) ?? null;
    const userCenterID = userCenters?.length > 1 ? userSelectCenterID : userCenterFirstID;

    const col: IColumnTable<Row>[] = [
        {
            field: 'nameClass',
            isSort: true,
            headerName: 'Name',
            width: '20%',
            render: (row) => <>{row?.nameClass}</>
        },
        {
            field: 'classYear',
            isSort: true,
            headerName: 'Year',
            width: '10%'
        },
        {
            field: 'description',
            isSort: true,
            headerName: 'Description',
            width: '30%',
            render: (row) => <>{row?.description}</>
        },
        {
            field: 'level',
            isSort: true,
            headerName: 'Level',
            width: '10%',
            render: (row) => <>{row?.level?.nameLevel}</>
        },
        {
            field: 'session',
            isSort: true,
            headerName: 'Session',
            width: '10%',
            render: (row) => <>{row?.session}</>
        },
        {
            field: 'type',
            isSort: true,
            headerName: 'Type',
            width: '10%',
            render: (row) => <>{row?.type}</>
        },
        {
            field: 'action',
            headerName: 'Action',
            isSort: false,
            render: (row) => (
                <Stack direction="row">
                    <Tooltip placement="top" title="View detail">
                        <Button
                            variant="outlined"
                            style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                            onClick={() => {
                                navigate(`/class/class-details/${row?.id}`);
                            }}
                            // href={`/centre/centre-details/${row?.id}`}
                        >
                            <img src={ViewIcon} alt="view" style={{ width: '22px', height: '22px', marginRight: '5px' }} /> View
                        </Button>
                    </Tooltip>
                </Stack>
            )
        }
    ];

    React.useEffect(() => {
        if (isCheckFilterCenter()) handleGetCentreList();
    }, []);

    React.useEffect(() => {
        debounceHandleGetClassList();
    }, [filter]);

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);

        if (dataList?.length > 0) {
            if (dataList?.length > 1) {
                dataList?.unshift(ALL_CENTER_OPTION);
            }
            dispatch(setClassListByCenter({ centerId: centerID || '' }));
            setFilter({ ...DEFAULT_REQUEST, centreId: dataList[0]?.id || '' });
            setCentreData(dataList);
        }
    };

    const handleGetClassList = async () => {
        if (!centerID && !filter.centreId && !userCenterID) return;

        let requestCenterId = centerID || filter.centreId || userCenterID;

        if (requestCenterId === ALL_CENTER_OPTION.id) requestCenterId = '';

        const { dataList, meta } = await classApi.list({ ...filter, centreId: requestCenterId });
        if (dataList) setClassList(dataList);
        if (meta) setMetaData(meta);
    };

    const debounceHandleGetClassList = useDebounce(handleGetClassList, 300);

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const searchString = event?.target.value;
        setFilter((prev) => ({ ...prev, page: 1, query: searchString || '' }));
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const newOrder = filter.order === Order.DESC && filter.sortColumn === property ? Order.ASC : Order.DESC;
        setFilter((prev) => ({ ...prev, sortColumn: property, order: newOrder }));
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter((prev) => ({ ...prev, page: newPage + 1 }));
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setFilter((prev) => ({ ...prev, page: 1, take: parseInt(event?.target.value, 10) }));
    };

    const isCheckFilterCenter = () => {
        return userRole !== Roles.Principals;
    };

    return (
        <div style={{ minHeight: '80vh', height: 'auto', backgroundColor: 'white' }}>
            {/* <MainCard title="Class List" content={false} style={{ minHeight: '80vh', height: 'auto' }}> */}
            <CardContent style={{ paddingTop: 0, paddingBottom: '15px' }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search by name"
                            value={filter.query}
                            size="small"
                            style={{ marginRight: '8px' }}
                        />
                        {isCheckFilterCenter() && (
                            <CustomSelectCenter
                                componentSx={{ marginRight: 8 }}
                                data={centreData}
                                value={centreData.length > 0 ? centerID || filter.centreId : ''}
                                name="centerId"
                                label="Center"
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        centreId: e.target.value,
                                        page: 1
                                    });
                                    dispatch(setClassListByCenter({ centerId: e.target.value }));
                                }}
                                selectProps={{
                                    MenuProps: {
                                        classes: {
                                            paper: classes.selectMenu
                                        }
                                    }
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {classList.length ? (
                <Box sx={{ padding: '0px 24px' }}>
                    <Box sx={{ borderRadius: '6px' }}>
                        <TableCustom
                            hasSort
                            rows={classList}
                            columns={col}
                            onRequestSort={handleRequestSort}
                            order={filter.order.toLowerCase()}
                            orderBy={filter.sortColumn}
                        />

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take || 10}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>
            ) : (
                <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <EmptyData />
                    </Grid>
                </Grid>
            )}
            {/* </MainCard> */}
        </div>
    );
};

export default ClassList;
