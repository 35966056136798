import { DesktopDatePickerProps } from '@mui/lab';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import LocalDesktopDatePicker from 'components/LocalDesktopDatePicker';
import { formatDate } from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface ILocalDesktopDateRangePicker extends DesktopDatePickerProps<Date> {
    fromDateValue: string | null;
    toDateValue: string | null;
    onChange: (e: any) => void;
    sx?: Object;
    dateSx?: Object;
    dateRangeErrorTextSx?: Object;
    isRequired?: boolean;
    disabled?: boolean;
    isAllowEqual?: boolean;
    disablePast?: boolean;
    isShowErrorMessage?: boolean;
    errorText?: string;
    onError?: (error: string | null) => void;
}

const FILTER_DATE_RANGE_NAME = {
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate'
};

const DateRangeLocalDesktopDatePicker = ({
    fromDateValue,
    toDateValue,
    isShowErrorMessage = true,
    onChange,
    sx = {},
    isAllowEqual = false,
    dateSx = {},
    dateRangeErrorTextSx = {},
    isRequired = false,
    disablePast = false,
    disabled = false,
    errorText = 'To Date must be greater than From Date',
    onError,
    ...props
}: ILocalDesktopDateRangePicker) => {
    const [errorMessage, setErrorMessage] = useState({
        errorText: '',
        inputErrorText: ''
    });
    const [filter, setFilter] = useState({
        fromDate: fromDateValue,
        toDate: toDateValue,
        errorText: ''
    });
    const [filterRanger, setFilterRanger] = useState({
        fromDate: fromDateValue || moment().format(formatDate.DATE_DEFAULT),
        toDate: toDateValue || moment().add(1, 'days').format(formatDate.DATE_DEFAULT)
    });

    useEffect(() => {
        onError && onError(errorMessage.errorText);
    }, [errorMessage]);

    useEffect(() => {
        onChange && onChange(filter);
    }, [filter]);

    const handleChangeDateRange = (name: string, inputValue: any) => {
        let newInputValue = { filterValue: inputValue, filterRangerValue: inputValue, errorText: '' };
        const date = moment(inputValue).format(formatDate.DATE_DEFAULT);

        if (!inputValue) {
            newInputValue = { filterValue: '', filterRangerValue: inputValue, errorText: '' };
        } else if (isAllowEqual) {
            // Check if from date is greater than to date
            if (name === FILTER_DATE_RANGE_NAME.FROM_DATE && filterRanger.toDate && date > filterRanger.toDate) {
                newInputValue = { ...newInputValue, filterValue: '', errorText };
            } else if (name === FILTER_DATE_RANGE_NAME.TO_DATE && filterRanger.fromDate && date < filterRanger.fromDate) {
                newInputValue = { ...newInputValue, filterValue: '', errorText };
            }
        } else if (!isAllowEqual) {
            // Check if from date is greater than or equal to date
            if (name === FILTER_DATE_RANGE_NAME.FROM_DATE && filterRanger.toDate && date >= filterRanger.toDate) {
                newInputValue = { ...newInputValue, filterValue: '', errorText };
            } else if (name === FILTER_DATE_RANGE_NAME.TO_DATE && filterRanger.fromDate && date <= filterRanger.fromDate) {
                newInputValue = { ...newInputValue, filterValue: '', errorText };
            }
        } else {
            newInputValue = { ...newInputValue, errorText: '' };
        }

        if (newInputValue?.errorText && !newInputValue?.filterValue) {
            setErrorMessage((prev) => {
                return { ...prev, errorText: newInputValue.errorText, inputErrorText: '' };
            });
        } else {
            setErrorMessage({ errorText: '', inputErrorText: '' });
        }

        const filterValue = newInputValue?.filterValue ? moment(newInputValue.filterValue).format(formatDate.DATE_DEFAULT) : '';
        const filterRangerValue = newInputValue?.filterRangerValue
            ? moment(newInputValue.filterRangerValue).format(formatDate.DATE_DEFAULT)
            : '';

        switch (name) {
            case 'fromDate': {
                setFilterRanger((prev) => ({ ...prev, fromDate: filterRangerValue }));
                setFilter({
                    fromDate: filterValue,
                    toDate: filterRanger.toDate,
                    errorText: newInputValue.errorText
                });
                break;
            }
            case 'toDate': {
                setFilterRanger((prev) => ({ ...prev, toDate: filterRangerValue }));
                setFilter({
                    fromDate: filterRanger.fromDate,
                    toDate: filterValue,
                    errorText: newInputValue.errorText
                });
                break;
            }
        }
    };

    const handleOnError = (error: string | null) => {
        if (error) {
            setErrorMessage({ errorText: '', inputErrorText: error });
        } else {
            setErrorMessage({ errorText: '', inputErrorText: '' });
        }
    };

    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <Box className="date-range-picker" sx={{ display: 'flex', gap: '10px', width: '100%' }}>
                <LocalDesktopDatePicker
                    disabled={disabled}
                    value={filterRanger.fromDate}
                    label="From Date"
                    sx={dateSx}
                    disablePast={disablePast}
                    isRequired={isRequired}
                    onChange={(value) => {
                        handleChangeDateRange(FILTER_DATE_RANGE_NAME.FROM_DATE, value);
                    }}
                    onError={handleOnError}
                    {...props}
                />
                <LocalDesktopDatePicker
                    disabled={disabled}
                    value={filterRanger.toDate}
                    label="To Date"
                    sx={dateSx}
                    disablePast={disablePast}
                    isRequired={isRequired}
                    onChange={(value) => {
                        handleChangeDateRange(FILTER_DATE_RANGE_NAME.TO_DATE, value);
                    }}
                    onError={handleOnError}
                    {...props}
                />
            </Box>
            {isShowErrorMessage && errorMessage.errorText && !errorMessage.inputErrorText && (
                <Typography
                    className="error-message_content"
                    variant="body2"
                    color="error"
                    sx={{ whiteSpace: 'nowrap', position: 'absolute', top: 40, left: 10, ...dateRangeErrorTextSx }}
                >
                    {errorMessage.errorText}
                </Typography>
            )}
        </Box>
    );
};

export default DateRangeLocalDesktopDatePicker;
