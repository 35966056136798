import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../header.module.scss';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// icons
import { IconTicket, IconChevronRight, IconChevronDown } from '@tabler/icons';
import { ReactComponent as ReliefTicketIcon } from 'assets/images/icons/topbars/tabler-icon-relief-ticket.svg';
import { ReactComponent as ReliefPoolIcon } from 'assets/images/icons/topbars/tabler-icon-relief-pool.svg';
import { ReactComponent as TimeApprovalIcon } from 'assets/images/icons/topbars/TimeSheetApproval.svg';

import { RedirectPath, Roles } from 'config';

// ==============================|| SEARCH INPUT - MEGA MENu||============================== //
interface IProps {
    userRole: string;
    path: string;
}
const ReliefManagementSection = (props: IProps) => {
    const { userRole, path } = props;
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <>
            <div className="wrapper-menu">
                <div className="menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className="group-menu">
                        <IconTicket stroke={1.5} size="20px" />
                        <span style={{ display: 'inline-block', margin: '0px 5px' }}>Relief Management</span>{' '}
                        {isDropdownVisible ? <IconChevronDown stroke={1.5} size="20px" /> : <IconChevronRight stroke={1.5} size="20px" />}
                    </div>
                    {isDropdownVisible && userRole !== Roles.User && (
                        <div className="dropdown-menu">
                            <ul>
                                <List>
                                    {userRole !== Roles.User && (
                                        <ListItemButton
                                            disableRipple
                                            component={Link}
                                            to="/staff/relief-list"
                                            className={path === RedirectPath.RELIEF_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <ReliefTicketIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Relief Ticket" style={{ whiteSpace: 'nowrap' }} />
                                        </ListItemButton>
                                    )}

                                    {(userRole === Roles.HrAdmin ||
                                        userRole === Roles.HrIc ||
                                        userRole === Roles.Principals ||
                                        userRole === Roles.Cluster) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/relief-ticket"
                                            className={path === RedirectPath.RELIEF_TICKET ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <ReliefPoolIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Relief Pool" />
                                        </ListItemButton>
                                    )}
                                    {(userRole === Roles.Principals ||
                                        userRole === Roles.Admin ||
                                        userRole === Roles.Cluster ||
                                        userRole === Roles.HrIc ||
                                        userRole === Roles.HrAdmin) && (
                                        <ListItemButton
                                            component={Link}
                                            to="/staff/timesheet-approval"
                                            className={path === RedirectPath.TIMESHEET_LIST ? styles.activeItem : styles.item}
                                        >
                                            <ListItemIcon>
                                                <TimeApprovalIcon className={styles.item} />
                                            </ListItemIcon>
                                            <ListItemText primary="Timesheet Approval" />
                                        </ListItemButton>
                                    )}
                                </List>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ReliefManagementSection;
