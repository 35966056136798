// project imports

// assets

// project imports
import { Checkbox, Chip, Grid, Paper, TablePagination, Typography } from '@mui/material';
import EmptyData from 'components/errors/empty-data';
import ModalCustom from 'components/Modal/ModalCustom';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import { DEFAULT_REQUEST } from 'config';
import UIManager from 'core/UIManager';
import { useEffect, useState } from 'react';
import { StaffApi } from 'services/api';
import { StaffKecho } from 'types/kecho/staff-kecho';
import { IFilterRequest } from 'types/query/filter';

// ===========================|| FIREBASE - REGISTER ||=========================== //
interface IProps {
    onClose: () => void;
    callback: any;
    centerId?: string;
    isTemporary?: string;
}

interface Row extends StaffKecho {
    name: string;
    email: string;
    phone: string;
    diploma: string;
    visibility: string;
}

const convertData = (data: StaffKecho): Row[] => {
    return data.map((item: StaffKecho) => {
        return {
            ...item,
            name: item.nameStaff,
            email: item?.email,
            phone: item?.phoneNumber
        };
    });
};

const VisibilityModal = (props: IProps) => {
    const { onClose, callback, centerId, isTemporary } = props;

    const [filter, setFilter] = useState<IFilterRequest>({ ...DEFAULT_REQUEST, isTemporary: '' });
    const [staffs, setStaffs] = useState<StaffKecho[]>([]);
    const [allStaffs, setAllStaffs] = useState<StaffKecho[]>([]);
    const [metaData, setMetaData] = useState<any>({});

    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const isRowSelected = (row: any) => selectedRows.some((selectedRow) => selectedRow.id === row.id);

    const col: IColumnTable<Row>[] = [
        {
            field: 'name',
            headerName: 'Name'
        },
        {
            field: 'email',
            headerName: 'Email'
        },
        {
            field: 'phone',
            headerName: 'Phone'
        },
        {
            field: 'diploma',
            headerName: 'Diplomas',
            render: (row) => {
                return (
                    <Typography>
                        {row?.staffCert?.map((item: any) => {
                            return <Chip key={item?.id} label={item?.cert?.diploma} />;
                        })}
                    </Typography>
                );
            }
        },
        {
            field: 'visibility',
            headerName: 'Visibility',
            render: (row) => {
                return (
                    <Checkbox
                        checked={allStaffs.filter((item) => !item.isHidden).some((item) => item.id === row.id && item.isHidden === false)}
                        onChange={(event) => handleCheckboxChange(event, row)}
                    />
                );
            }
        }
    ];

    useEffect(() => {
        handleGetStaffList();
    }, [filter]);
    useEffect(() => {
        handleGetAllStaffList();
    }, []);
    const handleGetStaffList = async () => {
        const { dataList, meta } = await StaffApi.list({ ...filter, centreId: centerId, isTemporary });
        if (dataList) setStaffs(dataList);
        if (meta) setMetaData(meta);
    };

    const handleGetAllStaffList = async () => {
        const { dataList } = await StaffApi.listAllStaffByCenter({ centreId: centerId, isTemporary });
        if (dataList) setAllStaffs(dataList);
    };

    const handleCheckboxChange = (event: any, row: any) => {
        const updatedAllStaffs = allStaffs.map((item) => {
            if (!item.isHidden && item.id === row.id) {
                return {
                    ...item,
                    isHidden: true
                };
            }
            if (item.isHidden && item.id === row.id) {
                return {
                    ...item,
                    isHidden: false
                };
            }

            return item;
        });
        setAllStaffs(updatedAllStaffs);
        setSelectedRows(updatedAllStaffs);
    };

    const handleSaveVisibility = async () => {
        try {
            const isHiddens = selectedRows.map((item) => ({ id: item.id, isHidden: item.isHidden }));
            const data = {
                centerId,
                staffs: isHiddens
            };
            const res = await StaffApi.visibilityStaff(data);
            if (res) {
                UIManager.showAlert({
                    message: 'Visibility staff successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                callback();
                onClose();
            }
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setFilter({ ...filter, page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const take = Number(event?.target?.value) || 10;
        setFilter({ ...filter, page: 1, take });
    };

    return (
        <ModalCustom
            onSave={handleSaveVisibility}
            onClose={onClose}
            onCancel={onClose}
            title="Deployment & Sizing visibility configuration"
            contentCancel="Cancel"
            contentSave="Save"
            // sx={{ width: 600, maxHeight: '300px', overflowY: step === 1 ? 'scroll' : 'hidden' }}
            // iconSave={step === 2 && !isSave ? CheckIcon : undefined}
            // typeHelper={isError && !isSave ? 'error' : 'warning'}
            // msgHelper={msg}
        >
            <Paper className="MuiPaper-root" style={{ width: '100%' }}>
                {staffs?.length ? (
                    <>
                        <TableCustom
                            rows={convertData(staffs)}
                            columns={col}
                            onRowClick={(row) => {
                                isRowSelected(row)
                                    ? handleCheckboxChange({ target: { checked: false } }, row)
                                    : handleCheckboxChange({ target: { checked: true } }, row);
                            }}
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={metaData?.itemCount || 0}
                            rowsPerPage={filter.take}
                            page={filter.page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid
                            item
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                        >
                            <EmptyData />
                            <div style={{ padding: '20px 0' }}>There is no data</div>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </ModalCustom>
    );
};

export default VisibilityModal;
