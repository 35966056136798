import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { IRatioTable } from 'types/kecho/staff-kecho';

// project imports
import { LoadingButton } from '@mui/lab';
import { CircularProgress, TextField, Tooltip } from '@mui/material';
import editIcon from 'assets/images/pages/edit-icon.svg';
import resetIcon from 'assets/images/pages/reset-icon.svg';
import saveIcon from 'assets/images/pages/save-icon.svg';
import CustomSelectCenter from 'components/CustomSelectCenter';
import { AUTH_LOCAL_STORAGE_KEY, DEFAULT_CENTER_OPTION, GET_ALL_REQUEST, RedirectPath, Roles, useStyles } from 'config';
import UIManager from 'core/UIManager';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { CentreApi } from 'services/api';
import ratioApi from 'services/api/ratioApi';
import { CentreKecho } from 'types/kecho/centre-kecho';
import { ITableRatioCenterId } from 'types/kecho/class-kecho';
import * as Yup from 'yup';
import { RatioWarningModal } from './components';
import './styles.scss';

interface FormValues {
    [key: string]: string | number;
}

export default function RatioInfo() {
    const classes = useStyles();
    const [data, setData] = useState<IRatioTable>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTogglingMode, setIsTogglingMode] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<CentreKecho[]>([]);
    const [formInitialValues, setFormInitialValues] = useState({});
    const [formValidation, setFormValidation] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [selectedCenterId, setSelectedCenterId] = useState<string>('');
    const userRole = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY.USER_INFO) || '')?.role?.roleName;
    const classLevels = ['Infant', 'K1', 'K2', 'Nursery', 'Playgroup', 'Pre-Nursery'];
    const titleForClassLevel = ['teacher', 'teacherWithAssistant'];

    useEffect(() => {
        handleGetListRatio();
        handleGetCentreList();
    }, []);

    useEffect(() => {
        if (location.pathname === RedirectPath.RATIO_LIST_EDIT && Object.keys(data).length > 0) {
            if (userRole !== Roles.Admin && (!selectedCenterId || selectedCenterId === DEFAULT_CENTER_OPTION.id)) {
                handleRedirectToRatioList();
                return;
            }

            setTableFieldValue();
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [location.pathname, data]);

    useEffect(() => {
        if (selectedCenterId) {
            const { KWS, LFP } = data;
            let columnsNames: string[] = ['ECDA'];
            if (KWS) {
                columnsNames = ['KWS'];
            }
            if (LFP) {
                columnsNames = ['LFP'];
            }
            if (selectedCenterId === DEFAULT_CENTER_OPTION.id) {
                columnsNames = ['KWS', 'LFP', 'ECDA'];
            }
            handleFormInitialValues(columnsNames);
            setYupShape(columnsNames);
        }
    }, [data]);

    useEffect(() => {
        if (location.pathname === RedirectPath.RATIO_LIST_EDIT && Object.keys(data).length > 0) {
            setTableFieldValue();
        }
    }, [formInitialValues, formValidation]);

    const setYupShape = (columnsName: string[]) => {
        const yupShape: any = {};
        columnsName.forEach((columnName: string) => {
            titleForClassLevel.forEach((title: string) => {
                classLevels.forEach((key: string) => {
                    const keyname = `${columnName}_${key}_${title}`;
                    yupShape[keyname] = Yup.number()
                        .integer('Must be a rounded number')
                        .min(1, 'Ratio must be greater than or equal to 1')
                        .required('This field is required')
                        .test(
                            'is-valid-ratio',
                            () => {
                                if (title === 'teacher') {
                                    return 'Cannot be bigger than the value in “Number of children (With 1 A.T)”';
                                }
                                if (title === 'teacherWithAssistant') {
                                    return 'Cannot be smaller than the value in “Number of children (Only Trained T)”';
                                }
                                return 'Invalid ratio';
                            },
                            // eslint-disable-next-line func-names
                            function (value) {
                                if (!value) return false;

                                const teacherKey = `${columnName}_${key}_teacher`;
                                const assistantKey = `${columnName}_${key}_teacherWithAssistant`;

                                // eslint-disable-next-line react/no-this-in-sfc
                                if (!this.parent[assistantKey] || !this.parent[teacherKey]) return true;

                                // eslint-disable-next-line react/no-this-in-sfc
                                if (title === 'teacher' && this.parent[assistantKey]) {
                                    // eslint-disable-next-line react/no-this-in-sfc
                                    return value <= this.parent[assistantKey];
                                }
                                // eslint-disable-next-line react/no-this-in-sfc
                                if (title === 'teacherWithAssistant' && this.parent[assistantKey]) {
                                    // eslint-disable-next-line react/no-this-in-sfc
                                    return value >= this.parent[teacherKey];
                                }
                                return true;
                            }
                        );
                });
            });
        });

        const formSchema = Yup.object().shape(yupShape);
        setFormValidation(formSchema as any);
    };

    const handleFormInitialValues = (columnsName: string[]) => {
        const initalValue: any = {};
        columnsName.forEach((columnName: string) => {
            titleForClassLevel.forEach((title: string) => {
                classLevels.forEach((key: string) => {
                    const keyname = `${columnName}_${key}_${title}`;
                    initalValue[keyname] = 1;
                });
            });
        });
        resetForm(initalValue);
        setFormInitialValues(initalValue as any);
    };

    const isAllowEditGeneral = () => {
        return userRole === Roles.Admin || userRole === Roles.Cluster || userRole === Roles.Principals;
    };

    const formik = useFormik<FormValues>({
        validateOnChange: true,
        initialValues: formInitialValues,
        validationSchema: formValidation,
        onSubmit: async (values: any) => {
            const newValues: any[] = [];
            const inputValuesLength = Object.keys(values);

            inputValuesLength.forEach((item) => {
                const [columnName, classLevel, title] = item.split('_');
                let columnEntry = newValues.find((ratio) => ratio.branchName === columnName);
                if (!columnEntry) {
                    columnEntry = { branchName: columnName, ratio: [] };
                    newValues.push(columnEntry);
                }

                const existingEntry = columnEntry.ratio.find((ratio: any) => ratio.level === classLevel);

                if (existingEntry) {
                    if (title.includes('teacherWithAssistant')) {
                        const assistantsNumber = +values[item] - existingEntry.teacher;
                        existingEntry.assistant = assistantsNumber <= 0 ? 0 : assistantsNumber;
                    } else {
                        existingEntry[title] = +values[item];
                    }
                } else {
                    const ratio: any = { level: classLevel };
                    ratio[title] = +values[item];
                    columnEntry.ratio.push(ratio);
                }
            });
            await handleEditTableRatio(selectedCenterId, newValues);
        }
    });

    const { errors, touched, submitForm, handleSubmit, resetForm, getFieldProps, setFieldValue, setTouched } = formik;

    const setAllFieldsTouched = (fields?: { [key: string]: number }) => {
        setTimeout(() => {
            const allTouched: { [key: string]: boolean } = {};
            Object.keys(fields || formInitialValues).forEach((field) => {
                allTouched[field] = true;
            });
            setTouched(allTouched, true);
        }, 100);
    };

    const setTableFieldValue = async () => {
        const fieldValues: { [key: string]: number } = {};
        classLevels.forEach((key: string) => {
            if (data.KWS) {
                const KWSTeacherAmount = data.KWS?.[key]?.teacher || 1;
                const KWSTeacherWithAssistantAmount = KWSTeacherAmount + (data.KWS?.[key]?.assistant || 0) || 0;
                fieldValues[`KWS_${key}_teacher`] = KWSTeacherAmount;
                fieldValues[`KWS_${key}_teacherWithAssistant`] = KWSTeacherWithAssistantAmount;
            }

            if (data.LFP) {
                const LFPTeacherAmount = data.LFP?.[key]?.teacher || 1;
                const LFPTeacherWithAssistantAmount = LFPTeacherAmount + (data.LFP?.[key]?.assistant || 0) || 0;
                fieldValues[`LFP_${key}_teacher`] = LFPTeacherAmount;
                fieldValues[`LFP_${key}_teacherWithAssistant`] = LFPTeacherWithAssistantAmount;
            }

            if (data.ecda && userRole === Roles.Admin) {
                const ecdaTeacherAmount = data.ecda?.[key]?.teacher || 1;
                const ecdaTeacherWithAssistantAmount = ecdaTeacherAmount + (data.ecda?.[key]?.assistant || 0) || 0;
                fieldValues[`ECDA_${key}_teacher`] = ecdaTeacherAmount;
                fieldValues[`ECDA_${key}_teacherWithAssistant`] = ecdaTeacherWithAssistantAmount;
            }
        });

        Object.keys(fieldValues).forEach((field) => {
            setFieldValue(field, fieldValues[field]);
        });

        setAllFieldsTouched(fieldValues);
    };

    const handleSelectCenter = (centerId: string) => {
        handleGetListRatio(centerId);
        setSelectedCenterId(centerId);
    };

    const handleEditTableRatio = async (centerId: string, values: any) => {
        let newValues;
        let newCenterId = centerId;
        if (centerId === DEFAULT_CENTER_OPTION.id) {
            newCenterId = '';
            newValues = values;
        } else {
            newValues = values[0]?.ratio;
        }

        try {
            const res = await ratioApi.editRatio(newCenterId, { branch: newValues });
            if (res?.error) {
                UIManager.showAlert({
                    message: res.error.response.data.message,
                    alert: {
                        color: 'error'
                    }
                });
                return;
                // eslint-disable-next-line no-else-return
            } else {
                UIManager.showAlert({
                    message: 'Edit successfully',
                    alert: {
                        color: 'success'
                    }
                });
            }

            handleRedirectToRatioList();
            await handleGetListRatio(selectedCenterId);
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const handleGetCentreList = async () => {
        const { dataList } = await CentreApi.listCentre(GET_ALL_REQUEST);
        if (dataList?.length > 0) {
            dataList.unshift(DEFAULT_CENTER_OPTION);
            setCenterData(dataList);
            setSelectedCenterId(dataList[0].id);
        }
    };

    const handleGetListRatio = async (centerId?: string) => {
        setIsLoading(true);

        let uploadDataFormat = { centerId } as ITableRatioCenterId;

        if (centerId === DEFAULT_CENTER_OPTION.id) {
            uploadDataFormat = {} as ITableRatioCenterId;
        }

        const res = await ratioApi.tableRatio(uploadDataFormat);
        if (res.data) {
            setData(res.data);
        }
        setIsLoading(false);
    };

    const handleResetCenterRatio = async () => {
        if (selectedCenterId === DEFAULT_CENTER_OPTION.id) {
            return;
        }

        try {
            const res = await ratioApi.resetRatio(selectedCenterId);
            if (res?.error) {
                UIManager.showAlert({
                    message: res.error.response.data.message,
                    alert: {
                        color: 'error'
                    }
                });
                return;
                // eslint-disable-next-line no-else-return
            } else {
                UIManager.showAlert({
                    message: 'Reset successfully',
                    alert: {
                        color: 'success'
                    }
                });
            }

            await handleGetListRatio(selectedCenterId);
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const hanldeOnClickEditBtn = async () => {
        setIsTogglingMode(true);

        setTimeout(() => {
            handleRedirectToRatioListEdit();
            setIsTogglingMode(false);
        }, 300);
    };

    const handleOnClickCancelBtn = () => {
        setIsTogglingMode(true);
        setTimeout(() => {
            handleRedirectToRatioList();
            resetForm();
            setIsTogglingMode(false);
        }, 300);
    };

    const handleRedirectToRatioList = () => {
        navigate(RedirectPath.RATIO_LIST);
    };

    const handleRedirectToRatioListEdit = () => {
        navigate(RedirectPath.RATIO_LIST_EDIT);
    };

    const handleOpenResetWarningModal = () => {
        const getSelectedCenter = centerData.find((center) => center.id === selectedCenterId);
        UIManager.showModal({
            content: (onClose: any) => (
                <RatioWarningModal
                    onClose={onClose}
                    title={
                        <>
                            You are about to reset
                            <Box component="span" fontWeight="600" color="#222">
                                {' '}
                                {getSelectedCenter?.nameCenter}{' '}
                            </Box>
                            center's ratio back to default
                        </>
                    }
                    bodyContent="This action cannot be undone"
                    callback={handleResetCenterRatio}
                />
            ),
            stylesModal: { maxWidth: 700 }
        });
    };

    const handleOpenEditWarningModal = () => {
        UIManager.showModal({
            content: (onClose: any) => (
                <RatioWarningModal
                    onClose={onClose}
                    title="You are about to change the ratio data"
                    bodyContent="Changing the ratio data will affect the Staff Deployment Plan and Sizing Page calculation"
                    callback={async () => {
                        await submitForm();
                    }}
                />
            ),
            stylesModal: { maxWidth: 700 }
        });
    };

    const handleOnkeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = event.key;
        if (keyCode === 'e') {
            event.preventDefault();
        }
    };

    const renderHeader = () => {
        const { KWS, LFP } = data;

        return (
            <>
                <TableRow
                    sx={{
                        backgroundColor: '#303972',
                        '.MuiTableCell-root': {
                            color: '#fff',
                            textAlign: 'left !important',
                            fontWeight: '600 !important',
                            border: 'none !important',
                            borderRight: '1px solid #fff !important',
                            '&:last-child': {
                                borderRight: 'none !important'
                            }
                        },
                        '&:last-child': {
                            borderBottom: '1px solid #E3E6EB !important'
                        }
                    }}
                >
                    <TableCell
                        sx={{
                            borderTopLeftRadius: '8px'
                        }}
                        colSpan={1}
                        rowSpan={1}
                        width={0.3}
                    >
                        <TableRow>Level</TableRow>
                    </TableCell>
                    {KWS && LFP ? (
                        <>
                            <TableCell align="center" colSpan={2}>
                                KWSH/PIBOS
                            </TableCell>

                            <TableCell align="center" colSpan={2}>
                                LFP
                            </TableCell>
                        </>
                    ) : (
                        <TableCell align="center" colSpan={2}>
                            BRAND
                        </TableCell>
                    )}

                    <TableCell
                        sx={{
                            borderTopRightRadius: '8px'
                        }}
                        align="center"
                        colSpan={2}
                    >
                        ECDA
                    </TableCell>
                </TableRow>
                <TableRow
                    sx={{
                        backgroundColor: '#F0F2FF',
                        '.MuiTableCell-root': {
                            color: '#222',
                            fontWeight: '600 !important',
                            borderBottom: '1px solid #E3E6EB !important'
                        }
                    }}
                >
                    <TableCell
                        sx={{ borderLeft: '1px solid #E3E6EB !important', borderRight: '1px solid #E3E6EB !important' }}
                        width={1}
                        rowSpan={1}
                        colSpan={1}
                    >
                        {}
                    </TableCell>
                    <TableCell sx={{ borderLeft: 'none !important', borderRight: 'none !important' }} width={1} colSpan={1}>
                        No. of Children (Only Trained T)
                    </TableCell>
                    <TableCell sx={{ borderLeft: 'none !important', borderRight: '1px solid #E3E6EB !important' }} width={1} colSpan={1}>
                        No. of Children (With 1 AT)
                    </TableCell>
                    {KWS && LFP && (
                        <>
                            <TableCell sx={{ borderLeft: 'none !important', borderRight: 'none !important' }} width={1} colSpan={1}>
                                No. of Children (Only Trained T)
                            </TableCell>
                            <TableCell
                                sx={{ borderLeft: 'none !important', borderRight: '1px solid #E3E6EB !important' }}
                                width={1}
                                colSpan={1}
                            >
                                No. of Children (With 1 AT)
                            </TableCell>
                        </>
                    )}
                    <TableCell sx={{ borderLeft: 'none !important', borderRight: 'none !important' }} width={1} colSpan={1}>
                        No. of Children (Only Trained T)
                    </TableCell>
                    <TableCell sx={{ borderLeft: 'none !important', borderRight: '1px solid #E3E6EB !important' }} width={1} colSpan={1}>
                        No. of Children (With 1 AT)
                    </TableCell>
                </TableRow>
            </>
        );
    };

    const renderBodyTable = () => {
        const { KWS, ecda, LFP } = data;
        return (
            <>
                {classLevels.map((key: string, classLevelsIndex: number) => {
                    return (
                        <TableRow
                            className="ratio-table__body-row"
                            sx={{
                                backgroundColor: classLevelsIndex % 2 !== 0 ? '#F5F5F5' : '#FFF'
                            }}
                            key={key}
                        >
                            <TableCell className="ratio-table__body-row__title">{key}</TableCell>
                            {!(isEditMode && isAllowEditGeneral()) ? (
                                <>
                                    <TableCell className="ratio-table__body-row__trained-T">{KWS && KWS[key].teacher}</TableCell>
                                    <TableCell className="ratio-table__body-row__with-1-AT">
                                        {KWS && KWS[key].teacher + (KWS[key].assistant || 0)}
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="ratio-table__body-row__trained-T">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            {...getFieldProps(`KWS_${key}_teacher`)}
                                            onKeyDown={handleOnkeyDown}
                                            {...getFieldProps(`KWS_${key}_teacher`)}
                                            error={Boolean(touched[`KWS_${key}_teacher`] && errors[`KWS_${key}_teacher`])}
                                            helperText={touched[`KWS_${key}_teacher`] && errors[`KWS_${key}_teacher`]}
                                        />
                                    </TableCell>
                                    <TableCell className="ratio-table__body-row__with-1-AT">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            {...getFieldProps(`KWS_${key}_teacherWithAssistant`)}
                                            onKeyDown={handleOnkeyDown}
                                            error={Boolean(
                                                touched[`KWS_${key}_teacherWithAssistant`] && errors[`KWS_${key}_teacherWithAssistant`]
                                            )}
                                            helperText={
                                                touched[`KWS_${key}_teacherWithAssistant`] && errors[`KWS_${key}_teacherWithAssistant`]
                                            }
                                        />
                                    </TableCell>
                                </>
                            )}
                            {LFP &&
                                (!(isEditMode && isAllowEditGeneral()) ? (
                                    <>
                                        <TableCell className="ratio-table__body-row__trained-T">{LFP[key].teacher}</TableCell>
                                        <TableCell className="ratio-table__body-row__with-1-AT">
                                            {' '}
                                            {LFP[key].teacher + (LFP[key].assistant || 0)}
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell className="ratio-table__body-row__trained-T">
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                {...getFieldProps(`LFP_${key}_teacher`)}
                                                onKeyDown={handleOnkeyDown}
                                                error={Boolean(touched[`LFP_${key}_teacher`] && errors[`LFP_${key}_teacher`])}
                                                helperText={touched[`LFP_${key}_teacher`] && errors[`LFP_${key}_teacher`]}
                                            />
                                        </TableCell>
                                        <TableCell className="ratio-table__body-row__with-1-AT">
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                {...getFieldProps(`LFP_${key}_teacherWithAssistant`)}
                                                onKeyDown={handleOnkeyDown}
                                                error={Boolean(
                                                    touched[`LFP_${key}_teacherWithAssistant`] && errors[`LFP_${key}_teacherWithAssistant`]
                                                )}
                                                helperText={
                                                    touched[`LFP_${key}_teacherWithAssistant`] && errors[`LFP_${key}_teacherWithAssistant`]
                                                }
                                            />
                                        </TableCell>
                                    </>
                                ))}
                            {!(isEditMode && userRole === Roles.Admin && selectedCenterId === DEFAULT_CENTER_OPTION.id) ? (
                                <>
                                    <TableCell className="ratio-table__body-row__trained-T">{ecda && ecda[key].teacher}</TableCell>
                                    <TableCell className="ratio-table__body-row__with-1-AT">
                                        {ecda && ecda[key].teacher + (ecda[key].assistant || 0)}
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="ratio-table__body-row__trained-T">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            {...getFieldProps(`ECDA_${key}_teacher`)}
                                            onKeyDown={handleOnkeyDown}
                                            error={Boolean(touched[`ECDA_${key}_teacher`] && errors[`ECDA_${key}_teacher`])}
                                            helperText={touched[`ECDA_${key}_teacher`] && errors[`ECDA_${key}_teacher`]}
                                        />
                                    </TableCell>
                                    <TableCell className="ratio-table__body-row__with-1-AT">
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            {...getFieldProps(`ECDA_${key}_teacherWithAssistant`)}
                                            onKeyDown={handleOnkeyDown}
                                            error={Boolean(
                                                touched[`ECDA_${key}_teacherWithAssistant`] && errors[`ECDA_${key}_teacherWithAssistant`]
                                            )}
                                            helperText={
                                                touched[`ECDA_${key}_teacherWithAssistant`] && errors[`ECDA_${key}_teacherWithAssistant`]
                                            }
                                        />
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    );
                })}
            </>
        );
    };

    const renderEditBtn = () => {
        return (
            <>
                {!data?.isDefault && selectedCenterId !== DEFAULT_CENTER_OPTION.id && (
                    <Tooltip title="Edit ratio" placement="top">
                        <LoadingButton
                            variant="outlined"
                            style={{ marginRight: 8, color: '#3F51B5', border: '1px solid #3F51B5' }}
                            size="medium"
                            onClick={handleOpenResetWarningModal}
                            loading={isTogglingMode}
                            startIcon={<img src={resetIcon} alt="Reset icon" />}
                        >
                            Reset
                        </LoadingButton>
                    </Tooltip>
                )}
                <Tooltip title="Edit ratio" placement="top">
                    <LoadingButton
                        variant="contained"
                        style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                        size="medium"
                        onClick={hanldeOnClickEditBtn}
                        loading={isTogglingMode}
                        startIcon={<img style={{ marginRight: '8px' }} src={editIcon} alt="Edit icon" />}
                    >
                        Edit
                    </LoadingButton>
                </Tooltip>
            </>
        );
    };

    const renderCancelAndSaveBtn = () => {
        return (
            <>
                {/* <LoadingButton
                    onClick={handleOnClickCancelBtn}
                    variant="outlined"
                    sx={{ color: '#3F51B5', fontSize: '0.938rem', marginRight: '10px' }}
                    loading={isTogglingMode}
                >
                    Cancel
                </LoadingButton> */}
                <Tooltip title="Cancel" placement="top">
                    <LoadingButton
                        variant="outlined"
                        style={{ color: '#3F51B5', border: '1px solid #3F51B5' }}
                        size="medium"
                        loading={isTogglingMode}
                        onClick={handleOnClickCancelBtn}
                    >
                        Cancel
                    </LoadingButton>
                </Tooltip>

                <Tooltip title="Save" placement="top">
                    <LoadingButton
                        variant="contained"
                        style={{ backgroundColor: '#3f51b5', marginLeft: 10 }}
                        size="medium"
                        onClick={handleOpenEditWarningModal}
                        loading={isTogglingMode}
                        startIcon={<img style={{ marginRight: '8px' }} src={saveIcon} alt="Save icon" />}
                    >
                        Save
                    </LoadingButton>
                </Tooltip>
            </>
        );
    };

    const renderEditRatioActionBtn = () => {
        return <Grid>{isEditMode ? renderCancelAndSaveBtn() : renderEditBtn()}</Grid>;
    };

    const renderUserAction = () => {
        if (!centerData.length) return <></>;

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Grid>
                    {centerData.length > 0 && (
                        <CustomSelectCenter
                            data={centerData}
                            value={selectedCenterId}
                            name="centerId"
                            label="Center"
                            onChange={(e) => handleSelectCenter(e.target.value)}
                            disabled={isTogglingMode || isEditMode}
                            selectProps={{
                                MenuProps: {
                                    classes: {
                                        paper: classes.selectMenu
                                    }
                                }
                            }}
                        />
                    )}
                </Grid>
                {isAllowEditGeneral() &&
                    !(userRole !== Roles.Admin && selectedCenterId === DEFAULT_CENTER_OPTION.id) &&
                    renderEditRatioActionBtn()}
            </Box>
        );
    };

    return (
        <div
            style={{ backgroundColor: 'white', padding: '0 20px 20px 20px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
        >
            {/* <MainCard title="List Ratio" content={false}> */}
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {renderUserAction()}
                    <Paper sx={{ width: '100%' }}>
                        {isLoading ? (
                            <Box width="100%" height="400px" display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer
                                sx={{
                                    maxHeight: 600
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        {renderHeader()}
                                        {renderBodyTable()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </Form>
            </FormikProvider>
            {/* </MainCard> */}
        </div>
    );
}
