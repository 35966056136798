import { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import ModalCustom from 'components/Modal/ModalCustom';
import { useStyles } from 'config';
import DashboardApi from 'services/api/dashboardApi';
import UIManager from 'core/UIManager';

interface IProps {
    onClose: () => void;
}
const DownloadReportModal = (props: IProps) => {
    const classes = useStyles();
    const { onClose } = props;

    const [currentYear, setCurrentYear] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getListYear = () => {
        const listYear = [];
        const currYear = new Date().getFullYear();
        const startYear = currYear - 5;
        const endYear = currYear + 5;
        for (let i = endYear; i >= startYear; i -= 1) {
            listYear.push(i);
        }
        return listYear;
    };

    const handleGenerateReport = async () => {
        setIsLoading(true);
        try {
            const res = await DashboardApi.downloadReports(currentYear);
            if (!res?.data) {
                UIManager.showAlert({
                    message: 'No data to export. Please choose a different year',
                    alert: {
                        color: 'error'
                    }
                });
                setIsLoading(false);
                return;
            }

            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Enrolment and Manpower ${currentYear}.xlsx`);
            document.body.appendChild(link);
            link.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);

            setIsLoading(false);
            UIManager.showAlert({
                message: 'Report generated successfully',
                alert: {
                    color: 'success'
                }
            });
            onClose();
        } catch (error) {
            setIsLoading(false);
            UIManager.showAlert({
                message: 'Failed to generate report',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    return (
        <ModalCustom
            onSave={handleGenerateReport}
            onClose={onClose}
            onCancel={onClose}
            title="Please select a year for report generation"
            contentSave="Generate"
            contentCancel="Back"
            disabled={currentYear === 0}
            isLoading={isLoading}
        >
            <Grid item xs={12} style={{ padding: '10px 0px' }}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel size="small" id="demo-simple-select-label">
                        Year
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="isLast"
                        value={currentYear}
                        label="Year"
                        size="small"
                        onChange={(e) => {
                            setCurrentYear(e.target.value as number);
                        }}
                        MenuProps={{
                            classes: {
                                paper: classes.selectMenu
                            }
                        }}
                    >
                        <MenuItem value={0} disabled>
                            Select year
                        </MenuItem>
                        {getListYear().map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </ModalCustom>
    );
};

export default DownloadReportModal;
