import { Checkbox, Grid, styled, TableCell, tableCellClasses, TableRow, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import EmptyData from 'components/errors/empty-data';
import moment from 'moment';
import { ClassKecho } from 'types/kecho/class-kecho';
// import { set } from 'lodash';
import DateRangeLocalDesktopDatePicker from 'components/LocalDesktopDateRangePicker';
import ModalCustom from 'components/Modal/ModalCustom';
import TableCustom, { IColumnTable } from 'components/Table/TableCustom';
import UIManager from 'core/UIManager';
import { PromiseApi } from 'types/kecho/api-promise';

interface IProps {
    onClose: any;
    onSave: (dateFrom: string, dateTo: string, classSelect: ClassKecho) => Promise<PromiseApi>;
    listClass: ClassKecho[];
    callback?: () => void;
}

interface Row extends ClassKecho {
    idClass?: string;
    name?: string;
    nameClass?: string;
    nameLevel?: string;
    select?: any;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#303972',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    },

    '&:hover': {
        backgroundColor: '#F0F2FF !important'
    }
}));

export default function ClearDuplicate({ onClose, onSave, listClass, callback }: IProps) {
    const [fromDate, setFromDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [endDate, setEndDate] = useState<any>(moment().format('YYYY/MM/DD'));
    const [isError, setIsError] = useState<boolean>(false);
    const [msg, setMsg] = useState<string>('');
    const [isSave, setIsSave] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<Row[]>([]);
    const [loading, setLoading] = useState(false);

    const col: IColumnTable<Row>[] = [
        {
            field: 'nameClass',
            headerName: 'Name'
        },
        {
            field: 'nameLevel',
            headerName: 'Level'
        },
        {
            field: 'select',
            headerName: 'Select',
            render: (row) => {
                return <Checkbox checked={isRowSelected(row)} onChange={(event) => handleCheckboxChange(event, row)} />;
            }
        }
    ];

    const listClassMapper: Row[] = listClass.map((item) => {
        return {
            idClass: item.idClass,
            nameClass: item.nameClass,
            nameLevel: item.level?.nameLevel,
            name: '',
            select: ''
        };
    });

    const handleCheckDuplicate = async () => {
        if (isError || !fromDate || !endDate) return;

        setIsError(true);
        setMsg('Do you want to continue?');
        setIsSave(true);
    };

    const handleSaveClear = async () => {
        try {
            setLoading(true);
            const res = await onSave(moment(fromDate).format('YYYY/MM/DD'), moment(endDate).format('YYYY/MM/DD'), selectedRows);
            if (res?.error) {
                UIManager.showAlert({
                    message: res.error.response.data.message,
                    alert: {
                        color: 'error'
                    }
                });
                setLoading(false);
                return;
            }
            UIManager.showAlert({
                message: 'Deployment cleared successfully',
                alert: {
                    color: 'success'
                }
            });
            setLoading(false);
            if (callback) callback();
            onClose();
        } catch (error) {
            UIManager.showAlert({
                message: 'Something went wrong',
                alert: {
                    color: 'error'
                }
            });
            setLoading(false);
        }
    };

    const [step, setStep] = useState<number>(1);

    const handleStep = () => {
        if (!selectedRows.length) {
            UIManager.showAlert({
                message: 'Please choose at least one class',
                alert: {
                    color: 'error'
                }
            });
            return;
        }
        if (step === 1) setStep(2);
    };
    const handleCheckboxChange = (event: any, row: any) => {
        const { checked } = event.target;

        setSelectedRows((prevSelectedRows) => {
            if (checked) {
                // Add the row to the selectedRows array if it's not already present
                if (!prevSelectedRows.find((selectedRow) => selectedRow.idClass === row.idClass)) {
                    return [...prevSelectedRows, row];
                }
            } else {
                // Remove the row from the selectedRows array
                return prevSelectedRows.filter((selectedRow) => selectedRow.idClass !== row.idClass);
            }

            return prevSelectedRows; // Return the previous state if no changes were made
        });
    };

    const isRowSelected = (row: any) => selectedRows.some((selectedRow) => selectedRow.idClass === row.idClass);
    const renderStep1 = () => {
        return (
            <Paper className="step-1">
                {listClassMapper.length ? (
                    <TableCustom
                        isInterleavedStyle
                        rows={listClassMapper}
                        columns={col}
                        onRowClick={(row) => {
                            isRowSelected(row)
                                ? handleCheckboxChange({ target: { checked: false } }, row)
                                : handleCheckboxChange({ target: { checked: true } }, row);
                        }}
                    />
                ) : (
                    <Grid container style={{ marginTop: 20 }} justifyContent="space-between">
                        <Grid
                            item
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                        >
                            <EmptyData />
                            <div style={{ padding: '20px 0' }}>There is no data</div>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        );
    };

    const renderStep2 = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DateRangeLocalDesktopDatePicker
                            views={['year', 'month', 'day']}
                            disablePast
                            isRequired
                            isAllowEqual
                            isShowErrorMessage={false}
                            dateSx={{ maxWidth: 'unset', width: '100%' }}
                            errorTextSx={{ top: '50px' }}
                            fromDateValue={fromDate}
                            toDateValue={endDate}
                            renderInput={(params: any) => <TextField size="large" fullWidth {...params} />}
                            disabled={isSave}
                            onChange={(value) => {
                                if (value.errorText) {
                                    setIsError(true);
                                    setMsg(value.errorText);
                                } else {
                                    setIsError(false);
                                    setMsg('');
                                }
                                setFromDate(value.fromDate);
                                setEndDate(value.toDate);
                            }}
                            onError={(error) => {
                                setIsError(!!error);
                                setMsg('');
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    function renderButtonSaveContent() {
        if (step === 1 && listClass.length) {
            return 'Next';
        }

        if (step === 2 && !isSave) {
            return 'Check';
        }

        if (step === 2 && isSave) {
            return 'Yes';
        }
        return undefined;
    }

    function renderButtonCancelContent() {
        if (step === 1) {
            return undefined;
        }

        if (step === 2 && !isSave) {
            return 'Back';
        }

        if (step === 2 && isSave) {
            return 'No';
        }
        return undefined;
    }

    function renderButtonSaveFunc() {
        if (step === 1) {
            return handleStep();
        }

        if (step === 2 && !isSave) {
            return handleCheckDuplicate();
        }

        if (step === 2 && isSave) {
            return handleSaveClear();
        }
        return undefined;
    }

    function renderButtonCancelFunc() {
        if (step === 1) {
            return undefined;
        }

        if (step === 2 && !isSave) {
            return setStep(1);
        }

        if (step === 2 && isSave) {
            return onClose();
        }
        return undefined;
    }

    return (
        <ModalCustom
            onSave={() => renderButtonSaveFunc()}
            onClose={onClose}
            onCancel={() => renderButtonCancelFunc()}
            title={step === 1 ? 'Please select class(es) for clearance' : 'Please select deployment clearance date range'}
            contentCancel={renderButtonCancelContent()}
            contentSave={renderButtonSaveContent()}
            sx={{ width: 600, maxHeight: '300px', overflowY: step === 1 ? 'scroll' : 'hidden' }}
            iconSave={step === 2 && !isSave ? CheckIcon : undefined}
            typeHelper={isError && !isSave ? 'error' : 'warning'}
            msgHelper={msg}
            isLoading={loading}
        >
            <>
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
            </>
        </ModalCustom>
    );
}
