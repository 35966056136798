/* eslint-disable no-nested-ternary */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// project imports
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    BadgeProps,
    Button,
    Chip,
    ClickAwayListener,
    Grid,
    Menu,
    Paper,
    Table,
    TableBody,
    TableContainer,
    styled
} from '@mui/material';
import { IconSquarePlus } from '@tabler/icons';
import AssistantIcon from 'assets/images/icons/assistant.svg';
import EditIcon from 'assets/images/icons/page-actions/tabler-icon-pencil-minus.svg';
import DeleteIcon from 'assets/images/icons/page-actions/tabler-icon-trash.svg';
import WarningIcon from 'assets/images/icons/tabler-icon-warning.svg';
import TeacherIcon from 'assets/images/icons/teacher.svg';
import { ClassKecho, IRatioClass } from 'types/kecho/class-kecho';
import { StaffKecho } from 'types/kecho/staff-kecho';

import UIManager from 'core/UIManager';
import { RatioApi } from 'services/api';
import { SelectStaffModal } from './components';
import './styles.scss';

import Tooltip from '@mui/material/Tooltip';
import { convertTime, generateHalfHourTimes, ReliefStatus } from 'config';
import { formatText } from 'utils/common/upperCaseText';
import WarningConflictModal from './components/WarningConflictModal';

export interface IStaffSelected {
    fromHour?: number;
    id?: string;
    staff?: StaffKecho;
    toHour?: number;
    typeStaffOfClass?: string;
    leave?: any;
}

interface IProps {
    detailClass: ClassKecho;
    date?: string;
    callback?: any;
    centreId?: any;
    // ref: any;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -2,
        top: -2,
        padding: '0 4px'
    }
}));

export const CollapseWrapper = styled('div')(({ theme }) => ({
    marginBottom: '10px',
    border: '1px solid #E8EAF6',
    borderRadius: '8px',
    // minHeight: '350px',
    '& .collapse-header': {
        width: '100%',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#E8EAF6',
        padding: '0px 16px 0px 0px',
        borderRadius: '7px',
        '& .MuiAccordionSummary-expandIconWrapper': {
            display: 'flex',
            border: '1px solid transparent',
            borderRadius: '50%',
            color: '#3F51B5',
            background: 'white'
        }
    }
}));

export const GroupActionTeacherWrapper = styled('div')(({ theme }) => ({
    width: '50%',
    display: 'flex',
    justifyContent: 'end',
    '& .disabled': {
        color: '#00000042',
        border: '1px solid #0000001f'
    },
    '& .btn-save.visible': {
        backgroundColor: '#3F51B5'
    }
}));

const TimeZone = styled('div')(({ theme }) => ({
    fontSize: '12px',
    width: '100%',
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E0E0E0',
    color: '#A6A6A6',
    borderRadius: '10px'
}));

function RatioInfo(props: IProps, ref: any) {
    const { detailClass, date, callback, centreId } = props;
    const startTime = 7;
    const endTime = 19;
    const listTimeOpacity = [7, 7.5, 8, 8.5, 17.5, 18, 18.5, 19];
    const listTime: any = generateHalfHourTimes(startTime, endTime);
    const uuid = uuidv4();
    const [listStaffRender, setListStaffRender] = useState<IStaffSelected[]>(
        detailClass?.staff && detailClass?.staff?.length > 0 ? detailClass?.staff : [{}]
    );
    const [visibleSave, setVisibleSave] = useState(false);
    const [isDisableSave, setIsDisableSave] = useState<boolean>(true);
    const [titleTooltipDisable, setTitleTooltipDisable] = useState<string>('');
    const [ratioClass, setRatioClass] = useState<IRatioClass>({});

    useEffect(() => {
        handleGetListRatio();
    }, [detailClass]);

    useEffect(() => {
        const staffItem = listStaffRender?.map((item) => {
            return {
                id: item.staff?.id,
                fromHour: item.fromHour,
                toHour: item.toHour
            };
        });
        staffItem?.forEach((item) => {
            if (item.id && item.fromHour && item.toHour) {
                setIsDisableSave(false);
                setTitleTooltipDisable('');
            } else if (item.id || !item.fromHour || !item.toHour) {
                setIsDisableSave(true);
                setTitleTooltipDisable('Please select time for staff');
            } else if (!item.id && item.fromHour && item.toHour) {
                setIsDisableSave(false);
                setTitleTooltipDisable('Please select staff');
            } else {
                setIsDisableSave(true);
                setTitleTooltipDisable('Please select staff and time for staff');
            }
        });
    }, [listStaffRender]);

    const handleGetListRatio = async () => {
        const param = {
            idLevel: detailClass?.level?.id,
            idBranch: detailClass?.branch?.id,
            centerId: centreId
        };
        const { data } = await RatioApi.listRatio(param);
        setRatioClass(data);
    };

    const handleClearTimePicker = (staffNumber?: number) => {
        if (staffNumber) {
            const staffSelected = listStaffRender[staffNumber - 1];
            listStaffRender[staffNumber - 1] = { ...staffSelected, fromHour: undefined, toHour: undefined };
            setListStaffRender([...listStaffRender]);
        }
        setVisibleSave(true);
    };

    const handleGetNumberRes = () => {
        try {
            let list: any = document.getElementsByClassName(`numberResCal${uuid}`);
            list = [...list];
            const arr: any = [];
            typeof list === 'object' &&
                list?.forEach((item: any) => {
                    arr.push({
                        numberCal: item.getAttribute('value'),
                        typeCal: item.getAttribute('typeCal'),
                        time: item.getAttribute('time'),
                        type: item.getAttribute('type')
                    });
                });
            return arr;
        } catch (error) {
            return null;
        }
    };

    const handleSaveDetail = async () => {
        const listStaffLeave = listStaffRender?.filter((item) => item?.staff?.leave);
        const listStaff = listStaffRender?.filter((item) => item.fromHour && item.toHour);
        const staffItem = listStaff?.map((item) => {
            return {
                ...item,
                fromHour: item.fromHour,
                toHour: item.toHour
            };
        });

        const selectTimeRanges = staffItem?.flatMap((item) => {
            return { fromHour: item.fromHour, toHour: item.toHour };
        });

        const leaveTimeRanges = listStaffLeave?.flatMap((item) => {
            return item?.staff?.leave?.map((leave: any) => {
                return { staff: item.staff, fromHour: leave.fromHour, toHour: leave.toHour };
            });
        });

        // check time overlapping
        const overlappingTimeRanges = selectTimeRanges.filter((selectTimeRange: any) => {
            return leaveTimeRanges.some((leaveTimeRange) => {
                return (
                    (selectTimeRange.fromHour >= leaveTimeRange.fromHour && selectTimeRange.fromHour < leaveTimeRange.toHour) ||
                    (selectTimeRange.toHour > leaveTimeRange.fromHour && selectTimeRange.toHour <= leaveTimeRange.toHour)
                );
            });
        });
        // and return list staff conflict
        const staffLeave: any = [];

        selectTimeRanges.forEach((selectTimeRange: any) => {
            leaveTimeRanges.forEach((leave: any) => {
                if (
                    (selectTimeRange.fromHour >= leave.fromHour && selectTimeRange.fromHour < leave.toHour) ||
                    (selectTimeRange.toHour > leave.fromHour && selectTimeRange.toHour <= leave.toHour)
                ) {
                    staffLeave.push(leave.staff);
                }
            });
        });

        if (overlappingTimeRanges.length > 0) {
            UIManager.showModal({
                content: (onClose: any) => (
                    <WarningConflictModal
                        onClose={onClose}
                        staffLeave={staffLeave.filter((value: any, index: any, self: any) => self.indexOf(value) === index)}
                        date={date}
                        onSave={handleSaveOverlap}
                    />
                ),
                stylesModal: {
                    maxWidth: '600px',
                    width: '100%'
                }
            });
            return;
        }

        await handleSaveOverlap();
    };

    const handleSaveOverlap = async () => {
        const resFE = await handleGetNumberRes();
        let hasStaff: boolean = false;
        const listStaff = listStaffRender?.filter((item) => item.fromHour && item.toHour);
        const listHasStaff = listStaffRender?.filter((item) => item?.staff);
        if (listHasStaff.length > 0) {
            hasStaff = true;
        }
        const staffItem = listStaff?.map((item) => {
            return {
                ...item,
                fromHour: item.fromHour,
                toHour: item.toHour
            };
        });
        const input: any = {
            idRatio: detailClass?.idRatio,
            staffs: staffItem,
            calRes: JSON.stringify(resFE),
            hasStaff
        };
        if (staffItem?.length > 0) {
            const { error } = await RatioApi.saveRatio(input);
            if (!error) {
                setVisibleSave(false);
                UIManager.showAlert({
                    message: 'Update staff successfully!',
                    alert: {
                        color: 'success'
                    }
                });
                if (callback) callback();
            }
        } else {
            UIManager.showAlert({
                message: 'Please select teacher!',
                alert: {
                    color: 'error'
                }
            });
        }
    };

    const handleCalculationChildren = (time: number) => {
        const numberChildFD = detailClass?.numberChildOfClass?.find((item) => item.key === 'FD')?.total || 0;
        let numberChildPart = 0;
        if (time <= 12) numberChildPart = detailClass?.numberChildOfClass?.find((item) => item.key === 'AM')?.total || 0;
        if (time > 12) numberChildPart = detailClass?.numberChildOfClass?.find((item) => item.key === 'PM')?.total || 0;
        const totalChildren = Number(numberChildPart) + Number(numberChildFD);
        return totalChildren;
    };

    const handleCalculationRatio = (time: number, typeRatio: string) => {
        const numberChild = handleCalculationChildren(time);
        const ratioBrand = ratioClass?.branch;
        const ratioEcda = ratioClass?.ecda;
        const checkTimeSelect = listStaffRender.some(
            (item) => generateHalfHourTimes(Number(item?.fromHour), Number(item?.toHour || item?.fromHour)).indexOf(time) >= 0
        );

        const teacherBrand = ratioBrand?.teacher || 0;
        const teacherEcda = ratioEcda?.teacher || 0;
        const assistantBrand = ratioBrand?.assistant ?? 0;
        const assistantEcda = ratioEcda?.assistant ?? 0;
        let totalBrandTeacher = 0;
        let totalECDATeacher = 0;
        let totalTempBrand = teacherBrand;
        let totalTempECDA = teacherEcda;

        const levelStaff = detailClass?.level?.nameLevel;
        listStaffRender.forEach((item) => {
            const timeRange = generateHalfHourTimes(Number(item?.fromHour), Number(item?.toHour || item?.fromHour));
            const timeSelect = timeRange.indexOf(time) >= 0;
            const isCount = item?.staff?.isCount;
            const isTeacher = item?.staff?.type === 'teacher';

            if (!item?.staff && (item?.fromHour || item?.toHour)) {
                if (timeSelect) {
                    totalBrandTeacher = totalBrandTeacher + teacherBrand || 0;
                    totalECDATeacher = totalECDATeacher + teacherEcda || 0;

                    totalTempBrand = totalBrandTeacher;
                    totalTempECDA = totalECDATeacher;
                }
            } else if (item?.staff && (item?.fromHour || item?.toHour)) {
                if (timeSelect && isCount && isTeacher) {
                    totalBrandTeacher = totalBrandTeacher + teacherBrand || 0;
                    totalECDATeacher = totalECDATeacher + teacherEcda || 0;

                    totalTempBrand = totalBrandTeacher;
                    totalTempECDA = totalECDATeacher;
                } else if (timeSelect && isCount && !isTeacher) {
                    totalBrandTeacher = totalBrandTeacher + assistantBrand || 0;
                    totalECDATeacher = totalECDATeacher + assistantEcda || 0;

                    totalTempBrand = totalBrandTeacher;
                    totalTempECDA = totalECDATeacher;
                } else if (timeSelect && !isCount) {
                    totalBrandTeacher = totalBrandTeacher - teacherBrand + teacherBrand || 0;
                    totalECDATeacher = totalECDATeacher - teacherEcda + teacherEcda || 0;

                    totalTempBrand = totalBrandTeacher;
                    totalTempECDA = totalECDATeacher;
                }
            }
        });

        const totalBrand = checkTimeSelect ? totalTempBrand : 0;
        const totalECDA = checkTimeSelect ? totalTempECDA : 0;

        const validEcda = totalECDA === numberChild && checkTimeSelect;
        const overEcda = totalECDA > numberChild && checkTimeSelect;
        const lessEcda = totalECDA < numberChild && checkTimeSelect;

        const validBrand = totalBrand === numberChild && checkTimeSelect;
        const overBrand = totalBrand > numberChild && checkTimeSelect;
        const lessBrand = totalBrand < numberChild && checkTimeSelect;

        const countStuOfTeacherBrand = ratioBrand?.teacher ?? 0;
        const countStuOfAssisBrand = levelStaff === 'Infant' ? ratioBrand?.teacher ?? 0 : ratioBrand?.assistant ?? 0;

        const countStuOfTeacherECDA = ratioEcda?.teacher ?? 0;
        const countStuOfAssisECDA = levelStaff === 'Infant' ? ratioEcda?.teacher ?? 0 : ratioEcda?.assistant ?? 0;

        let numberStaff = 0;
        let styleItem = '';

        if (typeRatio === 'brand') {
            if (numberChild === 0) {
                styleItem = 'error';
                numberStaff = 0;
            } else if (validBrand) {
                styleItem = 'success';
                numberStaff = 0;
            } else if (totalBrand === 0) {
                styleItem = 'error';
                numberStaff = Math.ceil(numberChild / countStuOfTeacherBrand);
            } else if (totalBrand > 0 && numberChild > totalBrand && checkTimeSelect) {
                styleItem = 'error';
                numberStaff = Math.ceil((numberChild - totalBrand) / countStuOfAssisBrand);
            } else if (overBrand) {
                styleItem = 'over';
                numberStaff = totalBrand - numberChild;
            } else if (lessBrand) {
                styleItem = 'warn';
                numberStaff = 1;
            } else if (!checkTimeSelect) {
                styleItem = 'warn';
                numberStaff = 1;
            } else if (ratioBrand?.teacher && numberChild <= ratioBrand?.teacher) {
                styleItem = 'error';
                numberStaff = 1;
            } else {
                styleItem = 'error';
                numberStaff = 3;
            }
        } else if (typeRatio === 'ecda') {
            if (numberChild === 0) {
                styleItem = 'error';
                numberStaff = 0;
            } else if (validEcda) {
                styleItem = 'success';
                numberStaff = 0;
            } else if (totalECDA === 0) {
                styleItem = 'error';
                numberStaff = Math.ceil(numberChild / countStuOfTeacherECDA);
            } else if (totalECDA > 0 && numberChild > totalECDA && checkTimeSelect) {
                styleItem = 'error';
                numberStaff = Math.ceil((numberChild - totalECDA) / countStuOfAssisECDA);
            } else if (overEcda) {
                styleItem = 'over';
                numberStaff = totalECDA - numberChild;
            } else if (lessEcda) {
                styleItem = 'warn';
                numberStaff = 1;
            } else if (!checkTimeSelect) {
                styleItem = 'warn';
                numberStaff = 1;
            } else if (ratioEcda?.teacher && numberChild <= ratioEcda?.teacher) {
                styleItem = 'error';
                numberStaff = 1;
            } else {
                styleItem = 'error';
                numberStaff = 3;
            }
        }

        return { styleItem, numberStaff };
    };

    const handleClickTime = (time: number, staffNumber?: number) => {
        if (staffNumber) {
            const staffSelected = listStaffRender[staffNumber - 1];
            const { fromHour, toHour } = staffSelected;

            if ((fromHour && toHour) || (!fromHour && !toHour)) {
                listStaffRender[staffNumber - 1] = { ...staffSelected, fromHour: time, toHour: undefined };
            } else if (fromHour && !toHour) {
                if (time > fromHour) {
                    listStaffRender[staffNumber - 1] = { ...staffSelected, toHour: time };
                } else {
                    listStaffRender[staffNumber - 1] = { ...staffSelected, fromHour: time, toHour: fromHour };
                }
            } else if (staffSelected.fromHour && !staffSelected.toHour) {
                listStaffRender[staffNumber - 1] = { ...staffSelected, fromHour: undefined };
            }
            setListStaffRender([...listStaffRender]);
        }

        setVisibleSave(true);
    };

    const handleSelectStaff = (staff: StaffKecho, staffNumber?: number) => {
        const staffSelected = {
            staff,
            fromHour: staffNumber ? listStaffRender[staffNumber - 1]?.fromHour : undefined,
            toHour: staffNumber ? listStaffRender[staffNumber - 1]?.toHour : undefined
        };

        if (staffNumber) {
            listStaffRender[staffNumber - 1] = { ...staffSelected };
            setListStaffRender([...listStaffRender]);
        }
        setVisibleSave(true);
    };
    const handleAddMoreTeacher = () => {
        setListStaffRender([...listStaffRender, {}]);
    };

    // open dropdown select staff
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);

    const onCloseDropdownStaff = () => {
        setOpen(false);
    };

    const [staffSequence, setStaffSequence] = useState<number>();
    const [selectStaff, setSelectStaff] = useState<StaffKecho | undefined>(undefined);
    const [positionModalTop, setPositionModalTop] = useState<number>(0);
    const [positionBefore, setPositionBefore] = useState<number>(0);

    const handleOpenStaffModal = (staffNumber: number) => {
        if (staffNumber) setSelectStaff(listStaffRender[staffNumber - 1]?.staff);
        setStaffSequence(staffNumber);
        setOpen((prevOpen) => !prevOpen);

        // set position visible modal staff
        const offSet = document.getElementById(`account-menu-${detailClass?.idRatio}-${staffNumber}`)?.getBoundingClientRect();
        const height = window.innerHeight;
        const heightModal = 390;
        if (offSet?.top) {
            if (offSet?.top + heightModal > height) {
                setPositionModalTop(offSet?.top - heightModal || 0);
                setPositionBefore(heightModal - 25);
            } else if (offSet?.top > height / 2) {
                setPositionModalTop(offSet?.top - heightModal || 0);
                setPositionBefore(heightModal - 25);
            } else {
                setPositionModalTop(offSet?.top + 40 || 0);
                setPositionBefore(0);
            }
        }
    };

    const handleRemoveSelectStaff = (staffNumber: number) => {
        setListStaffRender(listStaffRender.filter((item, index) => index !== staffNumber - 1));
        setVisibleSave(true);
    };

    const renderRowClass = () => {
        return (
            <>
                <div>
                    <CollapseWrapper>
                        <Accordion defaultExpanded>
                            <div className="collapse-header">
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ width: 'auto' }}
                                />
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', marginRight: 10 }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#3F51B5' }}>
                                        {detailClass?.nameClass}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: 500,
                                            color: '#3F51B5',
                                            marginLeft: 20,
                                            padding: '5px 15px',
                                            border: '1px solid #3F51B5',
                                            borderRadius: '5px'
                                        }}
                                    >
                                        {detailClass?.level?.nameLevel}
                                    </Typography>
                                </div>
                                <GroupActionTeacherWrapper>
                                    <Grid container style={{ justifyContent: 'end' }}>
                                        {visibleSave && (
                                            <Tooltip title={isDisableSave ? titleTooltipDisable : ''}>
                                                <span>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleSaveDetail}
                                                        style={{
                                                            marginLeft: 8,
                                                            outline: 'none',
                                                            color: 'white',
                                                            height: '100%'
                                                        }}
                                                        className={isDisableSave ? 'disable' : 'visible btn-save'}
                                                        disabled={isDisableSave}
                                                    >
                                                        <SaveIcon style={{ marginRight: '5px' }} />
                                                        Save
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </GroupActionTeacherWrapper>
                            </div>

                            <AccordionDetails style={{ padding: 0 }}>
                                <TableContainer className="table-container">
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableBody style={{ position: 'relative' }}>
                                            {listStaffRender?.map((staffSelect: StaffKecho, staffNum: number) => {
                                                return renderRowStaff(staffSelect, staffNum + 1);
                                            })}
                                            {renderRowCountChildren()}
                                            {renderRowRatioECDA()}
                                            {renderRowRatioBrand()}
                                            {open && renderStaffModal()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </CollapseWrapper>
                </div>
            </>
        );
    };

    // show select staff modal
    const renderStaffModal = () => {
        return (
            <ClickAwayListener onClickAway={onCloseDropdownStaff}>
                <Menu
                    open={open}
                    anchorEl={anchorRef.current}
                    id="account-menu"
                    onClose={onCloseDropdownStaff}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            top: `${positionModalTop}px !important`,
                            left: '68px !important',
                            '& .MuiList-root': {
                                paddingTop: 0
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: positionBefore,
                                left: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0
                            }
                        }
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Paper className="dropdown-select-staff" id="dropdown-select-staff">
                        <SelectStaffModal
                            selectedStaff={selectStaff}
                            onClose={onCloseDropdownStaff}
                            detailClass={detailClass}
                            onSelectStaff={(staffEdit: StaffKecho) => handleSelectStaff(staffEdit, staffSequence)}
                            date={date}
                            centreId={centreId}
                        />
                    </Paper>
                </Menu>
            </ClickAwayListener>
        );
    };

    const renderRowCountChildren = () => {
        return (
            <TableRow>
                <TableCell padding="none" width={300}>
                    <Grid container className="cel-shadow" style={{ padding: 8 }}>
                        <Typography gutterBottom>Number of children</Typography>
                    </Grid>
                </TableCell>
                <div className="cell-time">
                    {listTime.map((item: number) => {
                        const numberChildPart = handleCalculationChildren(item);
                        return (
                            <TableCell className="cell-item" padding="none" key={uuidv4()} style={{ textAlign: 'center' }}>
                                {numberChildPart}
                            </TableCell>
                        );
                    })}
                </div>
            </TableRow>
        );
    };

    const renderRowStaff = (staffSelect: any, staffNum: number) => {
        const { fromHour, toHour, staff } = staffSelect;
        let indexFrom = listTime.findIndex((item: any) => item === fromHour);
        let indexTo = listTime.findIndex((item: any) => item === toHour) + 1;
        const staffNumber = staffNum;
        if (!fromHour) {
            indexFrom = 0;
            indexTo = 0;
        } else if (!toHour) {
            indexTo = indexFrom + 1;
        } else {
            indexFrom = listTime.findIndex((item: any) => item === fromHour);
            indexTo = listTime.findIndex((item: any) => item === toHour) + 1;
        }
        return (
            <TableRow>
                <TableCell padding="none">
                    <Grid container className="cel-shadow" justifyContent="space-between" alignItems="center">
                        <Grid item sm={12}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <div className="group-name">
                                    <Grid item className="group-name-icon">
                                        {staff ? (
                                            <>
                                                <Tooltip title={staff?.nameStaff} placement="top">
                                                    <Typography className="font-weight-bold title" gutterBottom>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {staff?.type === 'teacher' && (
                                                                <img
                                                                    src={TeacherIcon}
                                                                    alt="teacher"
                                                                    style={{ marginRight: 5, marginTop: '-3px' }}
                                                                />
                                                            )}
                                                            {staff?.type === 'assistant' && (
                                                                <img
                                                                    src={AssistantIcon}
                                                                    alt="assistant"
                                                                    style={{ marginRight: 5, marginTop: '-3px' }}
                                                                />
                                                            )}
                                                            {staff && formatText(staff?.nameStaff)}
                                                            {staff?.reliefStaffs?.length > 0 &&
                                                                staff?.reliefStaffs[0].status === ReliefStatus.RELIEF_ASSIGNED && (
                                                                    <Chip className="chip-relief" label="Relief" />
                                                                )}
                                                        </div>
                                                    </Typography>
                                                </Tooltip>
                                                <Tooltip title="This staff is hidden" placement="bottom">
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {staff?.isHidden === true && (
                                                            <img
                                                                src={WarningIcon}
                                                                alt="warning"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginRight: 5,
                                                                    marginTop: '-3px'
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Leave & deployment conflict detected" placement="bottom">
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        {staff?.leave?.some(
                                                            (item: any) => fromHour < item?.toHour && toHour > item?.fromHour
                                                        ) && (
                                                            <img
                                                                src={WarningIcon}
                                                                alt="warning"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    marginRight: 5,
                                                                    marginTop: '-3px'
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <Typography className="title" gutterBottom>
                                                Select Teacher {staffNum}
                                            </Typography>
                                        )}
                                    </Grid>
                                </div>
                                <div className="group-actions">
                                    <Grid item className="group-item">
                                        <Tooltip title="Change teacher" placement="top">
                                            <Button
                                                variant="outlined"
                                                id={`account-menu-${detailClass?.idRatio}-${staffNum}`}
                                                style={{ padding: 0, border: 'none' }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                ref={anchorRef}
                                                onClick={() => handleOpenStaffModal(staffNumber)}
                                            >
                                                <img
                                                    src={EditIcon}
                                                    alt="edit"
                                                    style={{ width: '22px', height: '22px', marginRight: '5px' }}
                                                />{' '}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    {staffNum === 1 && (
                                        <Grid item className="group-item">
                                            <Tooltip title="Add more teacher" placement="top">
                                                <Button
                                                    variant="outlined"
                                                    style={{ padding: 0, border: 'none' }}
                                                    onClick={() => handleAddMoreTeacher()}
                                                >
                                                    <IconSquarePlus size={22} stroke={1.5} color="#3F51B5" />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    {staffNum !== 1 && (
                                        <Grid item className="group-item">
                                            <Tooltip title="Delete teacher" placement="top">
                                                <Button
                                                    variant="outlined"
                                                    style={{ padding: 0, border: 'none' }}
                                                    onClick={() => handleRemoveSelectStaff(staffNumber)}
                                                >
                                                    <img
                                                        src={DeleteIcon}
                                                        alt="delete"
                                                        style={{ width: '22px', height: '22px', marginRight: '5px' }}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </TableCell>
                <div className="cell-time">
                    {listTime.slice(0, indexFrom).map((item: number) => {
                        const numberChildPart = handleCalculationChildren(item);
                        const styleOpacity = listTimeOpacity.includes(item) ? '0.5' : '';
                        const checkDisabled = numberChildPart === 0;
                        return (
                            <TableCell
                                className="cell-item"
                                style={
                                    checkDisabled
                                        ? { cursor: 'not-allowed', opacity: '0.5', pointerEvents: 'none' }
                                        : { cursor: 'pointer', opacity: styleOpacity }
                                }
                                key={uuidv4()}
                                onClick={() => numberChildPart > 0 && handleClickTime(item, staffNum)}
                            >
                                <TimeZone className="time">{convertTime(item)}</TimeZone>
                            </TableCell>
                        );
                    })}
                    {indexFrom !== indexTo && (
                        <TableCell
                            className="cell-col-span"
                            padding="none"
                            colSpan={listTime.slice(indexFrom, indexTo).length}
                            key={uuidv4()}
                            style={{
                                width: `calc(4 * ${listTime.slice(indexFrom, indexTo).length}%)`,
                                padding: '14px 0px',
                                minWidth: `calc(80 * ${listTime.slice(indexFrom, indexTo).length}px)`
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className="button-select"
                                onClick={() => handleClearTimePicker(staffNum)}
                            >
                                {`${convertTime(fromHour)}${toHour && fromHour !== toHour ? ` - ${convertTime(toHour)}` : ''}`}
                            </Button>
                        </TableCell>
                    )}
                    {listTime.slice(indexTo, listTime.length).map((item: number) => {
                        const numberChildPart = handleCalculationChildren(item);
                        const styleOpacity = listTimeOpacity.includes(item) ? '0.5' : '';
                        const checkDisabled = numberChildPart === 0;
                        return (
                            <TableCell
                                className="cell-item"
                                style={
                                    checkDisabled
                                        ? { cursor: 'not-allowed', opacity: '0.5', pointerEvents: 'none' }
                                        : { cursor: 'pointer', opacity: styleOpacity }
                                }
                                key={uuidv4()}
                                onClick={() => numberChildPart > 0 && handleClickTime(item, staffNum)}
                            >
                                <TimeZone className="time">{convertTime(item)}</TimeZone>
                            </TableCell>
                        );
                    })}
                </div>
            </TableRow>
        );
    };

    const renderRowRatioECDA = () => {
        return (
            <TableRow>
                <TableCell padding="none" className="border-0">
                    <Grid container className="cel-shadow">
                        <Typography className="font-weight-bold title" gutterBottom>
                            ECDA
                        </Typography>
                    </Grid>
                </TableCell>
                <div className="cell-time">
                    {listTime.map((item: number) => {
                        const { styleItem, numberStaff } = handleCalculationRatio(item, 'ecda');
                        const type = {
                            time: item,
                            type: 'ecda',
                            typeCal: styleItem,
                            value: numberStaff
                        };
                        const styleOpacity = listTimeOpacity.includes(item) ? '0.5' : '';
                        return (
                            <TableCell
                                className="cell-item"
                                padding="none"
                                style={{ textAlign: 'center', opacity: styleOpacity }}
                                key={uuidv4()}
                            >
                                <div className={`box-res-ratio ${styleItem}`}>
                                    <Tooltip
                                        title={
                                            styleItem === 'over'
                                                ? `Over ${numberStaff} students`
                                                : `Needed ${numberStaff} more Teachers/Assistant teacher`
                                        }
                                    >
                                        <StyledBadge
                                            className="number-tag"
                                            badgeContent={styleItem === 'over' ? numberStaff : 0}
                                            color="error"
                                        >
                                            <Typography gutterBottom className={`numberResCal${uuid}`} {...type} style={{ width: '100%' }}>
                                                {styleItem !== 'over' ? numberStaff : 0}
                                            </Typography>
                                        </StyledBadge>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        );
                    })}
                </div>
            </TableRow>
        );
    };

    const renderRowRatioBrand = () => {
        return (
            <TableRow>
                <TableCell padding="none" className="border-0">
                    <Grid container className="cel-shadow">
                        <Typography className="font-weight-bold title" gutterBottom>
                            Brand
                        </Typography>
                    </Grid>
                </TableCell>
                <div className="cell-time">
                    {listTime.map((item: number) => {
                        const { styleItem, numberStaff } = handleCalculationRatio(item, 'brand');
                        const type = {
                            time: item,
                            type: 'brand',
                            typeCal: styleItem,
                            value: numberStaff
                        };
                        const styleOpacity = listTimeOpacity.includes(item) ? '0.5' : '';
                        return (
                            <TableCell
                                className="cell-item"
                                padding="none"
                                style={{ textAlign: 'center', opacity: styleOpacity }}
                                key={uuidv4()}
                            >
                                <div className={`box-res-ratio ${styleItem}`}>
                                    <Tooltip
                                        title={
                                            styleItem === 'over'
                                                ? `Over ${numberStaff} students`
                                                : `Needed ${numberStaff} more Teachers/Assistant teacher`
                                        }
                                    >
                                        <StyledBadge
                                            className="number-tag"
                                            badgeContent={styleItem === 'over' ? numberStaff : 0}
                                            color="error"
                                        >
                                            <Typography gutterBottom className={`numberResCal${uuid}`} {...type} style={{ width: '100%' }}>
                                                {styleItem !== 'over' ? numberStaff : 0}
                                            </Typography>
                                        </StyledBadge>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        );
                    })}
                </div>
            </TableRow>
        );
    };

    return <>{renderRowClass()}</>;
}

export default forwardRef(RatioInfo);
